import cx from 'classnames'
import { forwardRef } from 'react'

import * as styles from './Section.css'

interface SectionProps extends React.HTMLProps<HTMLDivElement> {
  highlighted?: boolean
}

const Section = forwardRef<HTMLDivElement, SectionProps>(
  ({ highlighted, className, ...props }, ref) => {
    return (
      <div
        className={cx(styles.section, className, {
          [styles.highlightedSection]: !!highlighted,
        })}
        ref={ref}
        {...props}
      />
    )
  },
)

export default Section
