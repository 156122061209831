import objectId from '@src/lib/objectId'
import { isDirectNumber } from '@src/service/model/DirectNumberModel'

interface CreateOutgoingCallParamsInput {
  userId: `US${string}`
  roomId?: string
  participantId?: string
  to: string
  workflowDefinitionId?: `WD${string}`
  from: { id: string; number: string }
}

interface BaseOutgoingCallParams {
  UserId: `US${string}`
  RoomId: string
  ParticipantId: string
  To: string
  WorkflowDefinitionId?: `WD${string}`
}

interface OutgoingCallDirectNumberParams extends BaseOutgoingCallParams {
  DirectNumberId: string
  DirectNumber: string
}

interface OutgoingCallPhoneNumberParams extends BaseOutgoingCallParams {
  PhoneNumberId: string
  PhoneNumber: string
}

export default function createOutgoingCallParams({
  userId,
  roomId = objectId(),
  participantId = objectId(),
  to,
  workflowDefinitionId,
  from,
}: CreateOutgoingCallParamsInput) {
  const baseParams: BaseOutgoingCallParams = {
    UserId: userId,
    RoomId: roomId,
    ParticipantId: participantId,
    To: to,
  }

  if (workflowDefinitionId) {
    baseParams.WorkflowDefinitionId = workflowDefinitionId
  }

  if (isDirectNumber(from.number)) {
    return {
      ...baseParams,
      DirectNumberId: from.id,
      DirectNumber: from.number,
    } as const satisfies OutgoingCallDirectNumberParams
  }

  return {
    ...baseParams,
    PhoneNumberId: from.id,
    PhoneNumber: from.number,
  } as const satisfies OutgoingCallPhoneNumberParams
}
