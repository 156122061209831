import { makeAutoObservable, observable, toJS } from 'mobx'

import { parseDate, removeAtIndex } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import objectId from '@src/lib/objectId'
import type ContactStore from '@src/service/contact-store'
import type { Enrichment } from '@src/service/model/activity'
import type { Model } from '@src/service/model/base'
import type { CodableContactNoteReaction } from '@src/service/model/reactions'
import {
  ContactNoteReactionModel,
  isCodableContactNoteReaction,
} from '@src/service/model/reactions'

import type { ContactModel } from './ContactModel'

export interface CodableNote {
  clientId: string | null
  enrichment?: Enrichment | null
  id: string
  private: boolean | null
  reactions: CodableContactNoteReaction[]
  text: string | null
  userId: string | null
  createdAt: number | null
  deletedAt: number | null
  updatedAt: number | null
}

// add contactId and noteId to reaction if they are not present
// this is a temporary fix for the data inconsistency
interface AddContactIdAndNoteIdIfNeeded {
  contactId: string
  noteId: string
  reaction: CodableContactNoteReaction
}

const addContactIdAndNoteIdIfNeeded = ({
  contactId,
  noteId,
  reaction,
}: AddContactIdAndNoteIdIfNeeded): CodableContactNoteReaction => {
  if (typeof reaction === 'object' && reaction !== null) {
    if (!reaction.contactId) {
      reaction.contactId = contactId
    }

    if (!reaction.noteId) {
      reaction.noteId = noteId
    }
  }

  return reaction
}

export class NoteModel implements Model {
  private raw: CodableNote

  constructor(
    private contactStore: ContactStore,
    public contact: ContactModel,
    attrs: Partial<CodableNote> = {},
  ) {
    this.raw = {
      clientId: null,
      createdAt: Date.now(),
      deletedAt: null,
      enrichment: null,
      id: objectId(),
      private: null,
      reactions: [],
      text: null,
      updatedAt: Date.now(),
      userId: null,
    }

    this.deserialize(attrs)

    makeAutoObservable<this, 'raw'>(this, {
      raw: observable.deep,
    })
  }

  get createdAt(): number | null {
    return this.raw.createdAt
  }

  get deletedAt(): number | null {
    return this.raw.deletedAt
  }

  get id(): string {
    return this.raw.id
  }

  get private(): boolean | null {
    return this.raw.private
  }

  get text(): string | null {
    return this.raw.text
  }

  get updatedAt(): number | null {
    return this.raw.updatedAt
  }

  get userId(): string | null {
    return this.raw.userId
  }

  get clientId(): string | null {
    return this.raw.clientId
  }

  get enrichment(): Enrichment | null {
    return this.raw.enrichment ?? null
  }

  get reactions(): ContactNoteReactionModel[] {
    return this.raw.reactions as ContactNoteReactionModel[]
  }

  update(text: string) {
    this.raw.text = text
    return this.contactStore.putNote(this)
  }

  delete() {
    this.contact.localUpdate({
      notes: this.contact.notes.filter((n) => n !== this),
    })
    return this.contactStore.deleteNote(this)
  }

  deleteReaction(reaction: ContactNoteReactionModel) {
    const reactionIndex = this.reactions.findIndex((r) => r === reaction)
    this.raw.reactions = removeAtIndex(this.reactions, reactionIndex)
    return this.contactStore.deleteNoteReaction(this.contact.id, this.id, reaction)
  }

  addReaction(reaction: ContactNoteReactionModel) {
    this.reactions.push(reaction)
    return this.contactStore.addNoteReaction(this.contact.id, this.id, reaction.toJSON())
  }

  deserialize(json: Partial<CodableNote>) {
    const { reactions, updatedAt, createdAt, deletedAt, clientId, id, text, userId } =
      json

    const attrs = { clientId, id, private: json['private'], text, userId }

    if (attrs) {
      Object.assign(this.raw, attrs)
    }

    if (updatedAt) {
      this.raw.updatedAt = parseDate(updatedAt)
    }

    if (createdAt) {
      this.raw.createdAt = parseDate(createdAt)
    }

    if (deletedAt) {
      this.raw.deletedAt = parseDate(deletedAt)
    }

    if (reactions) {
      this.raw.reactions = reactions
        .map((reaction) => {
          const normalizedReaction = addContactIdAndNoteIdIfNeeded({
            contactId: this.contact.id,
            noteId: this.id,
            reaction,
          })

          if (!isCodableContactNoteReaction(normalizedReaction)) {
            return null
          }

          const existing = this.reactions.find((r) => r.id === normalizedReaction.id)

          if (existing) {
            return existing.deserialize(normalizedReaction)
          } else {
            return new ContactNoteReactionModel(normalizedReaction)
          }
        })
        .filter(isNonNull)
    }
    return this
  }

  serialize(): CodableNote {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      private: this.private,
      text: this.text,
      enrichment: toJS(this.enrichment),
      updatedAt: this.updatedAt,
      userId: this.userId,
      reactions: this.reactions.map((r) => r.serialize()),
      clientId: this.clientId,
    }
  }

  toJSON(): CodableNote {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      private: this.private,
      text: this.text,
      updatedAt: this.updatedAt,
      userId: this.userId,
      clientId: this.clientId,
      reactions: this.reactions.map((r) => r.toJSON()),
    }
  }

  localUpdate(attrs: Partial<CodableNote>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
