import { reaction } from 'mobx'

import config from '@src/config'
import { formatDateToISO } from '@src/lib'
import { DisposeBag } from '@src/lib/dispose'
import type Service from '@src/service'
import type { IdentifyData } from '@src/service/analytics/AnalyticsStore'
import type { OrganizationAnalytics } from '@src/service/model'

export const HEAP_INSTALLATION_SCRIPT_ID = 'heap'

interface HeapData {
  appVersion: string
  desktopVersion?: string
  role: string
  signup_date: string
  theme_mode: string
  user_email_domain: string
  org_id: string
  is_paying: boolean
  plan: string
  org_creation_date: string
  size: string
  onboarding_industry?: OrganizationAnalytics['industry']
  onboarding_size?: OrganizationAnalytics['teamSize']
  onboarding_use_case?: OrganizationAnalytics['useCase']
  onboarding_teams?: OrganizationAnalytics['teams']
  onboarding_need?: OrganizationAnalytics['need']
  onboarding_users?: OrganizationAnalytics['users']
  subscription_start_date: string
  subscription_end_date: string
  has_auto_charge: boolean
  clearbit_industry: string
  clearbit_subindustry: string
  clearbit_employees: string
}

export default class Heap {
  private disposeBag = new DisposeBag()

  constructor(
    private root: Service,
    private desktopVersion?: string,
  ) {
    this.disposeBag.add(
      reaction(
        () => [
          root.flags.flags.heap,
          root.auth.hasSession || root.authV2.isAuthenticated,
        ],
        ([isEnabled, isLoggedIn]) => {
          if (isLoggedIn && isEnabled) {
            this.installHeap()
          } else {
            this.reset()
            this.uninstallHeap()
          }
        },
      ),
    )
  }

  installHeap() {
    if (import.meta.env.MODE === 'test') {
      // Don't install heap in tests
      return
    }

    const script = document.getElementById(HEAP_INSTALLATION_SCRIPT_ID)

    if (script || window.heap?.loaded) {
      return
    }

    const installationScript = document.createElement('script')
    installationScript.innerText = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load(${config.HEAP_APP_ID});`
    installationScript.id = HEAP_INSTALLATION_SCRIPT_ID
    document.head.appendChild(installationScript)
  }

  uninstallHeap() {
    const installationScript = document.getElementById(HEAP_INSTALLATION_SCRIPT_ID)
    const heapScript = document.querySelector('script[src*="heapanalytics"]')

    if (installationScript) {
      installationScript.remove()
    }

    if (heapScript) {
      heapScript.remove()
    }
  }

  identify({
    user,
    member,
    organization,
    subscription,
    themeKey,
    workspaceSize,
  }: IdentifyData) {
    if (!this.root.flags.flags.heap || !window.heap?.loaded) {
      return
    }

    if (!user || !organization) {
      return
    }

    const heapData: HeapData = {
      appVersion: config.VERSION,
      desktopVersion: this.desktopVersion,
      role: member.role ?? '',
      signup_date: user.createdAt ? formatDateToISO(user.createdAt) : '',
      theme_mode: themeKey,
      user_email_domain: user.email?.split('@')[1] ?? '',
      org_id: organization.id,
      is_paying: subscription.isActive,
      plan: subscription.type ?? '',
      org_creation_date: organization.createdAt
        ? formatDateToISO(organization.createdAt)
        : '',
      size: workspaceSize.toString(),
      onboarding_industry: organization.analytics?.industry,
      onboarding_size: organization.analytics?.teamSize,
      onboarding_use_case: organization.analytics?.useCase,
      onboarding_teams: organization.analytics?.teams,
      onboarding_need: organization.analytics?.need,
      onboarding_users: organization.analytics?.users,
      subscription_start_date: subscription.originalStartedAt
        ? formatDateToISO(subscription.originalStartedAt)
        : '',
      subscription_end_date: subscription.currentPeriodExpiresAt
        ? formatDateToISO(subscription.currentPeriodExpiresAt)
        : '',
      has_auto_charge: subscription.autochargeAmount > 0,
      clearbit_industry: organization.analytics?.enrichment?.category?.industry ?? '',
      clearbit_subindustry:
        organization.analytics?.enrichment?.category?.subIndustry ?? '',
      clearbit_employees:
        organization.analytics?.enrichment?.metrics?.employees?.toString() ?? '',
    }

    window.heap?.identify(user.id)
    window.heap?.addUserProperties(heapData)
  }

  track(eventName: string, properties?: Record<string, unknown>) {
    if (!window.heap?.loaded) {
      return
    }

    if (this.root.flags.flags.heap) {
      const eventProperties = {
        appVersion: config.VERSION,
        desktopVersion: this.desktopVersion,
        ...properties,
      }

      if (window.CommandBar) {
        window.CommandBar.trackEvent(eventName, eventProperties)
      }
      window.heap.track(eventName, eventProperties)
    }
  }

  reset() {
    if (!window.heap?.loaded) {
      return
    }

    window.heap.resetIdentity()
  }

  tearDown() {
    this.reset()
    this.uninstallHeap()
    this.disposeBag.dispose()
  }
}
