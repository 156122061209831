import { action, makeObservable } from 'mobx'

import { parseDate } from '@src/lib'

import type { BaseCodableReaction } from './BaseReaction'
import BaseReaction from './BaseReaction'

export interface CodableContactNoteReaction extends BaseCodableReaction {
  contactId: string | null
  noteId: string | null
}

export function isCodableContactNoteReaction(
  reaction: unknown,
): reaction is CodableContactNoteReaction {
  return Boolean(
    typeof reaction === 'object' &&
      reaction !== null &&
      'contactId' in reaction &&
      'noteId' in reaction &&
      reaction.contactId &&
      reaction.noteId,
  )
}

class ContactNoteReactionModel
  extends BaseReaction
  implements CodableContactNoteReaction
{
  contactId: string | null = null
  noteId: string | null = null

  constructor(attrs: CodableContactNoteReaction) {
    super()

    this.deserialize(attrs)

    makeObservable(this, {
      deserialize: action.bound,
      serialize: action.bound,
      toJSON: action.bound,
    })
  }

  deserialize(json?: Partial<CodableContactNoteReaction>) {
    if (json) {
      const { createdAt, updatedAt, ...attrs } = json
      Object.assign(this, attrs)
      this.createdAt = parseDate(createdAt || this.createdAt) as number
      this.updatedAt = parseDate(updatedAt || this.updatedAt) as number
    }
    return this
  }

  serialize(): CodableContactNoteReaction {
    return {
      id: this.id,
      body: this.body,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      userId: this.userId,
      noteId: this.noteId,
      contactId: this.contactId,
    }
  }

  toJSON(): CodableContactNoteReaction {
    return this.serialize()
  }
}

export default ContactNoteReactionModel
