import type { DBTypes } from '@openphone/dexie-database/types'
import { makeAutoObservable, toJS } from 'mobx'
import type { MarkOptional } from 'ts-essentials'

import type { StepDefinitionId } from '@src/app/components/workflow/types'
import uuid from '@src/lib/uuid'
import type { Model } from '@src/service/model/base'

export type RawWorkflowStepDefinition = DBTypes.WorkflowStepDefinitionRow

export default class WorkflowStepDefinitionModel implements Model {
  private raw: RawWorkflowStepDefinition

  get id() {
    return this.raw.id
  }

  get requiredConfiguration() {
    return this.raw.requiredConfiguration
  }

  get canComeAfterTrigger() {
    return this.raw.canComeAfterTrigger
  }

  get categoryMustMatchTrigger() {
    return this.raw.categoryMustMatchTrigger
  }

  get requiredPreviousSteps() {
    return this.raw.requiredPreviousSteps
  }

  get requiredCapabilities() {
    return this.raw.requiredCapabilities
  }

  get precludingSteps() {
    return this.raw.precludingSteps
  }

  get terminalFor() {
    return this.raw.terminalFor
  }

  get category() {
    return this.raw.category
  }

  get outputBranches() {
    return this.raw.outputBranches
  }

  get supportLevel() {
    return this.raw.supportLevel
  }

  constructor(attrs: MarkOptional<RawWorkflowStepDefinition, 'id'>) {
    this.raw = {
      ...attrs,
      id: (attrs.id as StepDefinitionId) ?? `WSD${uuid()}`.replace(/-/g, ''),
    }

    makeAutoObservable<this>(this)
  }

  deserialize(json: RawWorkflowStepDefinition): this {
    this.raw = json
    return this
  }

  serialize(): RawWorkflowStepDefinition {
    return toJS(this.raw)
  }

  localUpdate(attrs: Partial<RawWorkflowStepDefinition>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
