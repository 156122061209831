import { observer } from 'mobx-react-lite'
import { forwardRef } from 'react'

import { AppAvatar } from '@src/app/components/app-avatar'
import type { Identity } from '@src/service/model'
import AvatarRow, { type AvatarRowProps } from '@ui/AvatarRow'

export interface AppAvatarRowProps extends Omit<AvatarRowProps, 'children'> {
  identities: Identity[]
  classNameForAvatar?: (identity: Identity) => string
  classNameForCircle?: (identity: Identity) => string
}

const AppAvatarRow = forwardRef<HTMLDivElement, AppAvatarRowProps>(
  ({ identities, classNameForAvatar, classNameForCircle, ...props }, ref) => {
    return (
      <AvatarRow ref={ref} {...props}>
        {identities.map((identity, index) => (
          <AppAvatar
            // The index is part of the key in case the list contains
            // multiple of the same identity
            key={`${identity['phoneNumber'] ?? identity.id}-${index}`}
            className={classNameForAvatar?.(identity)}
            circleClassName={classNameForCircle?.(identity)}
            identity={identity}
            hideStatus={true}
            hideBadge={identities.length > 1}
          />
        ))}
      </AvatarRow>
    )
  },
)

export default observer(AppAvatarRow)
