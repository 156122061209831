import { useCallback } from 'react'

import { isArrayOfStrings } from '@src/lib/collections'
import useInstance from '@src/lib/hooks/useInstance'
import useKeyboardShortcuts from '@src/lib/hooks/useKeyboardShortcuts'
import type { FilterHandler } from '@src/lib/hooks/useKeyboardShortcuts'
import Stepper from '@src/lib/stepper'

export interface KeyStepperProps<T> {
  name: string
  node?: Element | Document
  items: readonly T[]
  deps?: any[]
  keys?: KeyMapping
  selectOnMouseEnter?: boolean
  selectOnHover?: boolean
  filter?: FilterHandler
  skip?: (item: T, index: number) => boolean
  cycle?: boolean
  handleSelect: (item: T | null, index?: number, event?: Event) => void
  handleChecked?: (item: T, index: number) => void
  defaultSelectedIndex?: number
  /**
   * When the items array mutates the stepper destroys and creates another
   * instance. We can choose to reset the selected index to the default
   * index provided on `defaultSelectedIndex` or reuse the selected index
   * from the previous instance.
   */
  resetToDefaultIndexOnUpdate?: boolean
}

export interface KeyStepperItemHandlers {
  onMouseEnter(): void
  onMouseLeave(): void
  onClick(): void
}

interface KeyStepperResult {
  selectedIndex: number
  setSelectedIndex: (index: number | null) => void
  getItemProps: (
    index: number,
    originalProps?: Partial<KeyStepperItemHandlers>,
  ) => KeyStepperItemHandlers
}

type Key = string | string[]

export interface KeyMapping {
  next?: Key
  prev?: Key
  start?: Key
  end?: Key
  select?: Key
  check?: Key
}

const NONE: KeyMapping = {}

const HORIZONTAL: KeyMapping = {
  next: 'ArrowRight',
  prev: 'ArrowLeft',
  start: 'Meta+ArrowLeft',
  end: 'Meta+ArrowRight',
  select: 'Enter',
  check: ['KeyX', 'Space'],
}

const VERTICAL: KeyMapping = {
  next: 'ArrowDown',
  prev: 'ArrowUp',
  start: 'Meta+ArrowUp',
  end: 'Meta+ArrowDown',
  select: 'Enter',
  check: ['KeyX', 'Space'],
}

export const KeyMappings = { NONE, HORIZONTAL, VERTICAL }

const createKeyMatcher = (shortcut: string) => (key?: Key) => {
  return isArrayOfStrings(key) ? key.includes(shortcut) : key === shortcut
}

export default function useKeyStepper<T>({
  name,
  node,
  items,
  deps = [],
  keys = KeyMappings.VERTICAL,
  skip,
  selectOnMouseEnter = true,
  selectOnHover = false,
  cycle = false,
  filter,
  handleSelect,
  handleChecked,
  defaultSelectedIndex = 0,
  resetToDefaultIndexOnUpdate = false,
}: KeyStepperProps<T>): KeyStepperResult {
  const stepper = useInstance<Stepper<T>>(
    (previousInstance) => {
      let index = defaultSelectedIndex

      if (!resetToDefaultIndexOnUpdate) {
        index =
          typeof previousInstance?.index === 'number' && previousInstance.index !== -1
            ? previousInstance.index
            : defaultSelectedIndex
      }

      return new Stepper(
        { data: items },
        {
          name,
          skip,
          index,
          cycle: cycle,
        },
      )
    },
    [items],
  )

  useKeyboardShortcuts({
    name,
    node: node || document,
    filter,
    handler: (shortcut, event) => {
      const keyMatches = createKeyMatcher(shortcut)
      if (keyMatches(keys.prev)) {
        stepper.prev()
        event.preventDefault()
      } else if (keyMatches(keys.next)) {
        stepper.next()
        event.preventDefault()
      } else if (keyMatches(keys.start)) {
        stepper.start()
        event.preventDefault()
      } else if (keyMatches(keys.end)) {
        stepper.end()
        event.preventDefault()
      } else if (keyMatches(keys.select)) {
        if (stepper.item !== null) {
          handleSelect(stepper.item, stepper.index, event)
        } else {
          handleSelect(null)
        }
        event.preventDefault()
      } else if (keyMatches(keys.check) && handleChecked) {
        if (stepper.item !== null) {
          handleChecked(stepper.item, stepper.index)
        }
        event.preventDefault()
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    dep: [stepper, handleChecked, handleSelect, ...deps],
  })

  const getItemProps = useCallback(
    (
      index: number,
      originalProps?: Partial<KeyStepperItemHandlers>,
    ): KeyStepperItemHandlers => {
      // @ts-expect-error unchecked index access
      const notSkip = !skip?.(stepper.items[index], index)
      return {
        onMouseEnter: () => {
          if ((selectOnMouseEnter || selectOnHover) && notSkip) {
            stepper.selectIndex(index)
          }
          originalProps?.onMouseEnter?.()
        },
        onMouseLeave: () => {
          if (selectOnHover && notSkip) {
            stepper.selectIndex(null)
          }
          originalProps?.onMouseLeave?.()
        },
        onClick: () => {
          if (notSkip) {
            stepper.selectIndex(index)
            // @ts-expect-error unchecked index access
            handleSelect(stepper.items[index], index)
          } else {
            originalProps?.onClick?.()
          }
        },
      }
    },

    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react-compiler/react-compiler -- FIXME: Fix this ESLint violation!, UXP-3732 - Fix React Compiler errors */
    [stepper, handleSelect, skip, selectOnHover, selectOnMouseEnter, ...deps],
  )

  return {
    selectedIndex: stepper.index,
    setSelectedIndex: stepper.selectIndex,
    getItemProps,
  }
}
