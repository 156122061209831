export type AiPromptDefinitionId = `APD${string}`
export type AiJobDefinitionId = `AJD${string}`
export type AiResourceDefinitionId = `ARD${string}`
export type AiToolDefinitionId = `AOD${string}`
export type AiTriggerDefinitionId = `ATD${string}`
export type AiTriggerDefinitionEntityId = `PN${string}`
export type AiTriggerDefinitionUserId = `US${string}`

export const isAiJobDefinitionId = (id: unknown): id is AiJobDefinitionId => {
  return typeof id === 'string' && /^AJD(.*)$/g.test(id)
}

export const isAiTriggerDefinitionId = (id: unknown): id is AiTriggerDefinitionId => {
  return typeof id === 'string' && /^ATD(.*)$/g.test(id)
}
