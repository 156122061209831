import * as Sentry from '@sentry/react'
import type { TwilioError } from '@twilio/voice-sdk'
import { Call, Device } from '@twilio/voice-sdk'
import { isValidNumber } from 'libphonenumber-js'
import type { IReactionDisposer } from 'mobx'
import { action, makeAutoObservable, observable, reaction, toJS, when } from 'mobx'

import type VoiceAgentTest from '@src/app/Automations/TestAiAgent/VoiceAgentTest'
import type { CallQualityWizardFeedbackSchema } from '@src/app/voice/CallQualityWizard'
import { markErrorAsIgnored } from '@src/lib/IgnoredError'
import { DisposeBag } from '@src/lib/dispose'
import { logError } from '@src/lib/log'
import type Service from '@src/service'
import type { VoiceAnalyticsCallQualityPayload } from '@src/service/analytics/VoiceAnalyticsStore'
import Collection from '@src/service/collections/Collection'
import { isDirectNumber } from '@src/service/model'
import makePersistable from '@src/service/storage/makePersistable'
import type {
  AddParticipantsParams,
  TransferParams,
  UpdateParticipantsParams,
  UpdateRoomParams,
} from '@src/service/transport/voice'
import type {
  RecordingStatusMessage,
  RoomParticipantMutedMessage,
  RoomParticipantOnHoldMessage,
  RoomParticipantSpeakingMessage,
  RoomParticipantStatusMessage,
  RoomParticipantUpdateMessage,
} from '@src/service/transport/websocket'

import type { ActiveCallFromType, ActiveCallToType } from './ActiveCall'
import ActiveCall from './ActiveCall'
import NoiseSuppressionAudioProcessor from './NoiseSuppressionAudioProcessor'
import SentryTwilioVoiceLogger from './SentryTwilioVoiceLogger'

interface IVoiceSession {
  token: string
  expiry: number
}

export default class VoiceStore {
  readonly calls = new Collection<ActiveCall>({ bindElements: true })

  audioDevices = new Map<string, MediaDeviceInfo>()
  inputDevices = new Map<string, MediaDeviceInfo>()
  device: Device | null = null
  defaultAudioInputDeviceId: MediaDeviceInfo['deviceId'] | null = null
  defaultAudioOutputDeviceId: MediaDeviceInfo['deviceId'] | null = null
  defaultAudioRingtoneDeviceId: MediaDeviceInfo['deviceId'] | null = null
  focused = false
  ready = false
  callQualityFeedbackRequest: VoiceAnalyticsCallQualityPayload | null = null

  private deviceSounds: Device.Options['sounds'] = {}
  private error: TwilioError.TwilioError | null = null
  private session: IVoiceSession | null = null
  private startDeviceDisposer: IReactionDisposer | null = null
  private audioProcessor: NoiseSuppressionAudioProcessor | null = null

  private readonly deviceAudioListeners: { [key: string]: (...args: any[]) => any } = {}
  private readonly deviceListeners: { [key: string]: (...args: any[]) => any } = {}
  private readonly disposeBag = new DisposeBag()
  private readonly sentryTwilioVoiceLogger = new SentryTwilioVoiceLogger()

  onRefreshRejected?: () => void

  constructor(private root: Service) {
    makeAutoObservable(this, {
      device: false,
      defaultAudioInputDeviceId: observable.ref,
      defaultAudioOutputDeviceId: observable.ref,
      defaultAudioRingtoneDeviceId: observable.ref,
    })
    this.setupInteractionListeners()
    this.handleWebsocket()

    this.disposeBag.add(
      reaction(
        () => ({
          isDebuggingCalls: this.root.flags.flags.webCallDebugging,
          device: this.device,
        }),
        ({ isDebuggingCalls, device }) => {
          if (!device) {
            return
          }
          device.updateOptions({
            logLevel: isDebuggingCalls ? 'DEBUG' : 'ERROR',
          })
          if (isDebuggingCalls) {
            this.sentryTwilioVoiceLogger.init().start()
          } else {
            this.sentryTwilioVoiceLogger.revert()
          }
        },
      ),
    )

    this.deviceListeners = {
      [Device.EventName.Registered]: this.handleRegistered,
      [Device.EventName.Error]: this.handleError,
      [Device.EventName.Unregistered]: this.handleUnregistered,
      [Device.EventName.Incoming]: this.handleIncoming,
      [Device.EventName.TokenWillExpire]: this.handleTokenWillExpire,
    }

    this.deviceAudioListeners = {
      deviceChange: this.handleAudioDeviceChange,
    }

    makePersistable(this, 'VoiceStore', {
      defaultAudioInputDeviceId: root.storage.sync(),
      defaultAudioOutputDeviceId: root.storage.sync(),
      defaultAudioRingtoneDeviceId: root.storage.sync(),
    })
  }

  /**
   * This is a workaround to start the audio context on the first user interaction
   * when a user's Media Engagement Index (MEI) Score is low for OpenPhone.
   *
   * This is a self-remedying issue, where over time, the MEI score will increase with usage
   * and the user will be able to place and receive calls without needing to interact
   * with the page first.
   *
   * See: https://linear.app/openphone/issue/COM-10213/one-way-audio-issue#comment-fdc8e2ae
   * See: https://developer.chrome.com/blog/autoplay
   */
  private setupInteractionListeners() {
    const resumeAudioContext = async () => {
      if (Device.audioContext?.state === 'suspended') {
        await Device.audioContext.resume()
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
    document.body.addEventListener('mousedown', resumeAudioContext, { once: true })
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
    document.body.addEventListener('keydown', resumeAudioContext, { once: true })
  }

  private get isAllowedToCall() {
    return (
      this.root.capabilities.features.callingEnabled &&
      !(
        this.root.billing.getCurrentSubscription().frozenState ||
        this.root.billing.getCurrentSubscription().isReviewRejected
      )
    )
  }

  get hasOngoingCall() {
    return this.ongoingCalls.length
  }

  get hasActiveCalls() {
    return this.calls.length > 0
  }

  get incomingCalls() {
    return this.calls.list.filter((c) => c.status === 'incoming')
  }

  get ongoingCalls() {
    return this.calls.list.filter(
      (c) =>
        c.status === 'connecting' ||
        c.status === 'reconnecting' ||
        c.status === 'connected' ||
        c.status === 'ringing',
    )
  }

  setCallQualityFeedbackRequest = (payload: VoiceAnalyticsCallQualityPayload | null) => {
    this.callQualityFeedbackRequest = payload
  }

  startCall = async (from: ActiveCallFromType, to: ActiveCallToType) => {
    if (!this.ready) {
      return
    }
    const callFrom =
      // FIXME: this is hack due to the current nerf of direct dialing
      false && to.every((o) => isDirectNumber(o.number))
        ? this.root.user.directNumber
        : from
    if (this.device && callFrom) {
      await this.setDefaultAudioInputDevice()

      const call = await ActiveCall.connect(this.root, this.device, callFrom, to)
      if (call) {
        this.calls.put(call)
        this.root.analytics.voice.callStarted(
          to.length + 1,
          to.map((target) => target.type),
        )
      }
    }
  }

  startVoiceAgentTestCall = async (
    phoneNumberId: string,
    voiceAgentTest: VoiceAgentTest,
  ) => {
    if (!this.ready || !this.device) {
      throw new Error('Failed to start test call, please try again')
    }

    // Get the phone number associated with this entity
    const phoneNumberModel = this.root.phoneNumber.collection.list.find(
      (pn) => pn.id === phoneNumberId,
    )

    if (!phoneNumberModel) {
      throw new Error('No phone number found for this entity')
    }

    // Validate the phone number
    if (!isValidNumber(phoneNumberModel.number)) {
      throw new Error('Phone number is invalid')
    }

    await this.setDefaultAudioInputDevice()
    const call = await ActiveCall.connectVoiceAgentTestCall(
      this.root,
      this.device,
      phoneNumberModel,
      [
        {
          number: phoneNumberModel.number,
          userId: null,
          type: 'number',
        },
      ],
      voiceAgentTest,
    )
    if (call) {
      this.calls.put(call)
      // TBD: do we want call started analytics here?
    }
  }

  recordings = {
    start: (roomId: string, participantId: string) => {
      return this.root.transport.voice.recordings.put(roomId, participantId, {
        action: 'start',
      })
    },
    resume: (roomId: string, participantId: string) => {
      return this.root.transport.voice.recordings.put(roomId, participantId, {
        action: 'resume',
      })
    },
    pause: (roomId: string, participantId: string) => {
      return this.root.transport.voice.recordings.put(roomId, participantId, {
        action: 'pause',
      })
    },
    summarize: (recordingId: string) => {
      return this.root.transport.voice.recordings.summarize(recordingId)
    },
    transcribe: (recordingId: string) => {
      return this.root.transport.voice.recordings.transcribe(recordingId)
    },
    bulkDelete: (activityId: string, recordingIds: string[]) => {
      return this.root.transport.voice.recordings.bulkDelete(activityId, recordingIds)
    },
  }

  room = {
    get: (roomId: string) => {
      return this.root.transport.voice.room.get(roomId)
    },

    transfer: (roomId: string, params: TransferParams) => {
      return this.root.transport.voice.room.transfer(roomId, params)
    },

    update: (roomId: string, params: UpdateRoomParams) => {
      return this.root.transport.voice.room.update(roomId, params)
    },
  }

  submitCallQualityFeedback = (feedback: CallQualityWizardFeedbackSchema) => {
    const isCallQualityFeedbackEnabled = this.root.flags.flags.webCallQualityFeedback
    if (!isCallQualityFeedbackEnabled) {
      return Promise.resolve()
    }
    return this.root.transport.voice.feedback.submit(feedback)
  }

  isCallQualityFeedbackModalShown = () => {
    const isCallQualityFeedbackEnabled = this.root.flags.flags.webCallQualityFeedback

    if (!isCallQualityFeedbackEnabled) {
      return false
    }

    const sampleRate = this.root.flags.flags.webCallQualityFeedbackSampleRate

    // calculate a random value between 0 and 100 and if it's less than the
    // sample rate, we show the modal
    const canShowCallQualityFeedback = Math.floor(Math.random() * 100) < sampleRate

    return canShowCallQualityFeedback
  }

  addParticipants(roomId: string, params: AddParticipantsParams) {
    return this.root.transport.voice.participants.add(roomId, params)
  }

  retryAddingParticipant(roomId: string, participantId: string) {
    return this.root.transport.voice.participants.addAgain(roomId, participantId)
  }

  updateParticipant(
    roomId: string,
    participantId: string,
    params: UpdateParticipantsParams,
  ) {
    return this.root.transport.voice.participants.update(roomId, participantId, params)
  }

  removeParticipant(roomId: string, participantId: string) {
    return this.root.transport.voice.participants.remove(roomId, participantId)
  }

  setAudioInputDevice(id: MediaDeviceInfo['deviceId']): Promise<void> {
    this.defaultAudioInputDeviceId = id
    return this.device?.audio?.setInputDevice(id) ?? Promise.resolve()
  }

  setAudioOutputDevice(id: MediaDeviceInfo['deviceId']): Promise<void> {
    this.defaultAudioOutputDeviceId = id
    return this.device?.audio?.speakerDevices.set(id) ?? Promise.resolve()
  }

  setAudioRingtoneDevice(id: MediaDeviceInfo['deviceId']): Promise<void> {
    this.defaultAudioRingtoneDeviceId = id
    return this.device?.audio?.ringtoneDevices.set(id) ?? Promise.resolve()
  }

  setDeviceSounds(sounds: Device.Options['sounds']) {
    this.deviceSounds = sounds
    this.device?.updateOptions({ sounds })
  }

  initialize() {
    this.startDevice()

    this.disposeBag.add(
      reaction(
        () => this.isAllowedToCall,
        () => {
          this.startDevice()
        },
      ),
      reaction(
        () => this.hasActiveCalls,
        (hasActiveCalls) => {
          if (!hasActiveCalls) {
            // Twilio recommends updating the token after a call, see:
            // https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceupdatetokentoken
            // Since `updateToken` has proven to be pretty buggy, we restart the device.
            this.startDevice()
          }
        },
      ),
    )
  }

  private refreshToken(): Promise<IVoiceSession> {
    return this.root.transport.voice
      .token()
      .then(
        action((res) => {
          this.session = {
            token: res.token,
            expiry: Date.parse(res.expiry),
          }
          return this.session
        }),
      )
      .catch((err) => {
        this.onRefreshRejected?.()
        throw err
      })
  }

  private startDevice() {
    this.startDeviceDisposer?.()
    this.sentryTwilioVoiceLogger.revert()
    this.startDeviceDisposer = when(
      // Refreshing the token with an ongoing call will cause it to drop, so we wait until
      // there are no active calls. We also wait for the app to be online and the UI to be loaded.
      () => this.root.transport.connectivity.online && !this.hasActiveCalls,
      () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        this.refreshToken().then(
          action((session) => {
            this.stopDevice()
            if (!this.isAllowedToCall) {
              return
            }

            // Add console.log interceptor before device initialization
            if (this.root.flags.flags.webCallDebugging) {
              this.sentryTwilioVoiceLogger.init().start()
            }

            this.error = null
            this.device = new Device(session.token, {
              allowIncomingWhileBusy: true,
              codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
              enableImprovedSignalingErrorPrecision: true,
              maxAverageBitrate: 510000,
              sounds: this.deviceSounds,
              logLevel: this.root.flags.flags.webCallDebugging ? 'debug' : 'error',
            })
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
            this.device.register()
            this.addProcessors()
            this.subscribeListeners()
          }),
        )
      },
    )
  }

  private handleRegistered = () => {
    this.ready = true
    this.setDefaultAudioOutputDevice()
    this.setDefaultAudioRingtoneDevice()
  }

  private handleError = (error: TwilioError.TwilioError) => {
    // If there is already an error it means we are waiting for the device to be
    // restarted, so we don't need to do anything else
    if (this.error) {
      return
    }

    this.ready = false
    this.error = error

    markErrorAsIgnored(
      this.error,
      'Ignoring Twilio error since we are restarting the device when it happens',
    )

    logError(this.error)

    this.startDevice()
  }

  private handleUnregistered = () => {
    this.ready = false
    // Immediately re-registering is something that Twilio recommends
    // See https://www.twilio.com/docs/voice/sdks/javascript/best-practices#device-is-not-available-for-calls-onunregistered-handler
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.device?.register()
  }

  private handleIncoming = async (call: Call) => {
    const incomingCall = await ActiveCall.fromIncomingCall(this.root, call)
    if (!incomingCall) {
      return
    }

    this.setCallQualityFeedbackRequest(null)

    const existingCall = this.calls.get(incomingCall.id)
    if (existingCall) {
      Sentry.captureMessage(`Duplicated incoming call id found: ${incomingCall.id}`, {
        contexts: {
          incomingCall: {
            existingCall: toJS(existingCall.data),
            incomingCall: toJS(incomingCall.data),
          },
        },
      })
    }

    this.calls.put(incomingCall)
  }

  private handleTokenWillExpire = () => {
    // Calling `device.updateToken` is pretty buggy, restarting the device is more reliable
    this.startDevice()
  }

  private handleAudioDeviceChange = () => {
    this.audioDevices =
      this.device?.audio?.availableOutputDevices ?? new Map<string, MediaDeviceInfo>()
    this.inputDevices =
      this.device?.audio?.availableInputDevices ?? new Map<string, MediaDeviceInfo>()
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'recording-status':
          return this.handleRecordingStatus(msg)
        case 'room-participant-update':
          return this.handleRoomParticipantUpdate(msg)
        case 'room-participant-speaking':
        case 'room-participant-on-hold':
        case 'room-participant-muted':
        case 'room-participant-status':
          return this.handleRoomParticipantPartialUpdate(msg)
      }
    })
  }

  private handleRecordingStatus(msg: RecordingStatusMessage) {
    const call = this.calls.find((call) => call.sid === msg.recording.callSid)

    if (call) {
      call.handleRecordingStatusUpdate(msg.recording.recordingStatus)
    }
  }

  private handleRoomParticipantUpdate(msg: RoomParticipantUpdateMessage) {
    const call = this.calls.find((call) => call.roomId === msg.participant.roomId)

    if (call) {
      call.upsertRoomParticipant(msg.participant.id, msg.participant)
    }
  }

  private handleRoomParticipantPartialUpdate(
    msg:
      | RoomParticipantSpeakingMessage
      | RoomParticipantOnHoldMessage
      | RoomParticipantMutedMessage
      | RoomParticipantStatusMessage,
  ) {
    const call = this.calls.find((call) => call.roomId === msg.participant.roomId)

    if (call) {
      call.updateRoomParticipant(msg.participant.id, msg.participant)
    }
  }

  private stopDevice = () => {
    if (this.root.flags.flags.webCallDebugging) {
      // Reset console.log if we modified it
      this.sentryTwilioVoiceLogger.revert()
    }
    this.unsubscribeListeners()
    this.removeProcessors()
    this.device?.destroy()
    this.device = null
  }

  private subscribeListeners() {
    Object.entries(this.deviceListeners).forEach(
      ([eventName, handler]) => this.device?.on(eventName, handler),
    )
    Object.entries(this.deviceAudioListeners).forEach(
      ([eventName, handler]) => this.device?.audio?.on(eventName, handler),
    )
  }

  private unsubscribeListeners() {
    Object.entries(this.deviceListeners).forEach(
      ([eventName, handler]) => this.device?.off(eventName, handler),
    )
    Object.entries(this.deviceAudioListeners).forEach(
      ([eventName, handler]) => this.device?.audio?.off(eventName, handler),
    )
  }

  private addProcessors() {
    if (!this.root.flags.flags.webNoiseSuppression) {
      return
    }

    this.audioProcessor = new NoiseSuppressionAudioProcessor()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.device?.audio?.addProcessor(this.audioProcessor)
  }

  private removeProcessors() {
    if (!this.audioProcessor) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.device?.audio?.removeProcessor(this.audioProcessor)
    this.audioProcessor = null
  }

  setDefaultAudioInputDevice() {
    if (!this.defaultAudioInputDeviceId) {
      return
    }

    return this.device?.audio
      ?.setInputDevice(this.defaultAudioInputDeviceId)
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
        if (error.message.includes('Device not found')) {
          this.defaultAudioInputDeviceId = null
        } else {
          logError(error)
        }
      })
  }

  private setDefaultAudioOutputDevice() {
    if (!this.defaultAudioOutputDeviceId) {
      return
    }

    this.device?.audio?.speakerDevices
      .set(this.defaultAudioOutputDeviceId)
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
        if (error.message.includes('Devices not found')) {
          this.defaultAudioOutputDeviceId = null
        } else {
          logError(error)
        }
      })
  }

  private setDefaultAudioRingtoneDevice() {
    if (!this.defaultAudioRingtoneDeviceId) {
      return
    }

    this.device?.audio?.ringtoneDevices
      .set(this.defaultAudioRingtoneDeviceId)
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
        if (error.message.includes('Devices not found')) {
          this.defaultAudioRingtoneDeviceId = null
        } else {
          logError(error)
        }
      })
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
