import { z } from 'zod'

const props = {
  duration: z.number().int().min(5).max(90),
  groupSize: z.number().positive().int(),
}

const AllAtOnceFormSchema = z.object({
  type: z.literal('ALL_AT_ONCE'),
  duration: props.duration,
})

export type AllAtOnceFormSchemaType = z.infer<typeof AllAtOnceFormSchema>

const RandomFormSchema = z.object({
  type: z.literal('RANDOM'),
  duration: props.duration,
  groupSize: props.groupSize,
})

export type RandomFormSchemaType = z.infer<typeof RandomFormSchema>

const SingleFormSchema = z.object({
  type: z.literal('SINGLE'),
  dialUserId: z.string(),
  duration: props.duration,
})

export type SingleFormSchemaType = z.infer<typeof SingleFormSchema>

const CustomFormSchema = z.object({
  type: z.literal('CUSTOM'),
  groups: z
    .array(
      z.object({
        id: z.string(),
        type: z.enum(['ALL_AT_ONCE', 'RANDOM']),
        userIds: z.array(z.string()).min(1),
        duration: props.duration,
        groupSize: props.groupSize,
      }),
    )
    .min(1),
})

export type CustomFormSchemaType = z.infer<typeof CustomFormSchema>

const RingOrderFormSchema = z.discriminatedUnion('type', [
  AllAtOnceFormSchema,
  RandomFormSchema,
  SingleFormSchema,
  CustomFormSchema,
])

export default RingOrderFormSchema

export type RingOrderFormSchemaType = z.infer<typeof RingOrderFormSchema>
