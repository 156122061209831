import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppStore } from '@src/app/context'
import Header from '@src/app/onboarding/Header'
import useMetaDescription from '@src/lib/hooks/useMetaDescription'
import useTitle from '@src/lib/hooks/useTitle'
import { logError } from '@src/lib/log'
import Button from '@ui/Button/Button'
import Typography from '@ui/Typography'
import { ContinueWithMagicCodeIcon, BrandEmailIcon } from '@ui/icons/custom'
import { GoogleIcon } from '@ui/icons/no-tint/misc'
import Spacer from '@ui/layout/Spacer'
import { themeClassNames, ThemeProvider } from '@ui/theme'

import * as styles from './UniversalLogin.css'
import UniversalLoginLegalFooter from './UniversalLoginLegalFooter'

const UniversalLogin = () => {
  const navigate = useNavigate()
  const { service, electron, intent } = useAppStore()
  const [searchParams] = useSearchParams()

  useTitle('Log in')
  useMetaDescription(
    'Log back into your OpenPhone account or create an account to get started with a Free Trial.',
  )

  const handleOpenUrl = (url: string) => {
    if (electron) {
      electron.app?.openExternal?.(url).catch(logError)
      return
    }

    window.open(url, '_self')
  }

  const handleLoginClick = () => {
    const email = searchParams.get('email')

    service.authV2.auth0Client
      ?.loginWithRedirect({
        appState: { intent: intent.intent },
        authorizationParams: {
          prompt: 'login',
          connection: 'Username-Password-Authentication',
          login_hint: email ?? undefined,
        },
        openUrl: handleOpenUrl,
      })
      .catch(logError)
  }

  const handleLoginClickPasswordless = () => {
    const email = searchParams.get('email')

    service.authV2.auth0Client
      ?.loginWithRedirect({
        appState: { intent: intent.intent },
        authorizationParams: {
          prompt: 'login',
          connection: 'email',
          login_hint: email ?? undefined,
        },
        openUrl: handleOpenUrl,
      })
      .catch(logError)
  }

  const handleGoogleLogin = () => {
    service.authV2.auth0Client
      ?.loginWithRedirect({
        appState: { intent: intent.intent },
        authorizationParams: {
          prompt: 'select_account',
          connection: 'google-oauth2',
        },
        openUrl: handleOpenUrl,
      })
      .catch(logError)
  }

  const handleNavigateToSignup = () => {
    navigate('/signup')
  }

  return (
    <ThemeProvider themeKey="light">
      <Header className={themeClassNames.light} />
      <div className={cx(styles.background, themeClassNames.light)}>
        <div className={styles.card}>
          <Typography color="textPrimary" variant="largeTitle" fontWeight="medium">
            Welcome back!
          </Typography>
          <Spacer height={8} />
          <Typography variant="body" color="textSecondary">
            It's great to see you 👋
          </Typography>
          <Typography variant="body" color="textSecondary">
            Log in to your account below
          </Typography>
          <Spacer height={24} />
          <div className={styles.actionsContainer}>
            <Button
              fullWidth
              onClick={handleGoogleLogin}
              icon={<GoogleIcon />}
              variant="outline"
            >
              <span className={styles.btnContent}>Continue with Google</span>
            </Button>
            <Spacer height={10} />
            <Typography variant="caption2" color="textSecondary">
              Or continue with your email
            </Typography>
            <Button
              fullWidth
              onClick={handleLoginClickPasswordless}
              icon={<ContinueWithMagicCodeIcon />}
              variant="outline"
            >
              <span className={styles.btnContent}>Email code</span>
            </Button>
            <Button
              fullWidth
              onClick={handleLoginClick}
              icon={<BrandEmailIcon />}
              variant="outline"
            >
              <span className={styles.btnContent}>Email & password</span>
            </Button>
          </div>
          <Spacer height={24} />
          <Typography variant="callout" color="textSecondary">
            Don’t have an account yet?
          </Typography>
          <Spacer height={4} />
          <button onClick={handleNavigateToSignup} className={styles.linkButton}>
            Sign up for free
          </button>
          <Spacer height={96} />
          <UniversalLoginLegalFooter />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default observer(UniversalLogin)
