import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import ColorPicker from '@src/app/contact/ItemEditor/components/ColorPicker'
import TagList from '@src/app/contact/ItemEditor/components/TagList'
import { useBackdropClick, useEditor } from '@src/app/contact/ItemEditor/context'
import type { TemplateTagItemOption } from '@src/lib/search'
import type { ContactTemplateItemModel } from '@src/service/model/contact/ContactTemplateItemModel'

export interface EditTagsProps {
  defaultValue: string[]
  templateItem: ContactTemplateItemModel
  onSave: (value: string[], newOption?: TemplateTagItemOption) => void
}

const EditTags = function ({ defaultValue, templateItem, onSave }: EditTagsProps) {
  const [, hide] = useEditor()
  const [values, setValues] = useState<string[]>(defaultValue)
  const [newValue, setNewValue] = useState<string | null>(null)
  const [colorSelection, setColorSelection] = useState(false)

  // Let's say there are two users with the same template. Both have their tags
  // editor opened. And "Tag A" is part of the selected tags. If User A removes
  // "Tag A" from the selected tags, and then proceeds to delete from the template
  // then the "Tag A" becomes stale for User B.
  //
  // This efffect makes sure to remove the Tag A for User B when it's deleted.
  useEffect(() => {
    if (!Array.isArray(templateItem.options)) {
      return
    }
    const availableTags = (templateItem.options as TemplateTagItemOption[]).map(
      (tag) => tag.name,
    )
    setValues((currentTags) => currentTags.filter((tag) => availableTags.includes(tag)))
  }, [templateItem.options])

  useBackdropClick(() => {
    onSave(values)
    hide()
  }, [])

  const save = () => onSave(values)

  const handleNew = (value: string) => {
    setNewValue(value)
    setColorSelection(true)
  }

  const handleSelect = (value: string) => {
    setValues((values) => Array.from(new Set([...values, value])))
  }

  const handleColorSelection = (color: string) => {
    if (newValue !== null) {
      const newValues = Array.from(new Set([...values, newValue]))
      setValues(newValues)
      onSave(newValues, { name: newValue, color })
      setColorSelection(false)
    }
  }

  const handleDelete = (value: string) => {
    setValues((values) => values.filter((v) => v !== value))
  }

  return colorSelection ? (
    <ColorPicker onSelect={handleColorSelection} />
  ) : (
    <TagList
      values={values}
      templateItem={templateItem}
      onNew={handleNew}
      onSelect={handleSelect}
      onDelete={handleDelete}
      onHide={hide}
      onSave={save}
    />
  )
}

export default observer(EditTags)
