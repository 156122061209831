import { createContext } from 'react'

import type { SubscriptionModel } from '@src/service/model'

interface BillingSubscriptionContextType {
  subscription: SubscriptionModel
}

const BillingSubscriptionContext = createContext<BillingSubscriptionContextType | null>(
  null,
)

export default BillingSubscriptionContext
