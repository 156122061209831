import { makeAutoObservable, observable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'
import type { TemplateTagItemOption } from '@src/lib/search'
import type ContactStore from '@src/service/contact-store'
import type { Model } from '@src/service/model/base'

const supportedTypes = [
  'address',
  'boolean',
  'date',
  'multi-select',
  'number',
  'string',
  'url',
  'created-by',
]

export type TemplateType =
  | 'address'
  | 'boolean'
  | 'date'
  | 'multi-select'
  | 'number'
  | 'string'
  | 'url'
  | 'created-by'

export interface CodableContactTemplateItem {
  createdAt?: number | null
  id: string
  key?: string | null
  name?: string | null
  options?: TemplateTagItemOption[] | object
  order?: number | null
  orgId?: string | null
  type: TemplateType | null
  updatedAt?: number | null
  local?: boolean | null
}

export class ContactTemplateItemModel implements Model {
  private raw: CodableContactTemplateItem

  constructor(
    private contactStore: ContactStore,
    attrs: Partial<ContactTemplateItemModel> = {},
  ) {
    this.raw = {
      id: objectId(),
      key: objectId(),
      name: null,
      options: [],
      order: null,
      orgId: null,
      type: null,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      local: false,
    }
    this.deserialize(attrs)

    this.setDefaultOption(this.type)

    makeAutoObservable<this, 'raw'>(this, {
      raw: observable.deep,
    })
  }

  get id(): string {
    return this.raw.id
  }

  get key(): string | null {
    return this.raw.key ?? null
  }

  get name(): string | null {
    return this.raw.name ?? null
  }

  get options(): TemplateTagItemOption[] | object {
    return this.raw.options ?? []
  }

  get order(): number | null {
    return this.raw.order ?? null
  }

  get orgId(): string | null {
    return this.raw.orgId ?? null
  }

  get type(): TemplateType | null {
    return this.raw.type ?? null
  }

  get createdAt(): number | null {
    return this.raw.createdAt ?? null
  }

  get updatedAt(): number | null {
    return this.raw.updatedAt ?? null
  }

  get local(): boolean | null {
    return this.raw.local ?? null
  }

  get isSupportedType() {
    return this.type ? supportedTypes.includes(this.type) : false
  }

  update(attrs: Partial<CodableContactTemplateItem>) {
    this.localUpdate(attrs)
    return this.contactStore.updateTemplate(this)
  }

  delete() {
    return this.contactStore.deleteTemplate(this)
  }

  deleteOption(option: TemplateTagItemOption) {
    if (!Array.isArray(this.raw.options)) {
      return
    }
    this.raw.options = this.raw.options.filter(
      (item: TemplateTagItemOption) =>
        !(item.name === option.name && item.color === option.color),
    )
  }

  renameOption(option: TemplateTagItemOption, name: string) {
    option.name = name
    void this.update(this)
  }

  deserialize = (json: Partial<CodableContactTemplateItem>) => {
    Object.assign(this.raw, json)

    this.setDefaultOption(json.type ?? null, json.options)

    if (json.updatedAt) {
      this.raw.updatedAt = parseDate(json.updatedAt)
    }

    if (json.createdAt) {
      this.raw.createdAt = parseDate(json.createdAt)
    }

    return this
  }

  serialize = (): CodableContactTemplateItem => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      key: this.key,
      name: this.name,
      options: toJS(this.options),
      order: this.order,
      orgId: this.orgId,
      type: this.type,
      updatedAt: this.updatedAt,
    }
  }

  localUpdate(attrs: Partial<CodableContactTemplateItem>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }

  private setDefaultOption(
    type: TemplateType | null,
    options?: TemplateTagItemOption[] | object,
  ) {
    if (options || this.options) {
      this.raw.options = options || this.options
    } else if (type === 'multi-select') {
      this.raw.options = []
    } else {
      this.raw.options = {}
    }
  }
}
