import type AppStore from '@src/app/AppStore'

export default class IntentStore {
  intent: string | null = null

  constructor(private router: AppStore['history']['router']) {
    this.setIntent()
  }

  setIntent() {
    const fullPath = location.pathname + location.search

    const excludedRoutes = [
      /^\/onboarding/,
      /^\/login/,
      /^\/signup/,
      /^\/authorize/,
      /^\/callback/,
      /^\/verification/,
      /^\/native/,
    ]

    const isExcluded = excludedRoutes.some((excludedRoute) =>
      excludedRoute.test(fullPath),
    )

    // ignore empty or root paths ('' or '/')
    if (fullPath.length <= 1 || isExcluded) {
      return
    }

    this.intent = fullPath
  }

  async navigateToIntent() {
    if (!this.intent) {
      return
    }

    await this.router.navigate(this.intent)

    this.intent = null
  }
}
