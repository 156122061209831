import type { BrowserOptions, User as SentryUser } from '@sentry/react'
import {
  browserProfilingIntegration,
  browserTracingIntegration,
  getClient,
  init,
  makeBrowserOfflineTransport,
  makeFetchTransport,
  setUser,
} from '@sentry/react'

import config from '@src/config'
import { isIgnoredError } from '@src/lib/IgnoredError'
import type { MemberModel, OrganizationModel } from '@src/service/model'

/**
 * The user context sent to Sentry.
 *
 * Some fields are used by Sentry (e.g. `id` and `email`), the rest are custom
 * to provide context to the user that experienced this error.
 *
 * @see https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
 */
type EnrichedSentryUser = Pick<Required<SentryUser>, 'id' | 'email'> & {
  name: string
  role: string
  organizationId: string
  organizationPlan?: string
}

type QueuedEvent = [RequestInfo | URL, RequestInit | undefined]

class SentryManagerSingleton {
  private queuedEvents: QueuedEvent[] = []
  private enabled = false

  /**
   * The sample rate for traces.
   *
   * Will be modified once the user gets identified and
   * feature flags are loaded.
   *
   * We need to set this higher than 0 by default to capture traces for the initial
   * load of the application.
   */
  private tracesSampleRate = 0.1

  enable() {
    this.enabled = true

    for (const queuedEvent of this.queuedEvents) {
      const [input, init] = queuedEvent
      const index = this.queuedEvents.indexOf(queuedEvent)

      setTimeout(() => {
        this.fetch(input, init).catch(() => null)
      }, 200 * index)
    }

    this.queuedEvents = []
  }

  disable() {
    this.enabled = false
  }

  initialize() {
    init({
      dsn: config.SENTRY_DSN,
      integrations: [
        browserProfilingIntegration(),
        browserTracingIntegration({
          enableInp: true,
        }),
      ],
      environment: config.ENV,
      release: config.FULL_VERSION,
      normalizeDepth: 10,
      tracesSampler: this.tracesSampler.bind(this),

      // We need to set this higher than 0 by default to capture traces for the initial load of the application.
      profilesSampleRate: 0.1,
      transport: (options) => {
        return makeBrowserOfflineTransport(() =>
          makeFetchTransport(options, this.fetch.bind(this)),
        )(options)
      },
      // Local environment does not need to tunnel events
      tunnel: import.meta.env.PROD ? '/api/sentry' : undefined,
      ignoreErrors: [
        // Prefer using RegExp to ensure we catch all instances of the error
        /ResizeObserver loop/gi,
        /Attempt to register when device is in state "registering"\. Must be "unregistered"\./gi,
        /Non-Error promise rejection captured/gi,
        /Event `CustomEvent` \(type=error\) captured as exception/gi,
        /Could not establish a connection\. Try again momentarily\./gi,
        /Failed to execute 'decodeAudioData' on 'BaseAudioContext'/gi,
        /conversation not found/gi,
        /cypress_runner\.js/gi,
      ],
      beforeSend: (event, hint) => {
        if (isIgnoredError(hint.originalException)) {
          return null
        }

        return event
      },
    })
  }

  setUser(
    user: Pick<Required<MemberModel>, 'id' | 'email' | 'name' | 'role'> | undefined,
    organization:
      | (Pick<Required<OrganizationModel>, 'id'> & { plan?: string })
      | undefined,
  ) {
    if (!user || !organization) {
      return
    }

    const sentryUser: EnrichedSentryUser = {
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.role,
      organizationId: organization.id,
      organizationPlan: organization.plan,
    }

    setUser(sentryUser)
  }

  setTracesSampleRate(tracesSampleRate: number) {
    this.tracesSampleRate = tracesSampleRate
  }

  setBrowserProfilingSampleRate(profilesSampleRate: number) {
    // Sentry doen't expose a way to use a function for this, so we have to
    // mutate the options directly.
    // We don't need to worry about `enabled` because the profiles sample rate
    // depends on traces being enabled as well.
    const options = getClient()?.getOptions() as BrowserOptions

    if (!options) {
      return
    }

    options.profilesSampleRate = profilesSampleRate
  }

  private tracesSampler(): boolean | number {
    return this.tracesSampleRate
  }

  private fetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    if (!this.enabled) {
      this.queuedEvents.push([input, init])
      return Promise.resolve(new Response('', { status: 200 }))
    }

    return fetch(input instanceof URL ? input.toString() : input, init)
  }
}

const SentryManager = new SentryManagerSingleton()

export default SentryManager
