import cx from 'classnames'

import * as styles from './Bone.css'

export interface BoneProps<C extends React.ElementType> extends React.HTMLProps<C> {
  component?: C
  className?: string
  style?: React.CSSProperties
  'data-testid'?: string
}

const Bone = <C extends React.ElementType>({
  className,
  style,
  component,
  ...rest
}: BoneProps<C>) => {
  const Component: React.ElementType = component || 'div'
  return <Component className={cx(styles.root, className)} style={style} {...rest} />
}

export default Bone
