import { useCallback, useEffect } from 'react'

import { useCurrentUser } from '@src/app/user'
import { areFeatureTooltipsDisabled } from '@src/config'
import type { YYYY, MM, DD } from '@src/lib'
import type { FeatureTooltipName } from '@src/service/model'

import type { FeatureTooltipProps } from './FeatureTooltip'
import useFeatureTooltipsActions from './useFeatureTooltipsActions'

export interface UseFeatureTooltipParams {
  name: FeatureTooltipName
  isDisabled?: boolean
  showForUsersCreatedBefore?: `${YYYY}-${MM}-${DD}`
  onClose?: FeatureTooltipProps['onClose']
}

export default function useFeatureTooltip({
  name,
  isDisabled,
  showForUsersCreatedBefore,
  onClose: onCloseProp,
}: UseFeatureTooltipParams): Pick<FeatureTooltipProps, 'isOpen' | 'onClose'> {
  const { dismiss } = useFeatureTooltipsActions()
  const user = useCurrentUser()

  if (showForUsersCreatedBefore) {
    const date = new Date(showForUsersCreatedBefore)
    const userCreatedAt = new Date(user?.createdAt ?? 0)

    // If the user was created after the date, disable the tooltip
    if (userCreatedAt.getTime() > date.getTime()) {
      isDisabled = true
    }
  }

  const isOpen = isDisabled ? false : !user.settings.web.newFeatureTooltips[name]

  const onClose = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions -- FIXME: Fix this ESLint violation!
    onCloseProp ? onCloseProp() : dismiss(name)
  }, [dismiss, name, onCloseProp])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    // Close the tooltip if the user reloads the page
    // But only if the reload URL is the same as the current URL to
    // prevent closing tooltips from other pages
    const reload = window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav as PerformanceNavigationTiming)
      .find((nav) => nav.type === 'reload')

    if (!reload) {
      return
    }

    const reloadUrl = new URL(reload.name)

    if (reloadUrl.pathname === window.location.pathname) {
      onClose()
    }
  }, [isOpen, onClose])

  return {
    isOpen: !areFeatureTooltipsDisabled && isOpen,
    onClose,
  }
}
