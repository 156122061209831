// These are internal-use human readable "ids" for the addons, so we can easily reference them in code
export const ADDON_IDS = {
  aiAgent: 'ai-agent',
} as const

// Addons may have more than one feature
export const ADDON_FEATURE_IDS = {
  aiAgent: {
    workflowVoiceAgent: 'workflowVoiceAgent',
  },
}
