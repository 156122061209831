import { makeObservable, observable } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import type { RawCallFallbackConfig } from './model'
import { CallFallbackConfigModel } from './model'
import type { PhoneNumberConfigUpdateErrorNotification } from './transport/websocket'
import type { CallFallbackConfigRepository } from './worker/repository/CallFallbackConfigRepository'
import { CALL_FALLBACK_CONFIG_TABLE_NAME } from './worker/repository/CallFallbackConfigRepository'

export default class CallFallbackConfigStore {
  readonly collection: PersistedCollection<
    CallFallbackConfigModel,
    CallFallbackConfigRepository
  >

  private readonly disposeBag = new DisposeBag()
  private readonly fetchedCallFallbackConfigsByPhoneNumber = new Set<string>()
  private readonly previousCallFallbackConfigsByPhoneNumberMap = new Map<
    string,
    RawCallFallbackConfig
  >()

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table(CALL_FALLBACK_CONFIG_TABLE_NAME),
      classConstructor: (json) =>
        new CallFallbackConfigModel(json as RawCallFallbackConfig),
    })

    makeObservable<this>(this, {
      collection: observable,
    })

    this.disposeBag.add(this.subscribeToWebSocket())
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.load()
  }

  getByPhoneNumberId(phoneNumberId: string): CallFallbackConfigModel | null {
    const localCallFallbackConfig = this.collection.find(
      (item) => item.phoneNumberId === phoneNumberId,
    )
    this.fetchCallFallbackConfigFromRemoteIfNeeded(
      phoneNumberId,
      localCallFallbackConfig,
    ).catch(() => null)

    return localCallFallbackConfig ?? null
  }

  private async fetchCallFallbackConfigFromRemoteIfNeeded(
    phoneNumberId: string,
    localCallFallbackConfig: CallFallbackConfigModel | undefined,
  ) {
    // We want to fetch the call fallback config at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedCallFallbackConfigsByPhoneNumber.has(phoneNumberId)
    if (alreadyFetched) {
      return
    }

    const remoteCallFallbackConfig: RawCallFallbackConfig | undefined =
      await this.fetchByPhoneNumberId(phoneNumberId)
    this.fetchedCallFallbackConfigsByPhoneNumber.add(phoneNumberId)

    if (localCallFallbackConfig && remoteCallFallbackConfig) {
      // If both local and remote exist, update the local call fallback config with remote data
      localCallFallbackConfig.deserialize(remoteCallFallbackConfig)
    } else if (!localCallFallbackConfig && remoteCallFallbackConfig) {
      // If the call fallback config exists only on remote, add it to the local collection
      this.putRawCallFallbackConfigToCollection(remoteCallFallbackConfig)
    }
  }

  update(
    callFallbackConfig: CallFallbackConfigModel,
    attrs: Partial<RawCallFallbackConfig>,
  ) {
    const previousCallFallbackConfig = callFallbackConfig.serialize()
    this.storePreviousCallFallbackConfigByPhoneNumber(previousCallFallbackConfig)

    callFallbackConfig.localUpdate({
      ...previousCallFallbackConfig,
      ...attrs,
    })

    return this.root.transport.voice.callFallbackConfig
      .update(callFallbackConfig.serialize())
      .catch((error) => {
        this.restorePreviousCallFallbackConfig(callFallbackConfig.phoneNumberId)
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors -- FIXME: Fix this ESLint violation!
        return Promise.reject(error)
      })
  }

  private load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  private putRawCallFallbackConfigToCollection(
    rawCallFallbackConfig: RawCallFallbackConfig,
  ) {
    const callFallbackConfig = new CallFallbackConfigModel(rawCallFallbackConfig)
    this.collection.put(callFallbackConfig)
  }

  private async fetchByPhoneNumberId(phoneNumberId: string) {
    const response =
      await this.root.transport.voice.callFallbackConfig.getByPhoneNumberId(phoneNumberId)

    if (!response) {
      return
    }

    return response
  }

  private storePreviousCallFallbackConfigByPhoneNumber(
    previousCallFallbackConfig: RawCallFallbackConfig,
  ) {
    this.previousCallFallbackConfigsByPhoneNumberMap.set(
      previousCallFallbackConfig.phoneNumberId,
      previousCallFallbackConfig,
    )
  }

  private restorePreviousCallFallbackConfig(phoneNumberId: string | null) {
    if (!phoneNumberId) {
      return
    }

    const previousCallFallbackConfig =
      this.previousCallFallbackConfigsByPhoneNumberMap.get(phoneNumberId)
    if (!previousCallFallbackConfig) {
      return
    }

    this.putRawCallFallbackConfigToCollection(previousCallFallbackConfig)
  }

  private onPhoneNumberConfigUpdateError(data: PhoneNumberConfigUpdateErrorNotification) {
    const validData = data.validData

    if (validData) {
      this.putRawCallFallbackConfigToCollection(validData)
    } else {
      this.restorePreviousCallFallbackConfig(data.phoneNumberId)
    }
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'call-fallback-config-update': {
          this.putRawCallFallbackConfigToCollection(data.callFallback)
          break
        }
        case 'phone-number-config-update-error': {
          this.onPhoneNumberConfigUpdateError(data)
          break
        }
      }
    })
  }
}
