import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import SpinnerScreen from '@src/app/components/SpinnerScreen'
import { Header, Page } from '@src/app/settings/common'
import Button from '@ui/Button/Button'
import Typography from '@ui/Typography'
import Spacer from '@ui/layout/Spacer'
import { VStack } from '@ui/layout/Stack'

import * as styles from './ParagonOAuthCallback.css'
import { useIntegrations, paragonCustomIntegrations } from './useIntegrations'
import { useParagon } from './useParagon'
import { useParagonAuthentication } from './useParagonAuthentication'
import { getParagonIntegrations, sendParagonCredentials } from './utils/paragonRequests'

export const PARAGON_OAUTH_REDIRECT_URL = `${window.location.origin}/oauth/integrations/`

const handleError = (message: string, details?: string) => ({
  message,
  details,
})

function ParagonOAuthCallback() {
  const { available = [] } = useIntegrations()
  const { completeInstall, getUserToken } = useParagon()
  const { integration } = useParams<{ integration: string }>()
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const authorizationCode = searchParams.get('code')
  const errorParam = searchParams.get('error')
  const isDesktop = searchParams.get('isDesktop') === 'true' ? true : false
  const { resync } = useParagonAuthentication(isDesktop)

  const [errorState, setErrorState] = useState<{
    message: string
    details?: string
  } | null>(null)

  // Remove the prefix 'custom.' from any custom integrations
  const normalizedAvailableIntegrations = useMemo(
    () => available.map((integration) => integration.replace('custom.', '')),
    [available],
  )

  const isSupportedIntegration =
    integration && normalizedAvailableIntegrations.includes(integration)

  const handleInstall = useCallback(async () => {
    if (!authorizationCode || !integration) {
      setErrorState(handleError('Missing required OAuth parameters.'))
      return
    }

    const paragonIntegrationName = paragonCustomIntegrations.includes(integration)
      ? `custom.${integration}`
      : integration

    try {
      if (isDesktop) {
        const token = await getUserToken()

        if (!token) {
          setErrorState(handleError('No token available'))
          return
        }

        const integrationId = await getIntegrationId(integration, token)

        await sendParagonCredentials(token, authorizationCode, integrationId)
      } else {
        await completeInstall(paragonIntegrationName, { authorizationCode })
      }
      await resync(paragonIntegrationName)

      if (isDesktop) {
        window.location.replace(`openphone://settings/integrations/${integration}`)
        window.close()
      } else {
        window.close()
        // Return focus to the original page and reload to reflect the new integration
        const opener = window.opener as Window | null
        if (opener) {
          opener.focus()
          opener.location.reload()
        }
      }
    } catch (err) {
      const error = err instanceof Error ? err : new Error(String(err))
      setErrorState(handleError('Installation failed', error.message))
    }
  }, [authorizationCode, completeInstall, getUserToken, integration, isDesktop, resync])

  useEffect(() => {
    if (errorParam) {
      setErrorState(handleError('OAuth callback returned an error', errorParam))
      return
    }

    if (!isSupportedIntegration || !integration) {
      setErrorState(handleError('Unsupported integration.'))
      return
    }

    handleInstall().catch((err) => {
      const error = err instanceof Error ? err : new Error(String(err))
      setErrorState(handleError('Complete install failed', error.message))
    })
  }, [
    errorParam,
    integration,
    authorizationCode,
    isSupportedIntegration,
    isDesktop,
    search,
    handleInstall,
    resync,
  ])

  return (
    <>
      {errorState ? (
        <VStack gap={32}>
          <Spacer size={48} />
          <Typography variant="title3" color="textPrimary">
            Unable to complete integration setup
          </Typography>
          <Typography variant="body" color="textSecondary">
            Please close this window and try again
          </Typography>
          {errorState.message && (
            <Typography
              variant="code"
              as="pre"
              color="textSecondary"
              className={classNames(styles.debugInfo)}
            >
              <VStack gap={6} alignItems="flex-start">
                <span>Error: {errorState.message}</span>
                {errorState.details && <span>{errorState.details}</span>}
              </VStack>
            </Typography>
          )}
          <Button color="default" onClick={() => window.close()}>
            Close
          </Button>
        </VStack>
      ) : (
        <Page>
          <Header
            title="Integration Setup"
            subtitle="Connecting to your integration..."
          />
          <Spacer size={32} />
          <SpinnerScreen />
        </Page>
      )}
    </>
  )
}
export default observer(ParagonOAuthCallback)

const getIntegrationId = async (integrationName: string, token: string) => {
  try {
    const integrationsResponse = await getParagonIntegrations(token)

    const integration = integrationsResponse.find(
      (integrationOption) =>
        integrationOption.name.toLowerCase() === integrationName.toLowerCase(),
    )

    if (!integration) {
      throw new Error('Integration not found')
    }

    return integration.id
  } catch (err) {
    throw new Error(`Failed to fetch integration ID: ${(err as Error).message}`)
  }
}
