import type { ConversationPresence } from '@src/service/conversation-store'
import type MemberStore from '@src/service/member-store'
import type { ConversationModel, MemberModel, MemberPresence } from '@src/service/model'

export function isUnreadConversation(
  conversation?: Pick<
    ConversationModel,
    'phoneNumberId' | 'directNumberId' | 'deletedAt' | 'snoozedUntil' | 'unreadCount'
  >,
) {
  if (!conversation) {
    return false
  }

  const doesNotHavePhoneNumberId =
    !conversation.phoneNumberId && !conversation.directNumberId
  const isDeleted = Boolean(conversation.deletedAt)
  const isSnoozed = conversation.snoozedUntil
    ? conversation.snoozedUntil > Date.now()
    : false

  if (doesNotHavePhoneNumberId || isDeleted || isSnoozed) {
    return false
  }

  return conversation.unreadCount > 0
}

export function userPresenceIdsToMemberPresences(
  userPresences: ConversationPresence,
  memberStore: MemberStore,
) {
  return (result: MemberPresence[], id: string) => {
    const usersPresence = userPresences[id]
    if (!usersPresence) {
      return result
    }
    result.push({
      member: memberStore.get(id),
      status: usersPresence.status,
      timestamp: usersPresence.timestamp,
    })
    return result
  }
}

type IsActiveArgs = {
  status: MemberPresence['status']
}
export function isActive({ status }: IsActiveArgs) {
  return status === 'active' || status === 'typing'
}

export function isMemberCurrentUser(
  member: MemberModel | null,
  currentUserId: string | undefined,
) {
  return member?.id === currentUserId
}

export function sortViewersComparator(userId: string) {
  return (a: MemberPresence, b: MemberPresence) => {
    if (isMemberCurrentUser(a.member, userId)) {
      return -1
    }
    if (isMemberCurrentUser(b.member, userId)) {
      return 1
    }
    if (isActive(a) && !isActive(b)) {
      return -1
    }
    if (!isActive(a) && isActive(b)) {
      return 1
    }
    return b.timestamp - a.timestamp
  }
}
