import Debug from 'debug'
import pickBy from 'lodash/fp/pickBy'
import { useEffect } from 'react'
import type { Subscription } from 'rxjs'
import { fromEvent } from 'rxjs'

import { isMacDesktop } from '@src/lib/device'
import { hasInputValue, isEditableElement, isElement } from '@src/lib/dom'

const debug = Debug('op:use-keyboard-shortcut')

type KeyboardHandler = (shortcut: string, event: KeyboardEvent) => void
export type FilterHandler = (shortcut: string, event: KeyboardEvent) => boolean

let metaKeyDown = false
const subscriptions = new WeakMap<
  Element | Document,
  {
    handlers: { name: string; handler: KeyboardHandler; filter: FilterHandler }[]
    subscription: Subscription
  }
>()

/**
 * Keep track of whether the Meta key is pressed or not
 */
fromEvent<KeyboardEvent>(document, 'keydown').subscribe((event: KeyboardEvent) => {
  if (event.key === 'Meta') {
    metaKeyDown = true
  }
})

fromEvent<KeyboardEvent>(document, 'keyup').subscribe((event: KeyboardEvent) => {
  if (event.key === 'Meta') {
    metaKeyDown = false
  }
})

/**
 * When you use command+tab or command+space that are OS level shortcuts, the browser
 * window loses focus and the 'keydown' event listener is not called. We need to reset
 * active keys on focus as a workaround.
 */
fromEvent(window, 'focus').subscribe(() => {
  metaKeyDown = false
})

/**
 * Detect Select All and reverse (Command+A)
 */
function handleSelectAll() {
  const isExtent = (node: unknown) => {
    return isElement(node) && node.classList.contains('extent')
  }

  // check the selection extends over two extent nodes (top and bottom)
  const s = window.getSelection()
  if (
    !s ||
    s.anchorNode === s.focusNode ||
    !isExtent(s.anchorNode) ||
    !isExtent(s.focusNode)
  ) {
    return
  }

  // clear page's selection (this isn't perfect and a user may see
  // a flash of selection anyway- use selectstart + rAF to fix this)
  s.removeAllRanges()
}

document.addEventListener('selectionchange', handleSelectAll)

interface DefaultWithInputOnScreenOptions {
  disallowedShortcuts?: Record<string, boolean>
  allowedShortcutsAlways?: string[]
  allowedShortcutsWhenEmpty?: string[]
}

/**
 * Checks if the path of the event contains an interactive element whose
 * own interactions should be respected
 */
function pathContainsInteractiveElements(path: EventTarget[]): boolean {
  return path.some((target) => {
    if (target instanceof HTMLButtonElement) {
      return true
    }

    if (target instanceof HTMLElement) {
      return (
        target.getAttribute('role') === 'menu' ||
        target.getAttribute('role') === 'grid' ||
        target.getAttribute('role') === 'separator' ||
        target.getAttribute('aria-haspopup')
      )
    }

    return false
  })
}

export const defaultWithInputOnScreen =
  ({
    disallowedShortcuts = {},
    allowedShortcutsAlways = [],
    allowedShortcutsWhenEmpty = [],
  }: DefaultWithInputOnScreenOptions = {}) =>
  (shortcut: string, event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return false
    }
    if (Object.keys(pickBy(Boolean)(disallowedShortcuts)).includes(shortcut)) {
      return false
    }
    if (pathContainsInteractiveElements(event.composedPath())) {
      return false
    }
    if (!(event.target && isEditableElement(event.target))) {
      return true
    }
    if (allowedShortcutsAlways.includes(shortcut)) {
      return true
    }

    const allowKeyboardWhenEmpty = event.target.hasAttribute(
      'data-allow-keyboard-when-empty',
    )

    return (
      isEditableElement(event.target) &&
      !hasInputValue(event.target) &&
      allowedShortcutsWhenEmpty.includes(shortcut) &&
      allowKeyboardWhenEmpty
    )
  }

const defaultFilter = (shortcut: string, event: KeyboardEvent) => {
  const skip =
    event.defaultPrevented ||
    pathContainsInteractiveElements(event.composedPath()) ||
    (!event.metaKey &&
      [
        'Escape',
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'Enter',
        'NumpadEnter',
        'Tab',
      ].includes(event.code) === false &&
      event.target &&
      isEditableElement(event.target))
  return !skip
}

export default function useKeyboardShortcuts(params: {
  name: string
  node: Element | Document | null
  handler: KeyboardHandler
  filter?: FilterHandler
  dep?: any[]
}) {
  const { name, node, handler, filter: filterFunc, dep = [] } = params

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    const element = node && 'current' in node ? (node as any).current : node

    if (!element) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    const existing = subscriptions.get(element)
    if (existing) {
      existing.handlers.push({ name, handler, filter: filterFunc || defaultFilter })
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      const subscription = fromEvent<KeyboardEvent>(element, 'keydown').subscribe(
        (event: KeyboardEvent) => {
          if (event.key === 'Meta') {
            return
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          const subscription = subscriptions.get(element)
          let code = event.code

          if (code === 'NumpadEnter') {
            code = 'Enter'
          }

          const shortcut = (
            isMacDesktop()
              ? [
                  metaKeyDown ? 'Meta' : null,
                  event.shiftKey ? 'Shift' : null,
                  event.altKey ? 'Alt' : null,
                  event.ctrlKey ? 'Ctrl' : null,
                  code,
                ]
              : // Windows and Linux essentially don't have a "meta" key like
                // Mac's Command key. Instead, most (hopefully all) shortcuts
                // coded to use `Meta` can safely be remapped to Ctrl, so when we
                // construct the shortcut we treat the Ctrl key as the meta key.
                //
                // TODO: We should probably remove support for shortcuts that use
                // Ctrl and Alt and ONLY support Shift and Meta (Command for Mac,
                // Ctrl otherwise).
                [
                  event.ctrlKey ? 'Meta' : null,
                  event.shiftKey ? 'Shift' : null,
                  event.altKey ? 'Alt' : null,
                  code,
                ]
          )
            .filter((a) => a)
            .join('+')

          const names: string[] = []
          let defaultPreventedBy: string | null = null
          if (subscription) {
            for (let i = subscription.handlers.length - 1; i >= 0; i--) {
              const handler = subscription.handlers[i]
              if (handler?.filter(shortcut, event)) {
                handler.handler(shortcut, event)
                if (!defaultPreventedBy && event.defaultPrevented) {
                  defaultPreventedBy = handler.name
                }
                names.push(handler.name)
              }
            }
          }

          debug(
            `%O ran through %O on %O. defaultPrevented on %O`,
            shortcut,
            names,
            element,
            defaultPreventedBy || 'none',
          )
        },
      )
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      subscriptions.set(element, {
        handlers: [{ name, handler, filter: filterFunc || defaultFilter }],
        subscription,
      })
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      const subscription = subscriptions.get(element)
      if (subscription) {
        const newHandlers = subscription.handlers.filter((h) => h.handler !== handler)
        if (newHandlers.length === 0) {
          subscription.subscription.unsubscribe()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          subscriptions.delete(element)
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          subscriptions.set(element, {
            handlers: newHandlers,
            subscription: subscription.subscription,
          })
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, [node, ...dep])
}
