import kebabCase from 'lodash/fp/kebabCase'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'

import { useAppStore } from '@src/app/context'
import { DeepLink } from '@src/component/DeepLink'
import Button from '@src/component/button'
import Typography from '@ui/Typography'
import { ArrowLeftIcon } from '@ui/icons/tint/20/general'
import Spacer from '@ui/layout/Spacer'
import { HStack } from '@ui/layout/Stack'

import * as styles from './Header.css'

export interface HeaderProps extends Omit<React.HTMLProps<HTMLDivElement>, 'title'> {
  title: ReactNode
  titleBadge?: ReactNode
  callout?: ReactNode
  subtitle?: ReactNode
  backTitle?: string
  onBack?: () => void
  actions?: ReactNode
  id?: string
  isDescriptionRow?: boolean
  disableDeepLink?: boolean
}

const Header = ({
  title,
  titleBadge,
  callout,
  subtitle,
  backTitle,
  onBack,
  actions,
  className,
  id,
  isDescriptionRow,
  disableDeepLink = false,
  ...props
}: HeaderProps) => {
  const { history } = useAppStore()

  const handleBack = () => {
    if (onBack) {
      onBack()
    } else {
      history.goBack()
    }
  }

  const alternativeId = typeof title === 'string' ? kebabCase(title) : undefined

  return (
    <div {...props} className={className}>
      {backTitle && (
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <Button
          variant="text"
          size="small"
          className={styles.backButton}
          onClick={handleBack}
        >
          <ArrowLeftIcon className={styles.backIcon} />
          <Typography variant="caption1" color="textSecondary" fontWeight="regular">
            {backTitle}
          </Typography>
        </Button>
      )}
      {/* TODO: WFA Delete the callout prop and this Spacer once the WFE beta has ended. */}
      {callout ? (
        <>
          {callout}
          <Spacer size={20} />
        </>
      ) : null}
      <div
        className={styles.content({
          isColumn: !!isDescriptionRow,
        })}
      >
        <div>
          <DeepLink id={id ?? alternativeId} disabled={disableDeepLink}>
            <HStack gap={6} className={styles.titleContainer}>
              {typeof title === 'string' ? (
                <Typography variant="title3" color="textPrimary" className={styles.title}>
                  {title}
                </Typography>
              ) : (
                title
              )}
              {titleBadge}
            </HStack>
          </DeepLink>

          {subtitle != null && (
            <Typography variant="body" color="textSecondary">
              {subtitle}
            </Typography>
          )}
        </div>
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
    </div>
  )
}

export default observer(Header)
