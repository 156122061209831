import type { Table } from 'dexie'
import { makeAutoObservable, observable } from 'mobx'

import type WorkflowStore from '@src/service/WorkflowStore'
import type { AnalyticsStore } from '@src/service/analytics'
import Collection from '@src/service/collections/Collection'
import type WorkflowDefinitionModel from '@src/service/model/workflow/WorkflowDefinitionModel'
import type { RawWorkflowDefinition } from '@src/service/model/workflow/WorkflowDefinitionModel'
import type StorageService from '@src/service/storage/StorageService'
import type Transport from '@src/service/transport'
import type { AgentResource } from '@src/service/transport/AiClient'
import type {
  AiJobDefinition,
  AiJobDefinitionByIdInput,
  AiJobDefinitionListInput,
} from '@src/service/transport/ai-client/aiJobDefinitionSchema'
import type {
  AiPromptDefinitionByIdInput,
  AiPromptDefinitionUpdateInput,
  AiPromptDefinitionCreateInput,
  AiPromptDefinitionDeleteInput,
  AiPromptDefinitionActiveListInput,
  AiPromptDefinitionListInput,
} from '@src/service/transport/ai-client/aiPromptDefinitionSchema'
import type {
  AiToolDefinitionByIdInput,
  AiToolDefinitionListInput,
} from '@src/service/transport/ai-client/aiToolDefinitionSchema'
import type {
  AiTriggerDefinitionByIdInput,
  AiTriggerDefinitionCreateInput,
  AiTriggerDefinitionDeleteInput,
  AiTriggerDefinitionListInput,
  AiTriggerDefinitionUpdateInput,
  AiTriggerDefinitionValidateExistingInput,
  AiTriggerDefinitionValidateInput,
  CodableAiTriggerDefinition,
} from '@src/service/transport/ai-client/aiTriggerDefinitionSchema'
import {
  isAiTriggerDefinitionId,
  type AiJobDefinitionId,
  type AiTriggerDefinitionId,
} from '@src/service/transport/ai-client/idSchemas'
import { AI_TRIGGER_DEFINITION_TABLE_NAME } from '@src/service/worker/repository/AiTriggerDefinitionRepository'

type UpdateResourceAccessProps = {
  isAllowed: boolean
  resource: AgentResource
  triggerDefinitionId: AiTriggerDefinitionId
  workflowDefinitionId: string
  blockId: string
}

export default class AutomationsStore {
  agentJobDefinitionsCollection: Collection<AiJobDefinition>
  private readonly aiAgentTriggerDefinitionsTable: Table<CodableAiTriggerDefinition>

  constructor(
    private storage: StorageService,
    private transport: Transport,
    private workflowStore: WorkflowStore,
    private analytics: AnalyticsStore,
  ) {
    this.agentJobDefinitionsCollection = new Collection<AiJobDefinition>()
    this.aiAgentTriggerDefinitionsTable = this.storage.database.table(
      AI_TRIGGER_DEFINITION_TABLE_NAME,
    )

    this.subscribeToWebSocket()
    makeAutoObservable(
      this,
      {
        agentJobDefinitionsCollection: observable.deep,
      },
      {
        autoBind: true,
      },
    )
  }

  getResources() {
    return this.transport.ai.automations.getResources()
  }

  getResourceById(id: string) {
    return this.transport.ai.automations.getResourceById(id)
  }

  createResourcePage(title: string, content: string) {
    return this.transport.ai.automations.createResourcePage(title, content)
  }

  updateResourcePage(id: string, title: string, content: string) {
    return this.transport.ai.automations.updateResourcePage(id, title, content)
  }

  deleteResource(id: string) {
    return this.transport.ai.automations.deleteResource(id)
  }

  async getTriggerDefinitions(input: AiTriggerDefinitionListInput, signal?: AbortSignal) {
    const localTriggerDefinitions = await this.aiAgentTriggerDefinitionsTable.toArray()
    const response = await this.transport.ai.triggerDefinitions.getAll(input, signal)
    const triggerDefinitions = [...response.data, ...localTriggerDefinitions]
    await this.aiAgentTriggerDefinitionsTable.bulkPut(triggerDefinitions)
    return triggerDefinitions
  }

  async getTriggerDefinitionById(
    input: AiTriggerDefinitionByIdInput,
    signal?: AbortSignal,
  ) {
    const triggerDefinition = await this.transport.ai.triggerDefinitions.getById(
      input,
      signal,
    )
    await this.aiAgentTriggerDefinitionsTable.put(triggerDefinition)
    return triggerDefinition
  }

  async createTriggerDefinition(
    input: AiTriggerDefinitionCreateInput,
    signal?: AbortSignal,
  ) {
    const triggerDefinition = await this.transport.ai.triggerDefinitions.create(
      input,
      signal,
    )
    await this.aiAgentTriggerDefinitionsTable.put(triggerDefinition)
    return triggerDefinition
  }

  async updateLocalTriggerDefinition(
    triggerDefinitionId: string,
    workflowDefinitionId: string,
    stepId: string,
    update: Partial<CodableAiTriggerDefinition>,
  ) {
    const triggerDefinition =
      await this.aiAgentTriggerDefinitionsTable.get(triggerDefinitionId)

    if (!triggerDefinition) {
      return
    }

    const updatedTriggerDefinition = {
      ...triggerDefinition,
      ...update,
    }

    await this.aiAgentTriggerDefinitionsTable.put(updatedTriggerDefinition)

    // Update the step to put the workflow in the draft/unpublished changes state
    this.workflowStore.updateNode({
      definitionId: workflowDefinitionId,
      stepId,
      update: {},
      skipAddingToHistory: true,
    })
  }

  deleteRemovedAiAgentTriggerDefinitionsFromRemote(
    definition: WorkflowDefinitionModel,
    definitionBeforeModifications: RawWorkflowDefinition,
  ) {
    Object.values(definitionBeforeModifications.workflowSteps)
      .filter((step) => step.definitionId === 'WSDvoiceAgent')
      .forEach((step) => {
        if (definition.workflowSteps[step.id]) {
          return
        }

        const triggerDefinitionId = step.configuration?.find(
          (c) => c.variableKey === 'WVagentTriggerDefinitionId',
        )?.value

        if (!isAiTriggerDefinitionId(triggerDefinitionId)) {
          return
        }

        void this.transport.ai.triggerDefinitions.delete({
          id: triggerDefinitionId,
        })

        this.agentJobDefinitionsCollection.delete(triggerDefinitionId)
      })
  }

  // Discard local Ai Agent trigger definitions for a workflow
  discardLocalAiAgentTriggerDefinitions(
    workflowSteps: WorkflowDefinitionModel['workflowSteps'],
  ) {
    const triggerDefinitionIds = Object.values(workflowSteps)
      .map((step) => {
        const triggerDefinitionId = step?.configuration?.find(
          (config) => config.variableKey === 'WVagentTriggerDefinitionId',
        )?.value as AiTriggerDefinitionId

        return triggerDefinitionId
      })
      .filter(Boolean)

    void Promise.all(
      triggerDefinitionIds.map((triggerId) =>
        this.discardLocalTriggerDefinition(triggerId),
      ),
    )
  }

  async discardLocalTriggerDefinition(triggerDefinitionId: AiTriggerDefinitionId) {
    const remoteTriggerDefinition = await this.transport.ai.triggerDefinitions.getById({
      id: triggerDefinitionId,
    })

    if (!remoteTriggerDefinition) {
      // delete the local trigger definition
      await this.aiAgentTriggerDefinitionsTable.delete(triggerDefinitionId)
      return
    }

    // update the local trigger definition with the remote one
    await this.aiAgentTriggerDefinitionsTable.put(remoteTriggerDefinition)
  }

  async updateTriggerDefinition(
    input: AiTriggerDefinitionUpdateInput,
    signal?: AbortSignal,
  ) {
    const triggerDefinition = await this.transport.ai.triggerDefinitions.update(
      input,
      signal,
    )
    await this.aiAgentTriggerDefinitionsTable.put(triggerDefinition)
    return triggerDefinition
  }

  async updateResourceAccess({
    isAllowed,
    resource,
    triggerDefinitionId,
    workflowDefinitionId,
    blockId,
  }: UpdateResourceAccessProps) {
    const triggerDefinition =
      await this.aiAgentTriggerDefinitionsTable.get(triggerDefinitionId)

    const updatedResources = isAllowed
      ? [{ definitionId: resource.id }, ...(triggerDefinition?.resources || [])]
      : triggerDefinition?.resources.filter((r) => r.definitionId !== resource.id)

    const updatedTriggerDefinition = {
      ...triggerDefinition,
      resources: updatedResources,
    } as CodableAiTriggerDefinition

    // Update the step to put the workflow in the draft/unpublished changes state
    this.workflowStore.updateNode({
      definitionId: workflowDefinitionId,
      stepId: blockId,
      update: {},
      skipAddingToHistory: true,
    })

    if (!isAllowed) {
      this.analytics.aiAutomations.removeKnowledgePageAccess(resource.title)
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return this.aiAgentTriggerDefinitionsTable.put(updatedTriggerDefinition)
  }

  async deleteTriggerDefinition(
    input: AiTriggerDefinitionDeleteInput,
    signal?: AbortSignal,
  ) {
    await this.transport.ai.triggerDefinitions.delete(input, signal)
    await this.aiAgentTriggerDefinitionsTable.delete(input.id)
  }

  validateTriggerDefinition(
    input: AiTriggerDefinitionValidateInput,
    signal?: AbortSignal,
  ) {
    return this.transport.ai.triggerDefinitions.validate(input, signal)
  }

  validateExistingTriggerDefinition(
    input: AiTriggerDefinitionValidateExistingInput,
    signal?: AbortSignal,
  ) {
    return this.transport.ai.triggerDefinitions.validateExisting(input, signal)
  }

  async getJobDefinitions(input?: AiJobDefinitionListInput, signal?: AbortSignal) {
    return this.transport.ai.jobDefinitions.getAll(input, signal).then((response) => {
      this.agentJobDefinitionsCollection.putBulk(response.data)
      return response.data
    })
  }

  async getJobDefinitionById(input: AiJobDefinitionByIdInput, signal?: AbortSignal) {
    const jobDefinition = await this.transport.ai.jobDefinitions.getById(input, signal)
    this.agentJobDefinitionsCollection.put(jobDefinition)
    return jobDefinition
  }

  getAiAgentPromptDefinitions(input: AiPromptDefinitionListInput, signal?: AbortSignal) {
    return this.transport.ai.aiAgentPromptDefinitions.getAll(input, signal)
  }

  getAiAgentPromptDefinitionById(
    input: AiPromptDefinitionByIdInput,
    signal?: AbortSignal,
  ) {
    return this.transport.ai.aiAgentPromptDefinitions.getById(input, signal)
  }

  getActiveAiAgentPromptDefinitions(
    input: AiPromptDefinitionActiveListInput,
    signal?: AbortSignal,
  ) {
    return this.transport.ai.aiAgentPromptDefinitions.getActive(input, signal)
  }

  createAiAgentPromptDefinition(
    input: AiPromptDefinitionCreateInput,
    signal?: AbortSignal,
  ) {
    return this.transport.ai.aiAgentPromptDefinitions.create(input, signal)
  }

  updateAiAgentPromptDefinition(
    input: AiPromptDefinitionUpdateInput,
    signal?: AbortSignal,
  ) {
    return this.transport.ai.aiAgentPromptDefinitions.update(input, signal)
  }

  deleteAiAgentPromptDefinition(
    input: AiPromptDefinitionDeleteInput,
    signal?: AbortSignal,
  ) {
    return this.transport.ai.aiAgentPromptDefinitions.delete(input, signal)
  }

  getToolDefinitions(input: AiToolDefinitionListInput, signal?: AbortSignal) {
    return this.transport.ai.toolDefinitions.getAll(input, signal)
  }

  getToolDefinitionById(input: AiToolDefinitionByIdInput, signal?: AbortSignal) {
    return this.transport.ai.toolDefinitions.getById(input, signal)
  }

  /**
   * Count the number of call flows (inboxes) in which the specified resource is attached.
   *
   * @param entityIds - List of entity IDs used to filter the relevant call flows.
   * @param resourceId - The identifier of the resource to check.
   * @returns The number of call flows that have the resource attached.
   */
  async getResourceUsageCountInCallflows(entityIds: string[], resourceId: string) {
    const entityIdsChecked = new Set<string>()

    const count = await this.aiAgentTriggerDefinitionsTable
      .where('entityId')
      .anyOf(entityIds)
      .filter((triggerDefinition) => {
        const hasResource = triggerDefinition.resources.some(
          (r) => r.definitionId === resourceId,
        )

        if (hasResource && !entityIdsChecked.has(triggerDefinition.entityId)) {
          entityIdsChecked.add(triggerDefinition.entityId)
          return true
        }

        return false
      })
      .count()

    return count
  }

  get localTriggerDefinitions() {
    return this.aiAgentTriggerDefinitionsTable.toArray()
  }

  async getLocalTriggerDefinitionsById() {
    const localDefinitions = await this.localTriggerDefinitions
    return localDefinitions.reduce(
      (acc, triggerDefinition) => {
        acc[triggerDefinition.id] = triggerDefinition
        return acc
      },
      {} as Record<AiTriggerDefinitionId, CodableAiTriggerDefinition>,
    )
  }

  get localJobDefinitions() {
    return this.agentJobDefinitionsCollection.list
  }

  get localJobDefinitionsById() {
    return this.localJobDefinitions.reduce(
      (acc, jobDefinition) => {
        acc[jobDefinition.id] = jobDefinition
        return acc
      },
      {} as Record<AiJobDefinitionId, AiJobDefinition>,
    )
  }

  subscribeToWebSocket() {
    this.transport.onNotificationData.subscribe((event) => {
      switch (event.type) {
        case 'agent-trigger-definition-create':
          void this.aiAgentTriggerDefinitionsTable.put(event.definition)
          return
        case 'agent-trigger-definition-update':
          void this.aiAgentTriggerDefinitionsTable.put(event.definition)
          return
        case 'agent-trigger-definition-delete':
          void this.aiAgentTriggerDefinitionsTable.delete(event.id)
          return
      }
    })
  }
}
