import Button from '@src/component/ButtonV2'

import type { PromptAction } from '..'

interface ActionProps {
  action: PromptAction
  onClick: (action: PromptAction) => void
}

export default function Action({ action, onClick }: ActionProps) {
  const color =
    action.type === 'primary'
      ? 'primary'
      : action.type === 'secondary-outlined'
      ? 'neutral'
      : 'textSecondary'
  const variant =
    action.type === 'primary' || action.type === 'secondary-outlined'
      ? 'contained'
      : 'text'

  return action.type === 'destructive' ? (
    // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
    <Button
      autoFocus
      key={action.title}
      height={36}
      color="danger"
      variant="contained"
      onClick={() => onClick(action)}
    >
      {action.title}
    </Button>
  ) : (
    // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
    <Button
      key={action.title}
      height={36}
      autoFocus={action.type === 'primary'}
      variant={variant}
      color={color}
      onClick={() => onClick(action)}
    >
      {action.title}
    </Button>
  )
}
