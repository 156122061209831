import { useDraggable } from '@dnd-kit/core'
import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import { useMemo } from 'react'

import useReaction from '@src/lib/hooks/useReaction'

import * as styles from './ScrollBar.css'
import type { ScrollViewStore } from './store'

interface ScrollBarProps {
  store: ScrollViewStore
}

const ScrollBar = ({ store }: ScrollBarProps) => {
  const id = useMemo(() => nanoid(), [])

  const {
    node: knobRef,
    listeners,
    setNodeRef: setKnobRef,
  } = useDraggable({ id: `scrollbar-${id}` })

  useReaction(
    () => store.knobHeight,
    (height) => {
      if (!knobRef.current) {
        return
      }
      // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
      knobRef.current.style.height = `${height}px`
    },
    { fireImmediately: true },
    [store],
  )

  useReaction(
    () => store.knobOffset,
    (offset) => {
      if (!knobRef.current) {
        return
      }
      knobRef.current.style.top = `${offset}px`
    },
    { fireImmediately: true },
    [store],
  )

  useReaction(
    () => store.isDragging,
    (dragging) => {
      if (!knobRef.current) {
        return
      }

      if (dragging) {
        knobRef.current.classList.add(styles.dragging)
      } else {
        knobRef.current.classList.remove(styles.dragging)
      }
    },
    { fireImmediately: true },
    [store],
  )

  return (
    <div className={styles.root}>
      <div
        ref={setKnobRef}
        className={styles.knob({ isKnobHidden: store.knobHidden })}
        {...listeners}
      />
    </div>
  )
}

export default observer(ScrollBar)
