import { makeAutoObservable, observable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'
import type { Model } from '@src/service/model/base'

import type { BaseCodableContactSettings } from './BaseCodableContactSettings'

export interface RawSharedContactSettings extends BaseCodableContactSettings {
  createdAt: number | null
  defaultSharingIds: string[]
  deletedAt: number | null
  deletedBy: string | null
  orgId: string | null
  suggestionsEnabled: boolean
}

export interface CodableSharedContactSettings extends BaseCodableContactSettings {
  deletedAt: number | null
  deletedBy: string | null
  orgId: string | null
  defaultSharingIds: string[]
  suggestionsEnabled: boolean
}

export class SharedContactSettingsModel implements Model {
  private raw: RawSharedContactSettings

  constructor(attributes: Partial<RawSharedContactSettings>) {
    makeAutoObservable<this, 'raw'>(this, {
      raw: observable.deep,
    })

    this.raw = {
      createdAt: null,
      id: objectId(),
      meta: null,
      updatedAt: null,
      userId: null,
      defaultSharingIds: [],
      deletedAt: null,
      deletedBy: null,
      orgId: null,
      suggestionsEnabled: false,
    }

    this.deserialize(attributes)
  }

  get id(): string {
    return this.raw.id
  }

  get createdAt(): number | null {
    return this.raw.createdAt
  }

  get meta(): { hasRefreshToken: boolean } | null {
    return this.raw.meta
  }

  get updatedAt(): number | null {
    return this.raw.updatedAt
  }

  get userId(): string | null {
    return this.raw.userId
  }

  get defaultSharingIds(): string[] {
    return this.raw.defaultSharingIds
  }

  get deletedAt(): number | null {
    return this.raw.deletedAt
  }

  get deletedBy(): string | null {
    return this.raw.deletedBy
  }

  get orgId(): string | null {
    return this.raw.orgId
  }

  get suggestionsEnabled(): boolean {
    return this.raw.suggestionsEnabled
  }

  deserialize(json: Partial<RawSharedContactSettings>) {
    const { updatedAt, createdAt, deletedAt, ...rest } = json

    Object.assign(this.raw, rest)

    if (updatedAt) {
      this.raw.updatedAt = parseDate(updatedAt)
    }

    if (createdAt) {
      this.raw.createdAt = parseDate(createdAt)
    }

    if (deletedAt) {
      this.raw.deletedAt = parseDate(deletedAt)
    }

    return this
  }

  serialize = (): CodableSharedContactSettings => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      meta: toJS(this.meta),
      updatedAt: this.updatedAt,
      userId: this.userId,
      defaultSharingIds: toJS(this.defaultSharingIds),
      deletedAt: this.deletedAt,
      deletedBy: this.deletedBy,
      orgId: this.orgId,
      suggestionsEnabled: this.suggestionsEnabled,
    }
  }

  localUpdate(attrs: Partial<RawSharedContactSettings>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
