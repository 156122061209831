import createDatabase from '@openphone/dexie-database'
import Dexie from 'dexie'
import zip from 'lodash/fp/zip'

import { logError } from '@src/lib/log'
import type MainWorker from '@src/service/worker/main'
import type Repository from '@src/service/worker/repository/base'

import AsyncStorage from './AsyncStorage'
import SyncStorage from './SyncStorage'

const AUTH0_KEY_PREFIX = '@@auth0spajs@@'

export type StorageEngine = AsyncStorage | SyncStorage

const clearCache = async () => {
  try {
    const keys = await window.caches.keys()
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
    keys.forEach((key) => window.caches.delete(key))
  } catch (error) {
    // operating on the cache storage is not supported in incognito mode
    // gracefully handle the error since it should not block any further operations
    logError(error)
  }
}
export default class StorageService {
  database: Dexie

  constructor(private worker: MainWorker) {
    this.database = createDatabase()
  }

  async(classConstructor?: (data: any) => any): AsyncStorage {
    return new AsyncStorage(this.database.table('kv'), classConstructor)
  }

  sync<T = unknown>(): SyncStorage<T> {
    return new SyncStorage<T>()
  }

  async reset() {
    const restorableKeys = [
      'AuthStore.session',
      'ApiClient.visitorId',
      'ApiClient.visitorIdUpdatedAt',
      'env-override',
      /**
       * When a user joins a workspace during onboarding, we clear the storage
       * and do a page reload so this ensures the user ends up in the correct
       * flow after the page reloads.
       */
      'OnboardingUiState.isJoinWorkspace',
      'AppStore.themeKey',
      'VoiceStore.defaultAudioInputDeviceId',
      'VoiceStore.defaultAudioOutputDeviceId',
      'VoiceStore.defaultAudioRingtoneDeviceId',
      /**
       * Identifiers used for tracking user sessions by LaunchDarkly
       */
      'dd_id',
      'dd_anonymous_id',
      /**
       * Special key that's used to override randomly generated key by LaunchDarkly
       * Used for testing purposes
       */
      'anon_user_key',
      /**
       * Used to display the logout reason
       */
      'UniversalLoginAuthStore.logoutReason',
      /**
       * Used to track the session start time
       */
      'UniversalLoginAuthStore.sessionStartedOn',
    ]
    const restorableValues = restorableKeys.map((key) => localStorage.getItem(key))
    localStorage.clear()
    for (const [key, value] of zip(restorableKeys, restorableValues)) {
      if (key && value) {
        localStorage.setItem(key, value)
      }
    }

    if ('caches' in window) {
      await clearCache()
    }

    if (this.database.isOpen()) {
      const allKvKeys = await this.database
        .table('kv')
        .toCollection()
        .keys()
        // eslint-disable-next-line @typescript-eslint/no-base-to-string -- FIXME: Fix this ESLint violation!
        .then((keys) => keys.map((key) => key.toString()))

      const restorableKvKeys = [
        'AuthStore.session',
        // we need to persist auth information that's saved by auth0-spa-js
        // the keys are dynamic, but always start with the following pattern
        ...allKvKeys.filter((key) => key.startsWith(AUTH0_KEY_PREFIX)),
      ]

      let restorableKvValues: unknown[] = []

      if (restorableKvKeys.length !== 0) {
        restorableKvValues = await Promise.all(
          restorableKvKeys.map((key) => this.database.table('kv').get(key)),
        )
      }

      await Promise.all(this.database.tables.map((t) => t.clear()))

      if (restorableKvValues.length !== 0) {
        await Promise.all(
          zip(restorableKvKeys, restorableKvValues).map(([key, value]) => {
            if (key && value) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
              this.database.table('kv').add(value, key)
            }
          }),
        )
      }
    }
  }

  async clearAll() {
    const restorableKeys = [
      'ApiClient.visitorId',
      'ApiClient.visitorIdUpdatedAt',
      'env-override',
      'AppStore.themeKey',
      'VoiceStore.defaultAudioInputDeviceId',
      'VoiceStore.defaultAudioOutputDeviceId',
      'VoiceStore.defaultAudioRingtoneDeviceId',
      /**
       * Identifiers used for tracking user sessions by LaunchDarkly
       */
      'dd_id',
      'dd_anonymous_id',
      /**
       * Special key that's used to override randomly generated key by LaunchDarkly
       * Used for testing purposes
       */
      'anon_user_key',
      /**
       * Used to display the logout reason
       */
      'UniversalLoginAuthStore.logoutReason',
    ]
    const restorableValues = restorableKeys.map((key) => localStorage.getItem(key))
    localStorage.clear()
    for (const [key, value] of zip(restorableKeys, restorableValues)) {
      if (key && value) {
        localStorage.setItem(key, value)
      }
    }

    if ('caches' in window) {
      await clearCache()
    }

    if (this.database.isOpen()) {
      return this.database.delete().then(() => (this.database = createDatabase()))
    }
  }

  exists(dbName: string): Promise<boolean> {
    return Dexie.exists(dbName)
  }

  delete(dbName: string): Promise<void> {
    return Dexie.delete(dbName)
  }

  table<T extends Repository>(collection: string): T {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return this.worker.repo[collection]
  }
}
