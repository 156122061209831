import type { DependencyList } from 'react'

import useAsyncEffect from '@src/lib/hooks/useAsyncEffect'

export default function useAsyncAction<T>(
  action: () => Promise<T>,
  callback: (value: T) => void,
  dependencies: DependencyList,
) {
  useAsyncEffect(async (signal) => {
    const value = await action()
    if (signal.aborted) {
      return
    }
    callback(value)
  }, dependencies)
}
