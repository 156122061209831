import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import type { CSSProperties } from 'react'
import { forwardRef, useImperativeHandle } from 'react'

import useStore from '@src/lib/hooks/useStore'

import ScrollBar from './ScrollBar'
import ScrollContainer from './ScrollContainer'
import * as styles from './ScrollView.css'
import { ScrollViewStore, type Size } from './store'

interface ScrollViewRef {
  scrollTo(scroll: ScrollToOptions): void
}

export interface ScrollViewProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'onResize'> {
  rootRef?: React.MutableRefObject<HTMLDivElement | null>
  scrollContainerRef?: React.MutableRefObject<HTMLDivElement | null>
  contentClassName?: string
  hideScroll?: boolean
  withShadows?: boolean
  overscrollBehavior?: CSSProperties['overscrollBehavior']
  onResize?: (size: Size) => void
  onPassiveScroll?: (point: { left: number; top: number }) => void
}

const ScrollView = forwardRef<ScrollViewRef, ScrollViewProps>(
  (
    {
      name,
      rootRef,
      scrollContainerRef,
      className,
      children,
      contentClassName,
      hideScroll,
      onResize,
      onPassiveScroll,
      withShadows = false,
      overscrollBehavior,
      ...props
    },
    outerRef,
  ) => {
    const store = useStore(
      () => new ScrollViewStore({ hideScroll, onResize, onPassiveScroll, name }),
      [hideScroll, onResize, onPassiveScroll, name],
    )

    const pointerSensor = useSensor(PointerSensor)
    const sensors = useSensors(pointerSensor)

    useImperativeHandle(
      outerRef,
      () => ({
        scrollTo: (scroll) => {
          store.setScrollOffset(scroll?.top ?? 0)
        },
      }),
      [store],
    )

    return (
      <div
        {...props}
        ref={rootRef}
        className={cx(styles.root, className)}
        onMouseEnter={store.onMouseEnter}
        onMouseLeave={store.onMouseLeave}
      >
        <ScrollContainer
          ref={scrollContainerRef}
          store={store}
          className={contentClassName}
          withShadows={withShadows}
          overscrollBehavior={overscrollBehavior}
        >
          {children}
        </ScrollContainer>
        <DndContext
          autoScroll={false}
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
          onDragMove={store.onKnobDrag}
          onDragStart={store.onKnobDragStart}
          onDragEnd={store.onKnobDragEnd}
        >
          <ScrollBar store={store} />
        </DndContext>
      </div>
    )
  },
)

export default observer(ScrollView)
