import { NotFoundError } from '@openphone/internal-api-client'
import { isValidEmail } from '@openphone/lib/email'
import { parsePhoneNumber } from 'libphonenumber-js'
import { action, makeAutoObservable } from 'mobx'
import { createRef } from 'react'
import { matchPath } from 'react-router-dom'

import type AppStore from '@src/app/AppStore'
import type IUiStore from '@src/app/IUiStore'
import config from '@src/config'
import assertNever from '@src/lib/assertNever'
import { DisposeBag } from '@src/lib/dispose'

import { googleLogin } from './google-login'

type LoginStep = 'login' | 'signup' | 'login_code' | 'signup_code' | 'password' | 'forgot'
type LoadingStatus =
  | 'none'
  | 'sending_code'
  | 'sending_reset_password'
  | 'authenticating_with_google'
  | 'logging_in'

export class LoginUiStore implements IUiStore {
  email: string | null = null
  loading: LoadingStatus = 'none'
  invite?: { email: string; name: string; role: string }
  referralCode?: string
  inviteCode?: string
  number: string | null = null

  readonly inputRef: React.MutableRefObject<HTMLInputElement | null> = createRef()

  private disposeBag = new DisposeBag()

  constructor(private app: AppStore) {
    makeAutoObservable(this, {})

    this.email = this.app.history.query.email ?? null
    this.number = this.app.history.query.number ?? null
    this.fetchInvite()
    this.fetchCoupon()
    this.handleReferralCode()
    this.handlePrepopulatedEmail()
    this.handlePrepopulatedNumber()
  }

  get step(): LoginStep | undefined {
    const isPath = (pattern: string) =>
      matchPath(pattern, this.app.history.location.pathname)

    if (isPath('/login')) {
      return 'login'
    } else if (isPath('/signup')) {
      return 'signup'
    } else if (isPath('/signup/code')) {
      return 'signup_code'
    } else if (isPath('/login/password')) {
      return 'password'
    } else if (isPath('/login/code')) {
      return 'login_code'
    } else if (isPath('/login/forgot')) {
      return 'forgot'
    } else if (isPath('/coupon/:couponId')) {
      return 'signup'
    }
  }

  get mode() {
    const path = this.app.history.location.pathname
    return path.startsWith('/login') ? 'login' : 'signup'
  }

  get coupon() {
    return this.app.service.billing.coupon
  }

  get isPromptShown(): boolean {
    return this.app.prompt.isShown
  }

  setInitialStep() {
    return this.setStep(this.mode === 'signup' ? this.step ?? 'login' : 'login')
  }

  toggleMode = () => {
    if (this.mode === 'login') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.setStep('signup')
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.setStep('login')
    }
  }

  setStep = (step: LoginStep) => {
    switch (step) {
      case 'login': {
        return this.app.history.router.navigate('/login')
      }
      case 'signup': {
        const path = this.app.history.location.pathname
        const newPath =
          path.startsWith('/signup') || path.startsWith('/coupon') ? path : '/signup'
        return this.app.history.router.navigate(newPath)
      }
      case 'login_code': {
        return this.app.history.router.navigate('/login/code')
      }
      case 'signup_code': {
        return this.app.history.router.navigate({
          pathname: '/signup/code',
          search: this.email
            ? `?${new URLSearchParams({ email: this.email })}`
            : undefined,
        })
      }
      case 'password': {
        return this.app.history.router.navigate('/login/password')
      }
      case 'forgot': {
        return this.app.history.router.navigate('/login/forgot')
      }
      default: {
        assertNever(step, `Unhandled login step: ${step}`)
      }
    }
  }

  continueToCode = (email: string) => {
    if (!isValidEmail(email)) {
      return this.app.toast.showError(new Error('Email address is not valid.'))
    }

    this.loading = 'sending_code'
    this.email = email
    const operation =
      this.step === 'login'
        ? this.app.service.auth.startPasswordlessSignin(email.toLowerCase())
        : this.app.service.auth.startPasswordlessRegister(email.toLowerCase())

    operation
      .then(() => this.setStep(this.step === 'login' ? 'login_code' : 'signup_code'))
      .catch(this.app.toast.showError)
      .finally(this.stopLoading)
  }

  continueToPassword = (email: string) => {
    this.email = email
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.setStep('password')
  }

  protected handleLoginSuccess = () => {
    this.app.service.analytics.workspace.login('passwordless')
    return this.navigateToIntent()
  }

  protected handleSignupSuccess = ({ trackGTM }: { trackGTM: boolean }) => {
    this.app.service.analytics.workspace.signup('passwordless', trackGTM)
  }

  protected verifyPasswordlessLogin = (code: string, recaptcha_token?: string) => {
    const email = this.email || ''
    return this.app.service.auth
      .verifyPasswordlessSignin(
        email.toLowerCase(),
        code,
        this.inviteCode,
        recaptcha_token,
      )
      .then(this.handleLoginSuccess)
      .catch((error) => {
        this.app.toast.showError(error)
        this.stopLoading()
      })
  }

  protected verifyPasswordlessSignup = (code: string, recaptcha_token?: string) => {
    const email = this.email || ''
    return this.app.service.auth
      .verifyPasswordlessRegister(
        email.toLowerCase(),
        code,
        this.inviteCode,
        this.referralCode,
        recaptcha_token,
      )
      .then(() => this.handleSignupSuccess({ trackGTM: !this.inviteCode }))
      .catch((error) => {
        this.app.toast.showError(error)
        this.stopLoading()
      })
  }

  loginWithCode = async (code: string, recaptcha_token?: string) => {
    if (!this.email) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.setStep(this.mode)
      return
    }

    this.loading = 'logging_in'

    if (this.mode === 'login') {
      return this.verifyPasswordlessLogin(code, recaptcha_token)
    } else {
      return this.verifyPasswordlessSignup(code, recaptcha_token)
    }
  }

  loginWithPassword = (email: string, password: string, recaptcha_token?: string) => {
    this.loading = 'logging_in'
    this.app.service.auth
      .passwordSignin(email, password, this.inviteCode, recaptcha_token)
      .then(() => {
        this.app.service.analytics.workspace.login('password')
        return this.navigateToIntent()
      })
      .catch(this.app.toast.showError)
      .finally(this.stopLoading)
  }

  showGoogleOneTap = () => {
    google.accounts.id.prompt((notification) => {
      if (notification.isDisplayed()) {
        // The One Tap modal uses an iframe which takes focus from the main window.
        // This is a hack that adds a listener for when the window blurs, just to
        // prevent the window blur and put the focus back on the Email input.
        const onBlur = (event: FocusEvent) => {
          event.preventDefault()
          window.removeEventListener('blur', onBlur)
          setTimeout(() => this.inputRef.current?.focus())
        }
        window.addEventListener('blur', onBlur)
      }
    })
  }

  hideGoogleOneTap = () => {
    google.accounts.id.cancel()
  }

  initializeGoogleOneTap = () => {
    const client_id = config.GOOGLE_CLIENT_ID
    const callback = (response: google.accounts.id.CredentialResponse) => {
      this.loading = 'logging_in'
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Fix this ESLint violation!
      this.accessWithGoogle(response.credential).finally(() => this.stopLoading())
    }
    google.accounts.id.initialize({
      client_id,
      callback,
      itp_support: true,
      context: this.mode === 'login' ? 'signin' : 'signup',
    })
  }

  handleGoogleAccountNotFound = () => {
    this.app.prompt.show({
      title: 'Account not found',
      body: `This Google account does not belong to an existing OpenPhone user. Would you like to create a new account instead?`,
      actions: [
        {
          title: 'Create a new account',
          type: 'primary',
          onClick: action(() => {
            this.toggleMode()
            this.loginWithGoogle()
          }),
        },
        {
          title: 'Try another account',
        },
      ],
    })
  }

  accessWithGoogle = (token: string) => {
    return this.mode === 'login'
      ? this.app.service.auth
          .googleSignin(token, this.inviteCode)
          .then(() => {
            this.app.service.analytics.workspace.login('google')
            return this.navigateToIntent()
          })
          .catch((error) => {
            if (error instanceof NotFoundError) {
              this.handleGoogleAccountNotFound()
            } else {
              this.app.toast.showError(error)
            }
          })
      : this.app.service.auth
          .googleRegister(token, this.inviteCode)
          .then((res) => {
            this.app.service.analytics.workspace.signup('google')
            return res
          })
          .catch((error) => this.app.toast.showError(error))
  }

  loginWithGoogle = () => {
    if (this.app.isElectron) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.app.electron?.app.openExternal?.(`${window.location.origin}/native/login`)
    } else {
      this.loading = 'authenticating_with_google'
      googleLogin()
        .then(async (auth): Promise<any> => {
          if (auth) {
            this.loading = 'logging_in'
            return this.accessWithGoogle(auth.accessToken)
            // return operation.then(() => this.app.service.contact.googleSync(auth.code))
          } else {
            return Promise.resolve()
          }
        })
        .catch((error: unknown) => {
          // ignore popup_closed error
          // which can be triggered if user closes the Google popup
          if (
            typeof error === 'object' &&
            error !== null &&
            'type' in error &&
            error.type === 'popup_closed'
          ) {
            return
          }

          if (error instanceof NotFoundError) {
            this.handleGoogleAccountNotFound()
          } else {
            this.app.toast.showError(error)
          }
        })
        .finally(this.stopLoading)
    }
  }

  resetPassword = (email: string) => {
    this.loading = 'sending_reset_password'
    this.app.service.auth
      .forgotPassword(email)
      .then(() => {
        this.app.prompt.show({
          title: 'Email sent',
          body: 'Check your email for instructions on how to reset your password.',
        })
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        this.setStep('password')
      })
      .catch(this.app.toast.showError)
      .finally(this.stopLoading)
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  stopLoading = () => {
    this.loading = 'none'
  }

  private fetchInvite = () => {
    this.inviteCode = this.app.history.query.invite
    if (!this.inviteCode) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.app.service.user
      .fetchInviteByToken(this.inviteCode)

      .then(action((invite) => (this.invite = invite)))
  }

  private fetchCoupon = () => {
    const parts = this.app.history.pathComponents
    if (parts[0] !== 'coupon') {
      return
    }
    if (!parts[1]) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.app.service.billing.fetchCoupon(parts[1])
  }

  private handleReferralCode = () => {
    if (this.app.history.query.referral_code) {
      this.referralCode = this.app.history.query.referral_code
    }
  }

  private handlePrepopulatedEmail = () => {
    if (this.app.history.query.email) {
      this.email = this.app.history.query.email
    }
  }

  private handlePrepopulatedNumber = () => {
    try {
      const queryNumber = this.app.history.query.number ?? ''

      const parsedPhoneNumber = parsePhoneNumber(queryNumber)

      if (parsedPhoneNumber?.isValid()) {
        this.number = queryNumber
      }
    } catch (error) {
      this.number = null
    }
  }

  private navigateToIntent() {
    return this.app.intent.navigateToIntent()
  }
}
