import { useCallback, useState } from 'react'

type PromiseState<T> =
  | {
      /**
       * The resolved value of the promise.
       */
      data: T | null

      /**
       * The error that caused the promise to reject.
       */
      error: null

      /**
       * The current status of the promise invocation.
       *
       * Possible values:
       * - 'idle': the promise has not yet been invoked
       * - 'loading': the promise is still being invoked
       * - 'success': the promise has been resolved, see {@link PromiseState.data}
       * - 'failed': the promise has been rejected, see {@link PromiseState.error}
       */
      status: 'idle'
    }
  | {
      data: T | null
      error: null
      status: 'loading'
    }
  | {
      data: T
      error: null
      status: 'success'
    }
  | {
      data: null
      error: Error
      status: 'failed'
    }

/**
 * Returns the state of a lazily invoked promise, and a function to invoke it.
 */
export default function useStatefulPromise<T = unknown, K extends unknown[] = unknown[]>(
  promise: (...args: [...K]) => Promise<T>,
  defaultValue: T | null = null,
): [PromiseState<T>, (...args: [...K]) => Promise<T>, () => void] {
  const [state, setState] = useState<PromiseState<T>>({
    data: defaultValue,
    status: 'idle',
    error: null,
  })

  const run = useCallback(
    (...args: [...K]) => {
      setState({
        data: null,
        status: 'loading',
        error: null,
      })
      return promise(...args)
        .then((response) => {
          setState({
            data: response,
            status: 'success',
            error: null,
          })
          return response
        })
        .catch((error) => {
          setState({
            data: null,
            status: 'failed',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
            error: error,
          })
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
          return error
        })
    },
    [promise],
  )

  const resetState = useCallback(() => {
    setState({
      data: defaultValue,
      status: 'idle',
      error: null,
    })
  }, [defaultValue])

  return [state, run, resetState]
}
