import { makeAutoObservable, observable } from 'mobx'

import { ADDON_FEATURE_IDS, ADDON_IDS } from '@src/app/billing/addon/constants'
import StatefulPromise from '@src/lib/StatefulPromise'
import { DisposeBag } from '@src/lib/dispose'
import type Service from '@src/service'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import { Addon } from '@src/service/model/Addon'
import type { AddonDataRaw } from '@src/service/model/Addon'
import { Usage } from '@src/service/model/Usage'
import type { UsageDataRaw } from '@src/service/model/Usage'
import type { AddonRepository } from '@src/service/worker/repository/AddonRepository'
import type { UsageRepository } from '@src/service/worker/repository/UsageRepository'

export default class AddonStore {
  readonly collection: PersistedCollection<Addon, AddonRepository>
  readonly usageCollection: PersistedCollection<Usage, UsageRepository>

  private readonly disposeBag = new DisposeBag()

  private fetchAddonsPromise = new StatefulPromise(this.handleFetch.bind(this))
  private fetchAddonUsagePromise = new StatefulPromise(this.handleFetchUsage.bind(this))

  constructor(private readonly root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table('addon'),
      classConstructor: (json: AddonDataRaw) => new Addon(json),
    })

    this.usageCollection = new PersistedCollection({
      table: root.storage.table('usage'),
      classConstructor: (json: UsageDataRaw) => new Usage(json),
    })

    makeAutoObservable(this, { collection: observable, usageCollection: observable })

    this.disposeBag.add(this.subscribeToWebSocket())
  }

  async fetch() {
    if (this.fetchAddonsPromise.status === 'idle') {
      const response = await this.fetchAddonsPromise.run()
      void this.collection.load(response)
    }

    if (this.fetchAddonUsagePromise.status === 'idle') {
      const response = await this.fetchAddonUsagePromise.run()
      void this.usageCollection.load(response)
    }
  }

  async toggle(addonId: string, enabled: boolean) {
    return this.root.transport.billing.toggleAddon({ addonId, enabled })
  }

  async updateAddon(addonId: string, enabled: boolean) {
    const addon = this.collection.get(addonId)
    await this.toggle(addonId, enabled)

    if (addon) {
      addon.localUpdate({ enabled })
    }
  }

  // Right now these two methods will fetch a specific addon,
  // however we plan on having a "get all" endpoint in the future
  private handleFetchUsage() {
    return this.root.transport.billing.getAddonUsage(
      ADDON_FEATURE_IDS.aiAgent.workflowVoiceAgent,
    )
  }

  private handleFetch() {
    return this.root.transport.billing.getAddon(ADDON_IDS.aiAgent)
  }

  tearDown() {
    this.collection.clear()
    this.usageCollection.clear()
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'feature-usage':
          void this.usageCollection.load(data.usage)
          break
        case 'addon-update':
          void this.collection.load(data.addon)
          break
      }
    })
  }
}
