import { makeAutoObservable, observable, toJS } from 'mobx'

import type { Model } from '@src/service/model/base'

export type SuggestedFields = {
  firstName?: string
  lastName?: string
  role?: string
  company?: string
}

export type ContactSuggestionStatus = 'pending' | 'accepted' | 'denied'

export interface RawContactSuggestion {
  id: string // random string prefixed by 'CS'
  status: ContactSuggestionStatus
  phoneNumber: string
  suggestedFields: SuggestedFields
}

export class ContactSuggestionModel implements Model {
  private raw: RawContactSuggestion

  get id(): string {
    return this.raw.id
  }

  get status(): ContactSuggestionStatus {
    return this.raw.status
  }

  get phoneNumber(): string {
    return this.raw.phoneNumber
  }

  get suggestedFields(): SuggestedFields {
    return this.raw.suggestedFields
  }

  get formattedName(): string {
    const fullName = [this.suggestedFields.firstName, this.suggestedFields.lastName]
      .join(' ')
      .trim()

    return fullName || this.suggestedFields.company || this.phoneNumber
  }

  constructor(attrs: RawContactSuggestion) {
    this.raw = { ...attrs }

    makeAutoObservable<this, 'raw'>(this, {
      raw: observable.deep,
    })
  }

  deserialize(json: RawContactSuggestion): this {
    this.raw = json
    return this
  }

  serialize(): RawContactSuggestion {
    return toJS(this.raw)
  }

  localUpdate(attrs: Partial<RawContactSuggestion>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
