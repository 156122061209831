import Debug from 'debug'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import {
  type Workbox,
  type WorkboxLifecycleEvent,
  type WorkboxLifecycleWaitingEvent,
} from 'workbox-window'

import { DisposeBag } from '@src/lib/dispose'

import type UpdateController from './UpdateController'

type ServiceWorkerUpdateState = 'idle' | 'installing' | 'waiting' | 'updating'

export default class ServiceWorkerUpdateController implements UpdateController {
  readonly id = nanoid()
  readonly debug = Debug(`op:updater:ServiceWorkerUpdateController:${this.id}`)

  protected _updateState: ServiceWorkerUpdateState = 'idle'

  protected registration: ServiceWorkerRegistration | null = null

  protected readonly disposeBag = new DisposeBag()

  constructor(readonly workbox: Workbox) {
    makeObservable<
      this,
      | '_updateState'
      | 'handleInstalledEvent'
      | 'handleInstallingEvent'
      | 'handleWaitingEvent'
      | 'handleActivatedEvent'
      | 'handleActivatingEvent'
      | 'handleControllingEvent'
    >(this, {
      _updateState: observable.ref,
      updateReady: computed,
      updateDownloading: computed,
      isUpdateAvailable: action,
      installAndRestart: action,
      handleInstallingEvent: action.bound,
      handleInstalledEvent: action.bound,
      handleWaitingEvent: action.bound,
      handleActivatingEvent: action.bound,
      handleActivatedEvent: action.bound,
      handleControllingEvent: action.bound,
    })

    this.disposeBag.add(
      // Sometimes the service worker will update but the 'controlling' event
      // isn't triggered. If a new service worker is activated, we want to reload
      // the page to ensure the new version is loaded.
      reaction(
        () => this._updateState,
        (state) => {
          if (state === 'updating') {
            window.location.reload()
          }
        },
      ),
    )

    this.workbox.addEventListener('installing', this.handleInstallingEvent)
    this.workbox.addEventListener('installed', this.handleInstalledEvent)
    this.workbox.addEventListener('waiting', this.handleWaitingEvent)
    this.workbox.addEventListener('activating', this.handleActivatingEvent)
    this.workbox.addEventListener('activated', this.handleActivatedEvent)
    this.workbox.addEventListener('controlling', this.handleControllingEvent)

    this.disposeBag.add(() => {
      this.workbox.removeEventListener('installing', this.handleInstallingEvent)
      this.workbox.removeEventListener('installed', this.handleInstalledEvent)
      this.workbox.removeEventListener('waiting', this.handleWaitingEvent)
      this.workbox.removeEventListener('activating', this.handleActivatingEvent)
      this.workbox.removeEventListener('activated', this.handleActivatedEvent)
      this.workbox.removeEventListener('controlling', this.handleControllingEvent)
    })
  }

  get updateReady() {
    return this._updateState === 'waiting'
  }

  get updateDownloading(): boolean {
    return this._updateState === 'installing'
  }

  async isUpdateAvailable(): Promise<boolean> {
    if (this.updateReady) {
      return true
    }

    await this.register()

    this.debug('checking for update...')

    return this.workbox.update().then(
      action(() => {
        const { installing, waiting } = this.registration ?? {}

        if (waiting) {
          this.debug('update available, waiting to install...')
          this._updateState = 'waiting'
          return true
        }

        if (installing) {
          this.debug('update installing...')
          this._updateState = 'installing'
          return true
        }

        this.debug('no update available')

        return false
      }),
    )
  }

  installAndRestart(): void {
    if (this.registration) {
      if (this.registration.waiting) {
        this.debug('skipping wait to install update...')
        this.workbox.messageSkipWaiting()
      } else {
        this.debug('no service worker waiting to install')
      }
    } else {
      this.debug('no service worker registration')
    }
  }

  async register(): Promise<void> {
    this.debug('registering service worker...')

    if (this.registration) {
      this.debug('service worker already registered')
    } else {
      await this.registerServiceWorker()
    }
  }

  async unregister(): Promise<void> {
    this.debug('unregistering service worker...')

    if (this.registration) {
      await this.registration.unregister()
      this.registration = null
      this.debug('service worker unregistered')
    } else {
      this.debug('no service worker to unregister')
    }
  }

  protected async registerServiceWorker() {
    this.registration = (await this.workbox.register()) ?? null

    if (this.registration) {
      this.debug('service worker registered (registration: %O)', this.registration)
    } else {
      this.debug('service worker not registered')
    }
  }

  protected handleInstallingEvent(event: WorkboxLifecycleWaitingEvent) {
    this.debug(`received 'installing' event (event: %O)`, event)
    this._updateState = 'installing'
  }

  protected handleInstalledEvent(event: WorkboxLifecycleWaitingEvent) {
    this.debug(`received 'installed' event (event: %O)`, event)
  }

  protected handleWaitingEvent(event: WorkboxLifecycleWaitingEvent) {
    this.debug(`received 'waiting' event (event: %O)`, event)
    this._updateState = 'waiting'
  }

  protected handleActivatingEvent(event: WorkboxLifecycleWaitingEvent) {
    this.debug(`received 'activating' event (event: %O)`, event)
  }

  protected handleActivatedEvent(event: WorkboxLifecycleWaitingEvent) {
    this.debug(`received 'activated' event (event: %O)`, event)
  }

  protected handleControllingEvent(event: WorkboxLifecycleEvent) {
    this.debug(`received 'controlling' event (event: %O)`, event)
    this._updateState = 'updating'
  }

  dispose() {
    this.disposeBag.dispose()
  }
}
