import type { DBTypes } from '@openphone/dexie-database/types'
import { makeAutoObservable, toJS } from 'mobx'
import type { MarkOptional } from 'ts-essentials'

import uuid from '@src/lib/uuid'
import type { Model } from '@src/service/model/base'

export type RawWorkflowDefinition = DBTypes.WorkflowDefinitionRow

export default class WorkflowDefinitionModel implements Model {
  private raw: RawWorkflowDefinition

  get id() {
    return this.raw.id
  }

  get name() {
    return this.raw.name
  }

  get description() {
    return this.raw.description
  }

  get workflowSteps() {
    return this.raw.workflowSteps
  }

  get initialWorkflowStep() {
    const firstStep = this.workflowSteps[this.raw.initialStepId]

    if (!firstStep) {
      return null
    }

    return firstStep
  }

  get triggerDefinitionId() {
    return this.raw.trigger.definitionId
  }

  get entityId() {
    return this.raw.entityId
  }

  get eventType() {
    return this.raw.eventType
  }

  get configuration() {
    return this.raw.configuration
  }

  get enabled() {
    return this.raw.enabled
  }

  get internalTags() {
    return this.raw.internalTags
  }

  get version() {
    return this.raw.version
  }

  get isIncomingCallFlow() {
    return !!this.internalTags?.includes('incoming-call-flow')
  }

  get isDefaultIncomingCallFlow() {
    return !!this.internalTags?.includes('incoming-call-flow-default')
  }

  get isForwardAllCallsIncomingCallFlow() {
    return !!this.internalTags?.includes('incoming-call-flow-forwarding')
  }

  constructor(attrs: MarkOptional<RawWorkflowDefinition, 'id'>) {
    this.raw = {
      ...attrs,
      id: attrs.id ?? `WD${uuid()}`.replace(/-/g, ''),
    }

    makeAutoObservable<this>(this)
  }

  deserialize(json: RawWorkflowDefinition): this {
    this.raw = json
    return this
  }

  serialize(): RawWorkflowDefinition {
    return toJS(this.raw)
  }
}
