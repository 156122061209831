import { observer } from 'mobx-react-lite'
import { useId } from 'react'

import { useAppStore } from '@src/app/context'
import { Link } from '@src/component/link'
import Button from '@ui/Button/Button'
import { Command, CommandFooter, CommandHeader } from '@ui/Command'
import Modal from '@ui/Modal'
import Typography from '@ui/Typography'
import { theme } from '@ui/theme'

import * as styles from './UniversalLoginReauthenticateInfoModal.css'

const updateTimeSettingsMacLink =
  'https://support.apple.com/en-ca/guide/mac-help/mchlp2996/mac'
const updateTimeSettingsWindowsLink =
  'https://support.microsoft.com/en-us/windows/set-time-date-and-time-zone-settings-in-windows-dfaa7122-479f-5b98-2a7b-fa0b6e01b261'

const UniversalLoginReauthenticateInfoModal = () => {
  const { service } = useAppStore()
  const titleId = useId()

  const reason = service.authV2.logoutReason

  if (!reason) {
    return null
  }

  const handleModalClose = () => {
    service.authV2.hideLogoutReason()
  }

  const handlePrimaryAction = () => {
    service.authV2.hideLogoutReason()
  }

  const handleSecondaryAction = (() => {
    if (reason === 'transition-to-v2') {
      return () => {
        service.authV2.hideLogoutReason()
        // TODO: UXP-3753 add navigation link
      }
    }

    return null
  })()

  const subtitle = (() => {
    if (reason === 'transition-to-v2') {
      return 'For your security you’ve been automatically logged out. Please log back in to continue using OpenPhone.'
    }

    if (reason === 'machine-clock-skew') {
      return 'It looks like your system time has not been properly synced with the world clock. To fix this, please go to your operating system settings and update your location timezone settings.'
    }

    return ''
  })()

  return (
    <Modal
      underlayColor={`rgba(${theme.palette.overlay.fullscreen})`}
      underlayClassName={styles.modal}
      className={styles.dialog}
      onClose={handleModalClose}
      isDismissable={true}
    >
      <Command width={464} aria-labelledby={titleId}>
        <CommandHeader
          title="You've been logged out"
          titleId={titleId}
          subtitle={subtitle}
        />
        {reason === 'machine-clock-skew' && (
          <div className={styles.content}>
            <Button asChild variant="ghost">
              <Link target="_blank" href={updateTimeSettingsWindowsLink}>
                Instructions for Windows
              </Link>
            </Button>
            <Button asChild variant="ghost">
              <Link target="_blank" href={updateTimeSettingsMacLink}>
                Instructions for Mac
              </Link>
            </Button>
          </div>
        )}
        <CommandFooter
          hideDivider={true}
          rightContent={
            <div className={styles.actionButtonsContainer}>
              {handleSecondaryAction && (
                <Button color="subtle" variant="solid" onClick={handleSecondaryAction}>
                  Learn more
                </Button>
              )}
              <Button onClick={handlePrimaryAction}>Got it</Button>
            </div>
          }
        />
      </Command>
    </Modal>
  )
}

export default observer(UniversalLoginReauthenticateInfoModal)
