import { useHover } from '@react-aria/interactions'
import { observer } from 'mobx-react-lite'
import { useId, useState, useRef } from 'react'

import type AudioPlayerController from '@src/component/AudioPlayer/AudioPlayerController'
import Button from '@src/component/ButtonV2'
import keyboard from '@src/lib/keyboard'
import Popover from '@ui/Popover'
import { useSensor } from '@ui/SensorProvider'
import Tooltip from '@ui/Tooltip'
import Typography from '@ui/Typography'
import VisuallyHidden from '@ui/VisuallyHidden'

import * as styles from './PlaybackSpeedControl.css'

function PlaybackSpeedControl({
  audioPlayerController,
}: {
  audioPlayerController: AudioPlayerController
}) {
  const id = useId()
  const [playbackSpeed, _setPlaybackSpeed] = useState<number | null>(
    audioPlayerController.plyr.speed ?? 1,
  )
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const { isHovered, hoverProps } = useHover({})
  const isControlOpened = audioPlayerController.openedControl === 'playbackSpeed'
  const sensor = useSensor()

  const speeds = [
    { value: 0.8, label: '0.8x' },
    { value: 1, label: '1x' },
    { value: 1.2, label: '1.2x' },
    { value: 1.5, label: '1.5x' },
    { value: 1.7, label: '1.7x' },
    { value: 2, label: '2x' },
  ]

  const setPlaybackSpeed = (speed: number) => {
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    audioPlayerController.plyr.speed = speed
    _setPlaybackSpeed(speed)
    if (sensor.type === 'pointer') {
      audioPlayerController.closeControl()
    }
  }

  return (
    <>
      <Tooltip open={isHovered} title="Playback speed" placement="top">
        {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
        <Button
          height={30}
          variant="text"
          ref={buttonRef}
          className={styles.playbackSpeedButton}
          onClick={() => audioPlayerController.openControl('playbackSpeed')}
          {...hoverProps}
        >
          <span aria-hidden>{playbackSpeed}x</span>
          <VisuallyHidden>Current playback speed is {playbackSpeed}x.</VisuallyHidden>
        </Button>
      </Tooltip>
      {isControlOpened ? (
        <Popover
          targetRef={buttonRef}
          placement="top"
          isDismissable
          onClose={() => audioPlayerController.closeControl()}
        >
          <div className={styles.playbackSpeedControl}>
            {speeds.map((speed) => (
              <label
                key={speed.value}
                className={styles.playbackSpeedControlOption({
                  selected: speed.value === playbackSpeed,
                })}
              >
                <VisuallyHidden>
                  <input
                    type="radio"
                    name={`playback-speed-${id}`}
                    value={speed.value.toString()}
                    checked={speed.value === playbackSpeed}
                    autoFocus={speed.value === playbackSpeed}
                    onChange={() => setPlaybackSpeed(speed.value)}
                    onKeyDown={keyboard.onEnter(audioPlayerController.closeControl)}
                  />
                </VisuallyHidden>
                <Typography variant="caption1">{speed.value}x</Typography>
              </label>
            ))}
          </div>
        </Popover>
      ) : null}
    </>
  )
}

export default observer(PlaybackSpeedControl)
