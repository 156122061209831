import { makeAutoObservable } from 'mobx'

import type { PhoneNumberSelection } from '@src/component/phone-number-selector/PhoneNumberSelectorController'
import { formatted, isValidNumber } from '@src/lib/phone-number'
import type Service from '@src/service'
import type {
  Identity,
  MemberModel,
  PhoneNumberGroup,
  PhoneNumberModel,
} from '@src/service/model'
import type { ContactModel } from '@src/service/model/contact/ContactModel'

import type ActiveCall from './ActiveCall'
import type { RoomParticipant } from './Room'

export default class ActiveCallParticipant {
  constructor(
    protected readonly root: Service,

    /**
     * The call to which this participant belongs.
     */
    protected readonly call: ActiveCall,

    /**
     * The raw participant data.
     *
     * This shouldn't be used in the view layer.
     */
    readonly roomParticipant: RoomParticipant,

    /**
     * The selection that was used to generate this temporary participant.
     *
     * If this is defined, then the participant was added through either
     * transferring or adding as a participant.
     */
    readonly selection?: PhoneNumberSelection,
  ) {
    makeAutoObservable(this)
  }

  /**
   * The associated identity of the participant identifier.
   *
   * The identity is looked up in the following order:
   *   1. By member ID
   *   2. By direct number
   *   3. By inbox phone number
   *   4. By contact phone number
   *   5. By integration contact phone number
   *   6. By contact suggestion
   * If not found, an `Identity` object is created.
   */
  get identity(): Identity {
    return (
      this.member ??
      this.group ??
      this.contact ??
      this.integrationContact ??
      this.suggestedContactIdentity ?? {
        id: this.roomParticipant.id,
        name: this.roomParticipant.identifier,
        shortName: '',
        initials: '',
        phones: [],
        emailAddresses: [],
        isAnonymous: false,
      }
    )
  }

  /**
   * If the participant was added to the call by phone number, this
   * return the associated phone number of the participant, based on
   * the identifier. Otherwise, returns null.
   */
  get phoneNumber(): PhoneNumberModel | null {
    return this.root.phoneNumber.byNumber[this.roomParticipant.identifier] ?? null
  }

  get isCurrentUser(): boolean {
    return this.identity.id === this.root.user.current?.asMember?.id
  }

  get id() {
    return this.roomParticipant.id
  }

  get identifier() {
    return this.roomParticipant.identifier
  }

  get userId() {
    return this.roomParticipant.userId
  }

  get name() {
    const name = this.identity.name
    return (isValidNumber(name) && formatted(name)) || name
  }

  get isMuted() {
    if (this.isCurrentUser && this.call.isMuted) {
      return true
    }

    return this.roomParticipant.isMuted
  }

  get onHold() {
    return this.roomParticipant.onHold
  }

  get isSpeaking() {
    if (this.isMuted || this.onHold) {
      return false
    }

    return this.roomParticipant.isSpeaking ?? false
  }

  get isTeamMember(): boolean {
    return !!this.member
  }

  get isExternal() {
    // FIXME: This is currently commented out since we temporarily don't support direct dialing
    //return !isDirectNumber(this.roomParticipant.identifier)
    return !this.isTeamMember && !this.isInbox
  }

  get isInbox(): boolean {
    return !this.isTeamMember && !!this.phoneNumber
  }

  get isContact(): boolean {
    return !this.member && !this.group && !!this.contact
  }

  get isSuggestedContact(): boolean {
    return (
      !this.member &&
      !this.group &&
      !this.contact &&
      !this.integrationContact &&
      !!this.suggestedContactIdentity
    )
  }

  get isListener() {
    return this.roomParticipant.isListener
  }

  get coaching() {
    return this.call.participants.list.find((p) => p.id === this.roomParticipant.coaching)
  }

  get pictureUrl() {
    return this.identity.pictureUrl
  }

  get pictureSymbol() {
    return this.identity.pictureSymbol
  }

  get status() {
    return this.roomParticipant.status === 'queued'
      ? 'ringing'
      : this.roomParticipant.status
  }

  get invitation() {
    return this.roomParticipant.invitation
  }

  get addedAt(): Date | null {
    return this.roomParticipant.addedAt ? new Date(this.roomParticipant.addedAt) : null
  }

  /**
   * If the participant has been added to the call by a forwarding, this property
   * represents the teammate or inbox that forwarded the call to the participant.
   *
   * It is set to null in case of the current user since we don't want to show this
   * information on the "self" participant.
   */
  get via(): MemberModel | PhoneNumberModel | null {
    if (this.isCurrentUser) {
      return null
    }

    const roomVia = this.roomParticipant.forwardedVia || this.roomParticipant.forwardeeVia
    if (!roomVia) {
      return null
    }

    const phoneNumber = this.root.phoneNumber.byNumber[roomVia]
    if (!phoneNumber) {
      return null
    }

    if (phoneNumber.isShared) {
      return phoneNumber
    }

    return phoneNumber.members[0] ?? null
  }

  get contact(): ContactModel | null {
    return this.root.contact.getByNumberSorted(this.roomParticipant.identifier)[0] ?? null
  }

  get suggestedContactIdentity(): Identity | null {
    const suggestedContact =
      this.root.contactSuggestion.getByNumber(this.roomParticipant.identifier) ?? null
    if (!suggestedContact) {
      return null
    }

    return {
      id: this.roomParticipant.id,
      name: suggestedContact.formattedName,
      shortName: '',
      initials: '',
      phones: [],
      emailAddresses: [],
      isAnonymous: false,
    }
  }

  /**
   * The associated Member object of the participant identifier.
   *
   * The member identity is looked up in the following order:
   *   1. By member ID
   *   2. By direct number
   */
  private get member(): MemberModel | null {
    return (
      (this.roomParticipant.userId
        ? this.root.member.collection.get(this.roomParticipant.userId)
        : null) ??
      this.root.member.collection.list.find(
        (m) => m.directNumber?.number === this.roomParticipant.identifier,
      ) ??
      null
    )
  }

  private get group(): PhoneNumberGroup | null {
    return this.phoneNumber?.group ?? null
  }

  private get integrationContact() {
    return (
      this.root.integration.people.find(
        (person) =>
          person.phoneNumbers?.includes(this.roomParticipant.identifier) ?? false,
      ) ?? null
    )
  }
}
