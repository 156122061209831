/**
 * Returns true if the value is a string that is a valid URL with a
 * protocol that is in the list of allowed protocols.
 *
 * @param value - The value to check
 * @returns True if the value is a safe contact URL, false otherwise
 */
export function isSafeContactUrl(value: unknown): value is string {
  try {
    if (typeof value !== 'string') {
      return false
    }

    const url = new URL(value)
    const allowedProtocols = ['http:', 'https:', 'mailto:', 'tel:']

    return allowedProtocols.includes(url.protocol)
  } catch {
    return false
  }
}
