import cx from 'classnames'

import DesktopDraggableArea from '@src/app/components/DesktopDraggableArea'
import { ScrollView } from '@src/component/scrollview'

import * as styles from './Page.css'

interface PageProps extends React.HTMLProps<HTMLDivElement> {
  centered?: boolean
}

const Page = ({ centered, className, children, ...props }: PageProps) => {
  const Component = centered ? 'div' : ScrollView

  return (
    <Component
      className={cx(styles.page, {
        [styles.centered]: !!centered,
      })}
    >
      <DesktopDraggableArea className={styles.desktopContainer} />
      <div className={cx(styles.container, className)} {...props}>
        <div className={styles.innerContainer}>{children}</div>
      </div>
    </Component>
  )
}

export default Page
