import type { PopoverProps as MuiPopoverProps } from '@material-ui/core/Popover'
import MuiPopover from '@material-ui/core/Popover'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import { Children, isValidElement, cloneElement, useEffect, useMemo } from 'react'

import useKeyStepper, { KeyMappings } from '@src/lib/hooks/useKeyStepper'
import unwrapFragment from '@src/lib/unwrapFragment'
import { mergeClasses } from '@src/lib/util'
import { global } from '@src/theme'

import Menu, { MenuItem } from './menu-v2'

interface PopoverMenuProps extends MuiPopoverProps {
  minWidth?: number
  maxWidth?: number
  size?: 'small' | 'medium'
  disableKeyStepper?: boolean
}

const PopoverMenu = function ({
  minWidth,
  maxWidth,
  children: childrenProp,
  size,
  disableKeyStepper = false,
  open,
  classes: classesProp,
  style,
  ...props
}: PopoverMenuProps) {
  const styles = useStyles({ minWidth, size, maxWidth })
  const classes = useMemo(() => mergeClasses(styles, classesProp), [styles, classesProp])
  const overridenStyle = { ...style, zIndex: 1450 }
  const validatedChildren = useMemo(() => {
    const children = Children.toArray(childrenProp)
    const unwrappedChildren = children.map(unwrapFragment).flat()

    return (
      unwrappedChildren.map((child, index) => {
        if (!isValidElement(child)) {
          return <></>
        }

        return cloneElement(child, { key: child.key ?? index })
      }) ?? []
    )
  }, [childrenProp])

  const { selectedIndex, getItemProps, setSelectedIndex } = useKeyStepper({
    name: 'PopoverMenu',
    items: validatedChildren,
    keys: KeyMappings.VERTICAL,
    defaultSelectedIndex: -1,
    filter: () => open && !disableKeyStepper && !!validatedChildren?.some(isMenuItem),
    skip: (element) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      element != null && (!isMenuItem(element) || element.props?.['disabled']),
    handleSelect: (element, _, event) => {
      if (!element) {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
      element.props?.['onClick']?.(event)
    },
  })

  useEffect(() => {
    if (!open) {
      setSelectedIndex(null)
    }
  }, [open, setSelectedIndex])

  const children = disableKeyStepper
    ? childrenProp
    : validatedChildren?.map((child, index) => {
        const newProps: Record<string, unknown> = {}

        if (index === selectedIndex) {
          newProps.highlighted = true
        }

        return cloneElement(child, {
          ...newProps,
          key: child.key ?? index,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          ...getItemProps(index, child.props),
        })
      })

  return (
    <MuiPopover
      open={open}
      transitionDuration={open ? 200 : 0}
      classes={classes}
      style={overridenStyle}
      {...props}
    >
      <Menu size={size}>{children}</Menu>
    </MuiPopover>
  )
}

function isMenuItem(element: JSX.Element) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  return element.type === MenuItem || element.type.actsAsMenuItem
}

export default observer(PopoverMenu)

const useStyles = makeStyles<Theme, Partial<PopoverMenuProps>>((theme) => ({
  paper: ({ minWidth, maxWidth }) => ({
    ...global.popover(theme),
    minWidth,
    maxWidth,
  }),
  root: {
    '-webkit-app-region': 'no-drag',
    'z-index': '1400 !important',
  },
}))
