import throttle from 'lodash/throttle'
import { action, computed, makeObservable, observable } from 'mobx'

interface MonitoredEvent {
  readonly event: keyof DocumentEventMap
  readonly throttleTime?: number
}

interface Handler {
  readonly onActivity: () => void
  readonly startTimeout: () => void
  readonly stopTimeout: () => void
  readonly timeoutId?: number
}

const defaultMonitoredEvents: readonly MonitoredEvent[] = [
  { event: 'click' },
  { event: 'keydown', throttleTime: 1000 },
  { event: 'mousemove', throttleTime: 1000 },
]

export type UserState = 'active' | 'idle'

interface UserIdleDetectorStartOptions {
  threshold?: number
}

/**
 * Detect when the user is idle.
 *
 * This class is modeled after the `IdleDetector` class in the Idle Detection
 * API, but different in these ways:
 *  - It works in all browsers.
 *  - `userState` is a mobx observable.
 *  - There is no `screenState` property because we can't detect it.
 *  - There is no 'change' event (use mobx).
 *  - There is no `requestPermissions()` method because there is no permission
 *    request necessary.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Idle_Detection_API
 */
export default class UserIdleDetector {
  private _userState: UserState = 'active'
  private _threshold: number | null = null
  private readonly _handlers: Map<keyof DocumentEventMap, Handler> = new Map()

  constructor() {
    makeObservable<this, '_userState'>(this, {
      _userState: observable.ref,
      userState: computed,
      start: action.bound,
      stop: action.bound,
    })
  }

  get userState(): UserState {
    return this._userState
  }

  start(options: UserIdleDetectorStartOptions = {}) {
    const { threshold = 1_800_000 } = options

    this.stop()

    if (typeof this._threshold !== 'number') {
      this._threshold = threshold

      for (const { event, throttleTime } of defaultMonitoredEvents) {
        const handler = this.createActivityHandler(event, throttleTime)
        this._handlers.set(event, handler)
        document.addEventListener(event, handler.onActivity)
        handler.startTimeout()
      }
    }
  }

  stop() {
    for (const [event, { onActivity, stopTimeout }] of this._handlers) {
      document.removeEventListener(event, onActivity)
      stopTimeout()
    }

    this._threshold = null
  }

  private createActivityHandler(
    event: keyof DocumentEventMap,
    throttleTime?: number,
  ): Handler {
    const stopTimeout = () => {
      const handler = this._handlers.get(event)

      if (typeof handler?.timeoutId === 'number') {
        window.clearTimeout(handler.timeoutId)
      }
    }

    const startTimeout = () => {
      const handler = this._handlers.get(event)

      if (handler && typeof this._threshold === 'number') {
        const timeoutId = window.setTimeout(
          action(() => {
            this._userState = 'idle'
          }),
          this._threshold,
        )

        this._handlers.set(event, { ...handler, timeoutId })
      }
    }

    const handler = action(() => {
      this._userState = 'active'
      stopTimeout()
      startTimeout()
    })

    return {
      onActivity: throttleTime ? throttle(handler, throttleTime) : handler,
      startTimeout,
      stopTimeout,
    }
  }
}
