import type { Client } from '@openphone/internal-api-client'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import createRelaySentryLogFunction from './createRelaySentryLogFunction'
import relayFieldLogger from './relayFieldLogger'

interface CreateRelayEnvironmentParams {
  httpClient: Client
}

export function createRelayEnvironment({ httpClient }: CreateRelayEnvironmentParams) {
  const network = Network.create((operation, variables) => {
    return httpClient.post(new URL('/api/graphql', window.location.origin).toString(), {
      query: operation.text,
      variables,
    })
  })

  const environment = new Environment({
    network,
    store: new Store(new RecordSource()),
    relayFieldLogger,
    log: createRelaySentryLogFunction(),
  })

  return environment
}
