import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import type { HTMLProps } from 'react'
import { useEffect, useRef } from 'react'

import AudioControls from '@src/component/AudioPlayer/controls/AudioControls'
import useAsyncState from '@src/lib/hooks/useAsyncState'
import useInstance from '@src/lib/hooks/useInstance'
import Bone from '@ui/Bone'

import * as styles from './AudioPlayer.css'
import type { AudioMedia } from './AudioPlayerController'
import AudioPlayerController from './AudioPlayerController'

export interface AudioPlayerProps extends Omit<HTMLProps<HTMLDivElement>, 'media'> {
  media: AudioMedia
  handleDelete?(): void
  onMenuOpened?(): void
  onMenuClosed?(): void
  onPlay?(): void
  onPause?(): void
  onDownload?(): void
  showOnlyDownloadAction?: boolean
  background?: 'purple' | 'gray'
}

const AudioPlayer = function ({
  media,
  className,
  onMenuOpened,
  onMenuClosed,
  onPlay,
  onPause,
  onDownload,
  handleDelete,
  showOnlyDownloadAction = true,
  background,
  ...props
}: AudioPlayerProps) {
  const audioRef = useRef<HTMLAudioElement>(null)

  const { value: plyrModule, isLoading } = useAsyncState({
    fetcher: async () => await import('plyr'),
  })

  const audioPlayerController = useInstance(() => {
    if (!audioRef.current || !plyrModule) {
      return
    }

    return new AudioPlayerController(plyrModule.default, audioRef.current, {
      onMenuOpened,
      onMenuClosed,
      onPlay,
      onPause,
      onDownload,
    })
  }, [plyrModule])

  useEffect(() => {
    if (
      audioPlayerController?.media?.name === media.name &&
      audioPlayerController?.media?.type === media.type &&
      audioPlayerController?.media?.url === media.url
    ) {
      // Avoid re-rendering the audio player if the media object is different but represents the same data.
      // This can happen when saving in the settings since we pass in an ad-hoc media object.
      return
    }

    audioPlayerController?.setSource(media)
  }, [audioPlayerController, media])

  useEffect(() => {
    return () => {
      audioPlayerController?.tearDown()
    }
  }, [audioPlayerController])

  if (!media.url) {
    return null
  }

  const isLoaded = !isLoading && !!audioPlayerController

  return (
    <div
      {...props}
      className={isLoaded ? cx(styles.wrapper({ background }), className) : undefined}
    >
      <div className={styles.audio({ hidden: !isLoaded })}>
        <audio ref={audioRef} controls tabIndex={-1}>
          <source src={media.url} type={media.type ?? undefined} />
          Your browser does not support the audio element.
        </audio>
      </div>
      {isLoaded ? (
        <AudioControls
          audioPlayerController={audioPlayerController}
          showOnlyDownloadAction={showOnlyDownloadAction}
          handleDelete={handleDelete}
        />
      ) : (
        <Bone className={cx(styles.bone, className)} />
      )}
    </div>
  )
}

export default observer(AudioPlayer)
