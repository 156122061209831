import type { AuthenticatedConnectUser, PortalOpenEvent } from '@useparagon/connect'
import { paragon, SDK_EVENT } from '@useparagon/connect'
import { useCallback, useEffect, useState } from 'react'

import config from '@src/config'
import { logError } from '@src/lib/log'
import { useService } from '@src/service/context'

import type { ParagonUserSettings } from './ParagonContext'

interface ParagonAuthenticationState {
  isLoading: boolean
  token: string | null
  user: AuthenticatedConnectUser | null
  expiresAt: string
  error?: unknown
}

export function useParagonAuthentication(isElectron: boolean) {
  const service = useService()
  const [state, setState] = useState<ParagonAuthenticationState>({
    isLoading: true,
    token: null,
    user: null,
    expiresAt: '1970-01-01T00:00:00Z',
  })

  const getParagonUserAndToken = useCallback(
    async (signal?: AbortSignal) => {
      const tokenResponse = await service.transport.integration.paragon.token()

      if (signal?.aborted) {
        return { tokenResponse: null, user: null }
      }

      await paragon.authenticate(config.PARAGON_PROJECT_ID, tokenResponse.token, {})

      if (signal?.aborted) {
        return { tokenResponse: null, user: null }
      }

      const user = paragon.getUser() as AuthenticatedConnectUser

      return { tokenResponse, user }
    },
    [service.transport.integration.paragon],
  )

  const refreshAuth = useCallback(
    async (signal: AbortSignal) => {
      try {
        setState((current) => ({ ...current, isLoading: true, error: undefined }))

        const { tokenResponse, user } = await getParagonUserAndToken(signal)

        if (tokenResponse && user) {
          setState({
            isLoading: false,
            token: tokenResponse.token,
            user,
            expiresAt: tokenResponse.expiresAt,
          })
        }
      } catch (e) {
        if (signal.aborted) {
          return
        }

        logError(e)
        setState((current) => ({ ...current, isLoading: false, error: e }))
      }
    },
    [getParagonUserAndToken],
  )

  const resync = useCallback(
    async (integrationType: string) => {
      service.integration.deleteLocalByName(integrationType)
      service.transport.integration.paragon.sync().catch(logError)
    },
    [service.integration, service.transport.integration.paragon],
  )

  useEffect(() => {
    const refreshAt = new Date(state.expiresAt).getTime() - 1000 * 60 * 5 // 5 minutes before expiration
    const now = new Date().getTime()
    const timeToRefresh = Math.max(refreshAt - now, 0)
    const abortController = new AbortController()

    let timeout: NodeJS.Timeout | null = null

    if (timeToRefresh <= 0) {
      void refreshAuth(abortController.signal)
    } else {
      timeout = setTimeout(() => {
        void refreshAuth(abortController.signal)
      }, timeToRefresh)
    }

    return () => {
      abortController.abort()

      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [state.expiresAt, refreshAuth])

  useEffect(() => {
    const onIntegrationInstallUnsub = paragon.subscribe(
      SDK_EVENT.ON_INTEGRATION_INSTALL,
      () => {
        setState((current) => ({
          ...current,
          user: paragon.getUser() as AuthenticatedConnectUser,
        }))

        void service.transport.integration.paragon.sync().catch(logError)
      },
    )

    const onIntegrationUninstallUnsub = paragon.subscribe(
      SDK_EVENT.ON_INTEGRATION_UNINSTALL,
      () => {
        setState((current) => ({
          ...current,
          user: paragon.getUser() as AuthenticatedConnectUser,
        }))

        void service.transport.integration.paragon.sync().catch(logError)

        // this's a workaround to be able to listen for portal open event
        // which can trigger polling for user info for the desktop app
        if (isElectron) {
          paragon.closePortal()
        }
      },
    )

    let intervalId: NodeJS.Timeout | null = null
    let timeoutId: NodeJS.Timeout | null = null

    // used to poll for user info in the desktop app
    // since paragon won't notify us about a completed installation
    // we need to poll for user info to detect if a user installed a new integration
    const pollUserInfo = (event: PortalOpenEvent) => {
      const intervalId = setInterval(() => {
        getParagonUserAndToken()
          .then(({ user: newUser, tokenResponse }) => {
            if (
              newUser &&
              newUser.integrations[event.integrationType]?.enabled === true
            ) {
              setState({
                isLoading: false,
                token: tokenResponse?.token,
                user: newUser,
                expiresAt: tokenResponse?.expiresAt,
              })

              void resync(event.integrationType)

              resetPolling()
            }
          })
          .catch(logError)

        // 5sec
      }, 5000)

      return intervalId
    }

    const resetPolling = () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }

    // listen for portal open event
    // we need to use it to detect if a user initiated a new integration flow in the desktop app
    const onPortalOpenUnsub = paragon.subscribe(
      SDK_EVENT.ON_PORTAL_OPEN,
      (event: PortalOpenEvent, user: AuthenticatedConnectUser) => {
        if (isElectron && user.integrations[event.integrationType]?.enabled === false) {
          intervalId = pollUserInfo(event)

          timeoutId = setTimeout(() => {
            resetPolling()
            // 7min
          }, 420000)
        }
      },
    )

    const onWorkflowChangeUnsub = paragon.subscribe(SDK_EVENT.ON_WORKFLOW_CHANGE, () => {
      void service.transport.integration.paragon.sync().catch(logError)
    })

    return () => {
      onIntegrationInstallUnsub()
      onIntegrationUninstallUnsub()
      onPortalOpenUnsub()
      resetPolling()
      onWorkflowChangeUnsub()
    }
  }, [getParagonUserAndToken, isElectron, resync, service])

  const setUserSettings = useCallback(
    async (settings: ParagonUserSettings) => {
      const user = await paragon.setUserMetadata(settings)

      setState((current) => ({
        ...current,
        user,
      }))

      void service.transport.integration.paragon.sync().catch(logError)
    },
    [service],
  )

  return {
    ...state,
    setUserSettings,
    resync,
  }
}
