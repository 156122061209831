import { computed, makeObservable, observable, action, toJS } from 'mobx'

import type { Model } from '.'

export type Feature = {
  name: string
  usageIncluded?: number
}

export interface AddonDataRaw {
  id: string
  orgId: string
  name: string
  enabled: boolean
  features: Feature[]
}

export class Addon implements Model {
  private raw: AddonDataRaw

  get id(): string {
    return this.raw.id
  }

  get orgId(): string {
    return this.raw.orgId
  }

  get enabled(): boolean {
    return this.raw.enabled
  }

  get features(): Feature[] {
    return this.raw.features
  }

  constructor(attrs: AddonDataRaw) {
    this.raw = attrs

    makeObservable<this, 'raw' | 'localUpdate'>(this, {
      raw: observable.deep,
      id: computed,
      orgId: computed,
      enabled: computed,
      features: computed,
      localUpdate: action.bound,
    })
  }

  localUpdate(partial: Partial<AddonDataRaw>): this {
    this.raw = { ...this.raw, ...partial }
    return this
  }

  deserialize(json: AddonDataRaw): this {
    this.raw = json
    return this
  }

  serialize(): AddonDataRaw {
    return toJS(this.raw)
  }
}
