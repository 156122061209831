/// <reference types="@openphone/desktop-client" />

import { createConfig } from '@openphone/web-config'

import uuid from '@src/lib/uuid'

const config = createConfig(import.meta.env)

export const platform = typeof window !== 'undefined' ? window._e?.platform : undefined
export const isWeb = !platform
export const isMac = platform === 'darwin'
export const isWindows = platform === 'win32'
export const isProd = config.ENV === 'production'
export const areFeatureTooltipsDisabled =
  typeof window !== 'undefined'
    ? !!window.localStorage.getItem('DISABLE_FEATURE_TOOLTIPS')
    : false

export const isRecaptchaDisabled =
  typeof window !== 'undefined'
    ? window.localStorage.getItem('DISABLE_RECAPTCHA') === 'true'
    : false

export const isCommandAiDisabled =
  typeof window !== 'undefined'
    ? window.localStorage.getItem('DISABLE_COMMAND_AI') === 'true'
    : false

export const deviceId = (() => {
  if (typeof window === 'undefined') {
    return `webworker-${uuid()}`
  }

  let id = window.localStorage.getItem('deviceId')

  if (id) {
    return id
  }

  id = uuid()
  window.localStorage.setItem('deviceId', id)
  return id
})()

export default config
