import type { Cacheable, ICache } from '@auth0/auth0-spa-js'

import type Service from '.'

class UniversalLoginCacheLocation implements ICache {
  constructor(private service: Service) {}

  get<T = Cacheable>(key: string): Promise<T | undefined> {
    return this.service.storage.async().get(key)
  }

  set<T = Cacheable>(key: string, value: T) {
    return this.service.storage.async().set<T>(value, key)
  }

  remove(key: string) {
    return this.service.storage.async().delete(key)
  }

  allKeys(): Promise<string[]> {
    return (
      this.service.storage.database
        .table('kv')
        .toCollection()
        .keys()
        // eslint-disable-next-line @typescript-eslint/no-base-to-string -- FIXME: Fix this ESLint violation!
        .then((keys) => keys.map((key) => key.toString()))
    )
  }
}

export default UniversalLoginCacheLocation
