import { assignInlineVars } from '@vanilla-extract/dynamic'
import { clamp } from 'lodash/fp'
import { observer } from 'mobx-react-lite'
import type { FocusEvent, ChangeEvent } from 'react'
import { useState, useRef } from 'react'

import type AudioPlayerController from '@src/component/AudioPlayer/AudioPlayerController'
import Button from '@src/component/ButtonV2'
import Popover from '@ui/Popover'
import { VolumeFullIcon, VolumeMuteIcon } from '@ui/icons/tint/20/general'

import * as styles from './VolumeControl.css'

function VolumeControl({
  audioPlayerController,
}: {
  audioPlayerController: AudioPlayerController
}) {
  const [internalVolume, setInternalVolume] = useState(
    audioPlayerController.plyr.volume ?? 0,
  )
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const popoverRef = useRef<HTMLDivElement | null>(null)
  const openTimeoutRef = useRef<number | undefined>()
  const closeTimeoutRef = useRef<number | undefined>()
  const isControlOpened = audioPlayerController.openedControl === 'volume'

  const setVolume = (volume: number) => {
    const clamped = clamp(0, 1, volume)
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    audioPlayerController.plyr.volume = clamped
    setInternalVolume(clamped)
  }

  const onClick = () => setVolume(internalVolume > 0 ? 0 : 1)

  const onButtonEnter = () => {
    openTimeoutRef.current = window.setTimeout(() => {
      if (audioPlayerController.openedControl !== 'volume') {
        audioPlayerController.openControl('volume')
      }
    }, 200)
  }

  const onButtonLeave = () => {
    if (openTimeoutRef.current) {
      clearTimeout(openTimeoutRef.current)
    }

    closeTimeoutRef.current = window.setTimeout(() => {
      if (audioPlayerController.openedControl === 'volume') {
        audioPlayerController.closeControl()
      }
    }, 200)
  }

  const onButtonFocus = () => {
    audioPlayerController.openControl('volume')
  }

  const onButtonBlur = (event: FocusEvent<HTMLButtonElement>) => {
    if (!popoverRef.current?.contains(event.relatedTarget as Node)) {
      audioPlayerController.closeControl()
    }
  }

  const onPopoverEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
    }
  }

  const handleVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber
    setVolume(value)
  }

  return (
    <>
      {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
      <Button
        height={30}
        variant="text"
        className={styles.volumeControlButton}
        onClick={onClick}
        onMouseEnter={onButtonEnter}
        onMouseLeave={onButtonLeave}
        onFocus={onButtonFocus}
        onBlur={onButtonBlur}
        ref={buttonRef}
      >
        {internalVolume === 0 ? <VolumeMuteIcon /> : <VolumeFullIcon />}
      </Button>
      {isControlOpened ? (
        <Popover
          targetRef={buttonRef}
          ref={popoverRef}
          placement="top"
          autoFocus
          isDismissable
          withUnderlay={false}
          shouldCloseOnBlur
          shouldCloseOnInteractOutside={(element) => {
            if (!buttonRef.current) {
              return true
            }

            return !buttonRef.current.contains(element)
          }}
          onClose={() => audioPlayerController.closeControl()}
        >
          <div className={styles.volumeControl} onMouseEnter={onPopoverEnter}>
            {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
            <Button
              height={30}
              variant="text"
              className={styles.volumeControlButton}
              onClick={() => setVolume(0)}
              aria-label="Mute"
            >
              <VolumeMuteIcon />
            </Button>
            <input
              type="range"
              min={0}
              max={1}
              onChange={handleVolumeChange}
              className={styles.volumeControlRangeInput}
              step="any"
              value={internalVolume}
              onKeyDown={(e) => e.stopPropagation()}
              style={assignInlineVars({
                [styles.volumeControlRangeInputValueVar]: `${internalVolume}`,
              })}
            />
            {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
            <Button
              height={30}
              variant="text"
              className={styles.volumeControlButton}
              onClick={() => setVolume(1)}
              aria-label="Set maximum volume"
            >
              <VolumeFullIcon />
            </Button>
          </div>
        </Popover>
      ) : null}
    </>
  )
}

export default observer(VolumeControl)
