import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import kebabCase from 'lodash/fp/kebabCase'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'

import { useTheme } from '@ui/theme'

import * as styles from './DeepLink.css'

interface DeepLinkProps {
  id?: string
  /**
   * Makes id from the text content of children if `id` is not provided
   */
  guess?: boolean
  /**
   * Callback called when the deeplink matches
   */
  onMatch?: () => void
  scrollIntoViewOptions?: ScrollIntoViewOptions
  children: ReactNode
  className?: string
  disabled?: boolean
}

function DeepLink({
  id,
  guess = true,
  onMatch,
  scrollIntoViewOptions,
  children,
  className,
  disabled = false,
}: DeepLinkProps) {
  const theme = useTheme()
  const { anchorRef } = useScrolltoAnchor({
    id,
    guess,
    disabled,
    onMatch,
    scrollIntoViewOptions,
  })

  if (disabled) {
    return <>{children}</>
  }

  return (
    <div
      style={assignInlineVars({
        [styles.highlightOpacity]: theme.match({
          dark: '0.14',
          light: '0.2',
        }),
      })}
      ref={anchorRef}
      id={id}
      className={cx(styles.root, className)}
    >
      {children}
    </div>
  )
}

export default DeepLink

function useScrolltoAnchor({
  id,
  guess,
  disabled = false,
  onMatch,
  scrollIntoViewOptions,
}: {
  id?: string
  guess?: boolean
  disabled?: boolean
  onMatch?: () => void
  scrollIntoViewOptions?: ScrollIntoViewOptions
}) {
  const anchorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!anchorRef.current || disabled) {
      return
    }

    const element = anchorRef.current

    let idToCheck = id

    if (!id && guess) {
      // we could truncate the text in case this component
      // is misused and children render a lot of text
      const text = element.textContent
      if (!text) {
        return
      }
      idToCheck = kebabCase(text)
      element.id = idToCheck
    } else if (!id && !guess) {
      return
    }

    function scrollToAnchor() {
      const hash = window.location.hash.slice(1)

      if (!hash || hash !== idToCheck || !element) {
        return
      }

      if (onMatch) {
        onMatch()
      }

      // since the `onMatch` callback might change the content, let's wait an extra frame
      // before scrolling
      requestAnimationFrame(() => {
        element.scrollIntoView(scrollIntoViewOptions)
      })

      const handleAnimationEnd = (event: AnimationEvent) => {
        if (event.target === element) {
          element.classList.remove('scroll-target-active')
        }
        element.removeEventListener('animationend', handleAnimationEnd)
      }

      element.addEventListener('animationend', handleAnimationEnd)

      element.classList.add('scroll-target-active')
    }

    // some content might render a bit later and increase
    // scroll height on the document, waiting for next frame
    // helps a bit to mitigate that problem
    requestAnimationFrame(scrollToAnchor)

    window.addEventListener('popstate', scrollToAnchor)

    return () => {
      window.removeEventListener('popstate', scrollToAnchor)
    }
  }, [id, guess, disabled, onMatch, scrollIntoViewOptions])

  return { anchorRef }
}
