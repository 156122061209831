import type { ContactItemType } from '@src/service/model/contact/ContactItemModel'

export const placeholders: Partial<Record<ContactItemType, string>> = {
  address: 'e.g. 1 Market St, SF, CA',
  role: 'e.g. Sales',
  email: 'example@openphone.co',
  string: 'Enter a value',
  url: 'e.g. https://openphone.co',
  number: 'e.g. 123.45',
}
