import { observer } from 'mobx-react-lite'
import type { Key } from 'react'

import * as styles from '@src/component/AudioPlayer/AudioPlayer.css'
import type AudioPlayerController from '@src/component/AudioPlayer/AudioPlayerController'
import Button from '@src/component/ButtonV2'
import IconButton from '@src/component/IconButton'
import isNonNull from '@src/lib/isNonNull'
import Menu from '@ui/Menu'
import { DeleteIcon, DownloadIcon, MoreIcon } from '@ui/icons/tint/20/general'
import { HStack } from '@ui/layout/Stack'

import PlaybackSpeedControl from './PlaybackSpeedControl'
import VolumeControl from './VolumeControl'

interface AudioControlsProps {
  audioPlayerController: AudioPlayerController
  showOnlyDownloadAction: boolean
  handleDelete?(): void
}

const AudioControls = ({
  audioPlayerController,
  showOnlyDownloadAction,
  handleDelete,
}: AudioControlsProps) => {
  const menuItems = showOnlyDownloadAction
    ? []
    : [
        { key: 'download', icon: <DownloadIcon />, textValue: 'Download' },
        handleDelete
          ? { key: 'delete', icon: <DeleteIcon />, textValue: 'Delete' }
          : null,
      ].filter(isNonNull)

  const handleMenuAction = (key: Key) => {
    if (key === 'download') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      audioPlayerController?.download()
    } else if (key === 'delete' && handleDelete) {
      handleDelete()
    }
  }

  return (
    <HStack gap={2}>
      <VolumeControl audioPlayerController={audioPlayerController} />
      <PlaybackSpeedControl audioPlayerController={audioPlayerController} />
      {showOnlyDownloadAction ? (
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          title="Download"
          tooltip={{ placement: 'top' }}
          icon={<DownloadIcon />}
          onClick={audioPlayerController.download}
          className={styles.baseButton}
        />
      ) : (
        <Menu.Provider
          isOpen={audioPlayerController.openedControl === 'settings'}
          onOpen={() => audioPlayerController.openControl('settings')}
          onClose={() => audioPlayerController.closeControl()}
        >
          <Menu.Trigger>
            <Button height={30} className={styles.baseButton} variant="text">
              <MoreIcon />
            </Button>
          </Menu.Trigger>
          <Menu.List
            items={menuItems}
            onAction={handleMenuAction}
            placement="bottom right"
          >
            {({ key, icon, textValue }) => (
              <Menu.ListItem key={key} icon={icon} textValue={textValue}>
                {textValue}
              </Menu.ListItem>
            )}
          </Menu.List>
        </Menu.Provider>
      )}
    </HStack>
  )
}

export default observer(AudioControls)
