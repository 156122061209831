import config from '@src/config'

const PARAGON_BASE_URL = `https://zeus.integrations.openphone.com/projects/${config.PARAGON_PROJECT_ID}/sdk/`

interface IntegrationOption {
  id: string
  name: string
  projectId: string
}

type IntegrationsList = IntegrationOption[]

/**
 *  This function sends an HTTP request to the Paragon credentials endpoint
 *  with the config needed to complete authentication for the specified integration.
 *
 *  This utility is needed in order to complete an integration install from the desktop app
 *  It is a workaround for a known bug related to the completeInstall method in he Paragon SDK.
 *
 */

export async function sendParagonCredentials<T>(
  token: string,
  authorizationCode: string,
  integrationId: string,
): Promise<T> {
  const response = await fetch(`${PARAGON_BASE_URL}credentials`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      code: authorizationCode,
      integrationId,
      integrationOptions: {},
    }),
  })

  if (!response.ok) {
    throw new Error(
      `sendParagonCredentials error: ${response.status} ${response.statusText}`,
    )
  }

  return (await response.json()) as T
}

/**
 *  This function sends an HTTP request to the Paragon integrations endpoint.
 *  It returns the response as a JSON object with all existing integrations.
 *
 *  Currently the Paragon SDK only exposes the list of authenticated integrations.
 *
 */
export async function getParagonIntegrations(token: string): Promise<IntegrationsList> {
  const response = await fetch(`${PARAGON_BASE_URL}integrations`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })

  if (!response.ok) {
    throw new Error(
      `getParagonIntegrations error: ${response.status} ${response.statusText}`,
    )
  }

  return (await response.json()) as IntegrationsList
}
