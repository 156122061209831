import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router-dom'

import { useAppStore } from '@src/app/context'
import Login from '@src/app/login'
import { UniversalLoginRoute } from '@src/app/universal-login'

const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const app = useAppStore()
  const isUniversalLoginEnabled = app.service.authV2.isEnabled

  if (!app.isLoggedIn) {
    return isUniversalLoginEnabled ? <UniversalLoginRoute /> : <Login />
  }

  if (!app.initialized || !app.storesLoaded) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error -- FIXME: Fix this ESLint violation!
    throw app.initializePromise
  }

  if (app.isAccountFlagged) {
    return <Navigate to="/verification/flagged" />
  }

  return <>{children}</>
}

export default observer(AuthGuard)
