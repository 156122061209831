import type Dexie from 'dexie'

import Deferred from '@src/lib/Deferred'
import log from '@src/lib/log'

export type DatabaseTable<R, T> = Dexie.Table<R, T>

export default class AsyncStorage {
  private connection: Deferred<void> | null = null

  constructor(
    protected table: DatabaseTable<any, string>,
    private classConstructor?: (data: any) => any,
  ) {}

  get<T>(id: string): Promise<T> {
    return this.ensureConnection()
      .then(() => this.table.get(id))
      .then((data) => {
        if (!data) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
          return data
        }
        if (Array.isArray(data)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
          return data.map((d) => (this.classConstructor ? this.classConstructor(d) : d))
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        return this.classConstructor ? this.classConstructor(data) : data
      })
  }

  set<T>(item: T, key: string): Promise<any> {
    return this.ensureConnection().then(() => this.table.put(item, key))
  }

  delete(id: string) {
    return this.ensureConnection().then(() => this.table.delete(id))
  }

  private async ensureConnection(): Promise<void> {
    if (this.connection && this.connection.state !== 'fulfilled') {
      await this.connection.promise
    }

    const db = this.table.db
    if (!db.isOpen()) {
      if (db.hasBeenClosed()) {
        log.warn('Database connection closed, reopening...')
      }

      this.connection = new Deferred()
      await db.open()
      this.connection.resolve()
    }
  }
}
