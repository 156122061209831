import { type FlagValue, type FlagKey } from '@src/service/feature-flags'

import useFlags from './useFlags'

export default function useFlag<Key extends FlagKey>(flag: Key): FlagValue<Key> {
  const context = useFlags()

  if (!context) {
    throw new Error('useFlag must be used within a FlagsContext')
  }

  return context.flags[flag]
}
