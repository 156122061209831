import { makeAutoObservable, observable, toJS } from 'mobx'

import { getInitials, getSingleFirstAndLastName } from '@src/lib'
import isTruthy from '@src/lib/isTruthy'
import objectId from '@src/lib/objectId'
import { formatted, toE164 } from '@src/lib/phone-number'
import type { Identity, IdentityPhone, Model } from '@src/service/model/base'

export interface RawIntegrationContact {
  id: string
  identifiers: string[] | null
  orgId: string | null
  firstName: string | null
  lastName: string | null
  company: string | null
  role: string | null
  phoneNumbers: string[] | null
  emails: string[] | null
  externalId: string | null
  url: string | null
  data?: ExtraData | null
  enrichment?: EnrichedData | null
  source?: 'salesforce' | 'hubspot'
}

interface SalesforceData {
  Company?: string
  Title?: string
  MobilePhone?: string | null
  HomePhone?: string | null
  OtherPhone?: string | null
  AssistantPhone?: string | null
  ownerName?: string
  accountName?: string
  Account?: { Name: string }
  attributes?: { type?: 'Contact' | 'Lead' }
}

interface HubSpotData {
  company?: string
  jobtitle?: string
  mobilephone?: string
}

interface HubSpotEnrichment {
  associatedcompanyid?: { name?: string }
  hubspot_owner_id?: {
    firstName?: string
    lastName?: string
  }
}

type ExtraData = SalesforceData | HubSpotData

type EnrichedData = HubSpotEnrichment

export type IntegrationContactType = 'contact' | 'lead'

export class IntegrationContactModel implements Identity, Model {
  private raw: RawIntegrationContact

  constructor() {
    this.raw = {
      id: objectId(),
      identifiers: null,
      orgId: null,
      firstName: null,
      lastName: null,
      company: null,
      role: null,
      phoneNumbers: null,
      emails: null,
      externalId: null,
      url: null,
      data: null,
      enrichment: undefined,
      source: undefined,
    }

    makeAutoObservable<this, 'raw'>(this, {
      raw: observable.deep,
    })
  }

  get id(): string {
    return this.raw.id
  }

  get identifiers(): string[] | null {
    return this.raw.identifiers
  }

  get orgId(): string | null {
    return this.raw.orgId
  }

  get firstName(): string | null {
    return this.raw.firstName
  }

  get lastName(): string | null {
    return this.raw.lastName
  }

  get company(): string | null {
    return this.raw.company
  }

  get role(): string | null {
    return this.raw.role
  }

  get phoneNumbers(): string[] | null {
    return this.raw.phoneNumbers
  }

  get emails(): string[] | null {
    return this.raw.emails
  }

  get externalId(): string | null {
    return this.raw.externalId ?? null
  }

  get source(): string | undefined {
    return this.raw.source ?? undefined
  }

  get url(): string | null {
    return this.raw.url ?? null
  }

  get data(): ExtraData | null {
    return this.raw.data ?? null
  }

  get enrichment(): EnrichedData | undefined {
    return this.raw.enrichment ?? undefined
  }

  get pictureUrl(): string | null {
    return null
  }

  get name(): string {
    if (!this.raw.firstName && !this.raw.lastName) {
      if (this.raw.company) {
        return this.raw.company
      }
      if (this.raw.phoneNumbers && this.raw.phoneNumbers.length > 0) {
        return formatted(this.raw.phoneNumbers[0] ?? '')
      }
      if (this.raw.emails && this.raw.emails.length > 0) {
        return this.raw.emails[0] ?? ''
      }
      return 'Unnamed'
    }
    return [this.raw.firstName, this.raw.lastName].filter(isTruthy).join(' ')
  }

  get shortName(): string {
    return [this.raw.firstName, this.raw.lastName].find(isTruthy) ?? ''
  }

  get initials(): string {
    if (this.raw.firstName || this.raw.lastName) {
      const singleFirstNameAndLastName = getSingleFirstAndLastName(
        this.raw.firstName,
        this.raw.lastName,
      )
      return getInitials(singleFirstNameAndLastName)
    }

    if (this.raw.company) {
      return getInitials(this.raw.company)
    }

    return ''
  }

  get phones(): IdentityPhone[] {
    return (
      this.raw.phoneNumbers?.map((number) => ({
        id: null,
        name: null,
        symbol: null,
        number,
        isOffHours: null,
      })) ?? []
    )
  }

  get emailAddresses(): string[] {
    return this.raw.emails ?? []
  }

  get isAnonymous() {
    return false
  }

  get mobilePhone(): string | null {
    if (this.raw.source && isHubSpotData(this.raw.data, this.raw.source)) {
      return this.raw.data.mobilephone ? toE164(this.raw.data.mobilephone) : null
    } else if (this.raw.source && isSalesforceData(this.raw.data, this.raw.source)) {
      return this.raw.data.MobilePhone ? toE164(this.raw.data.MobilePhone) : null
    }
    return null
  }

  get homePhone(): string | null {
    if (this.raw.source && isSalesforceData(this.raw.data, this.raw.source)) {
      return this.raw.data.HomePhone ? toE164(this.raw.data.HomePhone) : null
    }
    return null
  }

  get otherPhone(): string | null {
    if (this.raw.source && isSalesforceData(this.raw.data, this.raw.source)) {
      return this.raw.data.OtherPhone ? toE164(this.raw.data.OtherPhone) : null
    }
    return null
  }

  get assistantPhone(): string | null {
    if (this.raw.source && isSalesforceData(this.raw.data, this.raw.source)) {
      return this.raw.data.AssistantPhone ? toE164(this.raw.data.AssistantPhone) : null
    }
    return null
  }

  get jobTitle(): string | null {
    if (this.raw.source && isHubSpotData(this.raw.data, this.raw.source)) {
      return this.raw.data.jobtitle ?? null
    } else if (this.raw.source && isSalesforceData(this.raw.data, this.raw.source)) {
      return this.raw.data.Title ?? null
    }
    return null
  }

  get companyName(): string | null {
    if (this.raw.source && isHubSpotData(this.raw.data, this.raw.source)) {
      return this.raw.data.company ?? null
    } else if (this.raw.source && isSalesforceData(this.raw.data, this.raw.source)) {
      return (
        this.raw.data.Company ??
        this.raw.data.accountName ??
        this.raw.data.Account?.Name ??
        null
      )
    }
    return null
  }

  get associatedCompany(): string | null {
    if (this.raw.source && isEnrichedHubSpotData(this.raw.enrichment, this.raw.source)) {
      return this.raw.enrichment.associatedcompanyid?.name ?? null
    }
    return null
  }

  get contactOwner(): string | null {
    if (this.raw.source && isEnrichedHubSpotData(this.raw.enrichment, this.raw.source)) {
      const name = [
        this.raw.enrichment.hubspot_owner_id?.firstName,
        this.raw.enrichment.hubspot_owner_id?.lastName,
      ]
        .filter(isTruthy)
        .join(' ')
      return name ? name : null
    } else if (this.raw.source && isSalesforceData(this.raw.data, this.raw.source)) {
      return this.raw.data.ownerName ?? null
    }
    return null
  }

  get contactType(): IntegrationContactType {
    if (
      this.raw.source &&
      isSalesforceData(this.raw.data, this.raw.source) &&
      this.raw.data.attributes?.type === 'Lead'
    ) {
      return 'lead'
    }
    return 'contact'
  }

  get sourceIconUrl() {
    // Legacy map of sources to icon URLs, these will go away once we remove IntegrationContacts!
    switch (this.raw.source) {
      case 'hubspot':
        return 'https://files.openphone.co/a/i/hubspot-icon.svg'
      case 'salesforce':
        return 'https://files.openphone.co/a/i/salesforce-icon.svg'
      default:
        return null
    }
  }

  localUpdate = (attrs: Partial<RawIntegrationContact>) => {
    this.raw = {
      ...this.raw,
      ...attrs,
    }
  }

  deserialize = (json: any) => {
    if (json) {
      Object.assign(this.raw, json)
    }
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      identifiers: toJS(this.identifiers),
      orgId: this.orgId,
      firstName: this.firstName,
      lastName: this.lastName,
      company: this.company,
      role: this.role,
      phoneNumbers: toJS(this.phoneNumbers),
      emails: toJS(this.emails),
      externalId: this.externalId,
      source: this.source,
      url: this.url,
      data: toJS(this.data),
      enrichment: toJS(this.enrichment),
    }
  }
}

export const isIntegrationContact = (a: any): a is IntegrationContactModel => {
  return a instanceof IntegrationContactModel
}

const isHubSpotData = (data: any, source: string): data is HubSpotData => {
  return !!data && source === 'hubspot'
}

const isSalesforceData = (data: any, source: string): data is SalesforceData => {
  return !!data && source === 'salesforce'
}

const isEnrichedHubSpotData = (data: any, source: string): data is HubSpotEnrichment => {
  return !!data && source === 'hubspot'
}
