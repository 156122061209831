const empty = Symbol('empty')

type Empty = typeof empty

function isEmpty(value: unknown): value is Empty {
  return value === empty
}

export default class DataResource<T = unknown> {
  private result: T | Empty = empty
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- FIXME: Fix this ESLint violation!
  private error: unknown | Empty = empty
  private promise: Promise<T> | Empty = empty

  constructor(private readonly loader: () => Promise<T>) {}

  async load(): Promise<T> {
    let promise = this.promise

    if (!isEmpty(promise)) {
      return promise
    }

    promise = this.loader()
      .then((result) => {
        this.result = result
        return result
      })
      .catch((error) => {
        this.error = error
        throw error
      })

    this.promise = promise

    return promise
  }

  get(): T | undefined {
    if (isEmpty(this.result)) {
      return undefined
    }

    return this.result
  }

  read() {
    if (!isEmpty(this.result)) {
      return this.result
    }

    if (!isEmpty(this.error)) {
      throw this.error
    }

    // eslint-disable-next-line @typescript-eslint/only-throw-error -- FIXME: Fix this ESLint violation!
    throw this.load()
  }
}
