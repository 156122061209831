export const PREMIUM_PLAN_ANNUAL_PRICE_ID = 'premium-annual'
export const PREMIUM_PLAN_ANNUAL_V6_PRICE_ID = 'premium-annual-v6'
export const PREMIUM_PLAN_MONTHLY_PRICE_ID = 'premium-monthly'
export const PREMIUM_PLAN_MONTHLY_V6_PRICE_ID = 'premium-monthly-v6'
export const STANDARD_PLAN_ANNUAL_PRICE_ID = 'standard-annual'
export const STANDARD_PLAN_ANNUAL_V5_PRICE_ID = 'standard-annual-v5'
export const STANDARD_PLAN_ANNUAL_V6_PRICE_ID = 'standard-annual-v6'
export const STANDARD_PLAN_MONTHLY_PRICE_ID = 'standard-monthly'
export const STANDARD_PLAN_MONTHLY_V3_PRICE_ID = 'standard-monthly-v3'
export const STANDARD_PLAN_MONTHLY_V5_PRICE_ID = 'standard-monthly-v5'
export const STANDARD_PLAN_MONTHLY_V6_PRICE_ID = 'standard-monthly-v6'
export const ADDITIONAL_NUMBER_ANNUAL_PRICE_ID = 'additional-annual'
export const ADDITIONAL_NUMBER_MONTHLY_PRICE_ID = 'additional-monthly'
export const LOW_VOLUME_STANDARD_MONTHLY_PRICE_ID = 'low-volume-standard'
export const LOW_VOLUME_STANDARD_ANNUAL_PRICE_ID = 'low-volume-standard-annual'
export const SOLE_PROP_PRICE_ID = 'sole-prop'
export const SOLE_PROP_ANNUAL_PRICE_ID = 'sole-prop-annual'
export const CHARITY_PRICE_ID = 'charity'
export const CHARITY_ANNUAL_PRICE_ID = 'charity-annual'
export const CREDITS_PRICE_ID = 'credits'
export const COUPON_PRICE_ID = 'coupon'
export const PRORATED_ADJUSTMENTS_PRICE_ID = 'prorated-adjustments'
export const SCALE_PLAN_MONTHLY_V6_PRICE_ID = 'scale-monthly-v6'
export const SCALE_PLAN_ANNUAL_V6_PRICE_ID = 'scale-annual-v6'

export type PriceId =
  | typeof PREMIUM_PLAN_ANNUAL_PRICE_ID
  | typeof PREMIUM_PLAN_ANNUAL_V6_PRICE_ID
  | typeof PREMIUM_PLAN_MONTHLY_PRICE_ID
  | typeof PREMIUM_PLAN_MONTHLY_V6_PRICE_ID
  | typeof STANDARD_PLAN_ANNUAL_PRICE_ID
  | typeof STANDARD_PLAN_ANNUAL_V5_PRICE_ID
  | typeof STANDARD_PLAN_ANNUAL_V6_PRICE_ID
  | typeof STANDARD_PLAN_MONTHLY_PRICE_ID
  | typeof STANDARD_PLAN_MONTHLY_V3_PRICE_ID
  | typeof STANDARD_PLAN_MONTHLY_V5_PRICE_ID
  | typeof STANDARD_PLAN_MONTHLY_V6_PRICE_ID
  | typeof ADDITIONAL_NUMBER_ANNUAL_PRICE_ID
  | typeof ADDITIONAL_NUMBER_MONTHLY_PRICE_ID
  | typeof LOW_VOLUME_STANDARD_MONTHLY_PRICE_ID
  | typeof LOW_VOLUME_STANDARD_ANNUAL_PRICE_ID
  | typeof SOLE_PROP_PRICE_ID
  | typeof SOLE_PROP_ANNUAL_PRICE_ID
  | typeof CHARITY_PRICE_ID
  | typeof CHARITY_ANNUAL_PRICE_ID
  | typeof CREDITS_PRICE_ID
  | typeof COUPON_PRICE_ID
  | typeof PRORATED_ADJUSTMENTS_PRICE_ID
  | typeof SCALE_PLAN_MONTHLY_V6_PRICE_ID
  | typeof SCALE_PLAN_ANNUAL_V6_PRICE_ID
  // we open this type up to any string because we don't know all the id values that prices have
  // but also have the unionize constants of known prices to help with autocompletion
  | (string & {})
