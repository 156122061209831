import { useCallback, useState } from 'react'

import useAsyncAction from '@src/lib/hooks/useAsyncAction'

export default function useAsyncState<T>({
  fetcher,
  enabled = true,
  defaultValue,
}: {
  fetcher: () => Promise<T>
  enabled?: boolean
  defaultValue?: T
}) {
  const [isLoading, setLoading] = useState(true)
  const [value, setValue] = useState<T | undefined>(defaultValue)

  useAsyncAction(
    async () => {
      if (!enabled) {
        return
      }
      return await fetcher()
    },
    (value) => {
      setLoading(false)
      setValue(value)
    },
    [enabled],
  )

  const mutate = useCallback(
    async (callback: () => Promise<void>) => {
      if (!enabled) {
        return
      }

      setLoading(true)

      try {
        await callback()
      } finally {
        setLoading(false)
      }
    },
    [enabled],
  )

  const refetch = useCallback(
    async (options?: { silent?: boolean }) => {
      const { silent } = options ?? { silent: true }

      if (!silent) {
        setLoading(true)
      }

      try {
        const result = await fetcher()
        setValue(result)
      } finally {
        if (!silent) {
          setLoading(false)
        }
      }
    },
    [fetcher],
  )

  return { value, mutate, refetch, isLoading }
}
