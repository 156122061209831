import config from '@src/config'

import type {
  AiJobDefinitionByIdInput,
  AiJobDefinitionList,
  AiJobDefinitionListInput,
  AiJobDefinition,
} from './ai-client/aiJobDefinitionSchema'
import type {
  AiPromptDefinitionList,
  AiPromptDefinitionListInput,
  AiPromptDefinitionActiveListInput,
  AiPromptDefinition,
  AiPromptDefinitionCreateInput,
  AiPromptDefinitionUpdateInput,
  AiPromptDefinitionDeleteInput,
  AiPromptDefinitionByIdInput,
} from './ai-client/aiPromptDefinitionSchema'
import type {
  AiToolDefinitionByIdInput,
  AiToolDefinitionList,
  AiToolDefinitionListInput,
  AiToolDefinition,
} from './ai-client/aiToolDefinitionSchema'
import type {
  AiTriggerDefinition,
  AiTriggerDefinitionCreateInput,
  AiTriggerDefinitionDeleteInput,
  AiTriggerDefinitionByIdInput,
  AiTriggerDefinitionList,
  AiTriggerDefinitionListInput,
  AiTriggerDefinitionUpdateInput,
  AiTriggerDefinitionValidateInput,
  AiTriggerDefinitionValidateExistingInput,
} from './ai-client/aiTriggerDefinitionSchema'
import type Transport from './index'
import { HttpTransaction } from './transaction'

export interface PromptSettings {
  id: string
  version: number
  name: string
  aiProviderId: string
  phoneNumberId: string
  useCase: string
  isActive: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

type AgentResourceDataItem = {
  storageBucket: string
  storageKey: string
}

type AgentResourceContentItem = {
  content: string
}

export type AgentResourceResponse = {
  id: string
  version: number
}

export interface AgentResource {
  createdAt: string
  updatedAt: string
  deletedAt?: string
  version: number
  createdBy: string
  updatedBy: string
  deletedBy?: string
  id: string
  status: 'processing' | 'completed' | 'failed'
  data: AgentResourceDataItem | AgentResourceContentItem
  mimeType: string
  filename: string
  title: string
  emoji?: string
}

export default class AiClient {
  constructor(private transport: Transport) {}
  automations = {
    getResourceById: (id: string): Promise<AgentResource> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources/${id}`,
          method: 'GET',
        }),
      )
    },
    getResources: (): Promise<{
      data: AgentResource[]
      totalItems: number
      nextPageToken: string
    }> => {
      const params = new URLSearchParams({
        maxResults: '1000',
      })

      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources?${params.toString()}`,
          method: 'GET',
        }),
      )
    },
    createResourcePage: (
      title: string,
      content: string,
    ): Promise<{
      id: string
      version: number
    }> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources/text`,
          method: 'POST',
          body: {
            title,
            content,
          },
        }),
      )
    },
    updateResourcePage: (
      id: string,
      title: string,
      content: string,
    ): Promise<{
      id: string
      version: number
    }> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources/text/${id}`,
          method: 'PUT',
          body: {
            title,
            content,
          },
        }),
      )
    },
    deleteResource: (id: string) => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-resources/${id}`,
          method: 'DELETE',
        }),
      )
    },
  }
  triggerDefinitions = {
    getById: (
      input: AiTriggerDefinitionByIdInput,
      signal?: AbortSignal,
    ): Promise<AiTriggerDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-definitions/${input.id}`,
          method: 'GET',
          signal,
          query: {
            version:
              typeof input.version !== 'undefined' ? `${input.version}` : undefined,
          },
        }),
      )
    },
    getAll: (
      input: AiTriggerDefinitionListInput,
      signal?: AbortSignal,
    ): Promise<AiTriggerDefinitionList> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-definitions`,
          method: 'GET',
          signal,
          query: {
            maxResults: `${input.maxResults ?? 1000}`,
            pageToken: input.pageToken ? `${input.pageToken}` : undefined,
            entityIds: input.entityIds ? `${input.entityIds.join(',')}` : undefined,
          },
        }),
      )
    },
    create: (
      input: AiTriggerDefinitionCreateInput,
      signal?: AbortSignal,
    ): Promise<AiTriggerDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-definitions`,
          method: 'POST',
          body: input,
          skipPersisting: true,
          signal,
        }),
      )
    },
    update: (
      input: AiTriggerDefinitionUpdateInput,
      signal?: AbortSignal,
    ): Promise<AiTriggerDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-definitions/${input.id}`,
          method: 'PUT',
          body: input,
          skipPersisting: true,
          signal,
        }),
      )
    },
    delete: (
      input: AiTriggerDefinitionDeleteInput,
      signal?: AbortSignal,
    ): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-definitions/${input.id}`,
          method: 'DELETE',
          skipPersisting: true,
          signal,
        }),
      )
    },
    validate: (
      input: AiTriggerDefinitionValidateInput,
      signal?: AbortSignal,
    ): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-definitions/validate`,
          method: 'POST',
          body: input,
          signal,
        }),
      )
    },
    validateExisting: (
      input: AiTriggerDefinitionValidateExistingInput,
      signal?: AbortSignal,
    ): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-definitions/${input.id}/validate`,
          method: 'POST',
          body: input,
          signal,
        }),
      )
    },
  }
  jobDefinitions = {
    getAll: (
      input?: AiJobDefinitionListInput,
      signal?: AbortSignal,
    ): Promise<AiJobDefinitionList> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-job-definitions`,
          method: 'GET',
          signal,
          query: {
            maxResults: `${input?.maxResults ?? 10}`,
            pageToken: input?.pageToken ? `${input.pageToken}` : undefined,
          },
        }),
      )
    },
    getById: (
      input: AiJobDefinitionByIdInput,
      signal?: AbortSignal,
    ): Promise<AiJobDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-job-definitions/${input.id}`,
          method: 'GET',
          signal,
          query: {
            version:
              typeof input.version !== 'undefined' ? `${input.version}` : undefined,
          },
        }),
      )
    },
  }
  aiAgentPromptDefinitions = {
    getAll: (
      input: AiPromptDefinitionListInput,
      signal?: AbortSignal,
    ): Promise<AiPromptDefinitionList> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-prompt-definitions`,
          method: 'GET',
          signal,
          query: {
            maxResults: `${input.maxResults ?? 10}`,
            pageToken: input.pageToken ? `${input.pageToken}` : undefined,
            modelType: input.modelType ? `${input.modelType}` : undefined,
            promptType: input.promptType ? `${input.promptType}` : undefined,
          },
        }),
      )
    },
    getById: (
      input: AiPromptDefinitionByIdInput,
      signal?: AbortSignal,
    ): Promise<AiPromptDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-prompt-definitions/${input.id}`,
          method: 'GET',
          signal,
          query: {
            version:
              typeof input.version !== 'undefined' ? `${input.version}` : undefined,
          },
        }),
      )
    },
    getActive: (
      input: AiPromptDefinitionActiveListInput,
      signal?: AbortSignal,
    ): Promise<AiPromptDefinitionList> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-prompt-definitions/active`,
          method: 'GET',
          signal,
          query: {
            modelType: `${input.modelType}`,
          },
        }),
      )
    },
    create: (
      input: AiPromptDefinitionCreateInput,
      signal?: AbortSignal,
    ): Promise<AiPromptDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-prompt-definitions`,
          method: 'POST',
          body: input,
          signal,
        }),
      )
    },
    update: (
      input: AiPromptDefinitionUpdateInput,
      signal?: AbortSignal,
    ): Promise<AiPromptDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-prompt-definitions/${input.id}`,
          method: 'PUT',
          body: input,
          signal,
        }),
      )
    },
    delete: (
      input: AiPromptDefinitionDeleteInput,
      signal?: AbortSignal,
    ): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-prompt-definitions/${input.id}`,
          method: 'DELETE',
          signal,
        }),
      )
    },
  }
  toolDefinitions = {
    getAll: (
      input: AiToolDefinitionListInput,
      signal?: AbortSignal,
    ): Promise<AiToolDefinitionList> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-tool-definitions`,
          method: 'GET',
          signal,
          query: {
            maxResults: `${input.maxResults ?? 10}`,
            pageToken: input.pageToken ? `${input.pageToken}` : undefined,
          },
        }),
      )
    },
    getById: (
      input: AiToolDefinitionByIdInput,
      signal?: AbortSignal,
    ): Promise<AiToolDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}agent-tool-definitions/${input.id}`,
          method: 'GET',
          signal,
          query: {
            version:
              typeof input.version !== 'undefined' ? `${input.version}` : undefined,
          },
        }),
      )
    },
  }
  prompt = {
    toggle: (promptId: string, isActive: boolean): Promise<PromptSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}config/prompt/${promptId}/toggle`,
          method: 'PUT',
          body: {
            isActive,
          },
        }),
      )
    },

    get: (phoneNumberId: string): Promise<PromptSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}config/prompt/phone-number/${phoneNumberId}`,
          method: 'GET',
        }),
      )
    },
  }
}
