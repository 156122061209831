import { computed, makeObservable, observable, action, toJS } from 'mobx'

import type { Model } from '.'

export interface UsageDataRaw {
  name: string
  orgId: string
  usage: number
}

export class Usage implements Model {
  private raw: UsageDataRaw

  // Usage uses the feature name as its id
  get id(): string {
    return this.raw.name
  }

  get name(): string {
    return this.raw.name
  }

  get orgId(): string {
    return this.raw.orgId
  }

  get usage(): number {
    return this.raw.usage
  }

  constructor(attrs: UsageDataRaw) {
    this.raw = attrs

    makeObservable<this, 'raw' | 'localUpdate'>(this, {
      raw: observable.deep,
      id: computed,
      name: computed,
      orgId: computed,
      usage: computed,
      localUpdate: action.bound,
    })
  }

  localUpdate(partial: Partial<UsageDataRaw>): this {
    this.raw = { ...this.raw, ...partial }
    return this
  }

  deserialize(json: UsageDataRaw): this {
    this.raw = json
    return this
  }

  serialize(): UsageDataRaw {
    return toJS(this.raw)
  }
}
