import config from '@src/config'
import { compareString, toQueryString } from '@src/lib'
import { formatted } from '@src/lib/phone-number'

import type { ParticipantModel } from '.'

export function fullName(entity: { firstName: string; lastName: string }): string {
  if (!entity) {
    return ''
  }
  return [entity.firstName, entity.lastName].filter((name) => name).join(' ')
}
// eslint-disable-next-line @typescript-eslint/no-unused-expressions -- FIXME: Fix this ESLint violation!
;``
export const thumbnailUrl = (
  url: string,
  opts: { width?: number; height?: number; quality?: number },
): string => {
  if (url?.startsWith(config.STATIC_URL)) {
    const query = toQueryString(opts)
    return `${config.FILES_URL}${url.substr(config.STATIC_URL.length)}${query}`
  }
  return url
}

export const buffer = <T>(
  handler: (objs: T[]) => void,
  opts: { duration: number; maxSize?: number } = { duration: 0 },
) => {
  let timeout: number | null = null
  let items = new Set<T>()

  const flush = () => {
    // Create a completely new buffer for next round
    const currentItems = items

    // Reset the items set by creating a new Set to ensure complete isolation from any modifications
    items = new Set<T>()

    // Only proceed if we have items to process
    if (currentItems.size > 0) {
      // Create a NEW array from the Set to pass to the handler
      const copy = [...currentItems]
      handler(copy)
    }
  }

  return function (obj: T) {
    if (timeout) {
      window.clearTimeout(timeout)
      timeout = null
    }

    // Add the item to the Set - duplicates are automatically handled
    items.add(obj)

    if (opts.maxSize && items.size >= opts.maxSize) {
      flush()
    } else {
      timeout = window.setTimeout(flush, opts.duration)
    }
  }
}

export const compareByName = (
  a: { name: string; sortName?: string },
  b: { name: string; sortName?: string },
): number =>
  compareString(a.sortName ?? a.name, b.sortName ?? b.name, { caseInsensitive: true })

export const participantsFriendlyName = (participants: ParticipantModel[]): string => {
  return participants
    .map((participant) => {
      const name = participants.length === 1 ? participant.name : participant.shortName
      return name || formatted(participant.phoneNumber)
    })
    .join(', ')
}
