import { useObserver } from 'mobx-react-lite'

import { useFlag } from '@src/app/feature-flags'
import { useService } from '@src/service/context'

import { useParagon } from './useParagon'

// These are integrations whose titles are prefixed with "custom." in the Paragon API
export const paragonCustomIntegrations = ['jobber']
const legacyIntegrations = ['hubspot', 'salesforce', 'gong', 'zapier', 'slack']
const integrationsWithCustomConfig = ['hubspot', 'custom.jobber']

interface IntegrationsResult {
  reenable: string[]
  enabled: string[]
  available: string[]
  legacy: string[]
  withCustomConfig: string[]
}

export function useIntegrations(): IntegrationsResult {
  const hasParagonMigrationStarted = useFlag('webParagonIntegrationsMigration')
  const migratedIntegrationsFlag = useFlag('webParagonIntegrationsLegacyToParagon')
  const enableJobberIntegration = useFlag('webParagonIntegrationsEnableJobberIntegration')
  const migratedIntegrations = hasParagonMigrationStarted ? migratedIntegrationsFlag : []
  const { integrations, getAllMetadata } = useParagon()
  const service = useService()

  const allParagonMetadata = enableJobberIntegration
    ? getAllMetadata()
    : getAllMetadata().filter((metadata) => metadata.type !== 'custom.jobber')

  const withParagonData = allParagonMetadata.map((metadata) => ({
    type: metadata.type,
    metadata: metadata,
    data: integrations[metadata.type],
  }))

  const legacyEnabled = useObserver(() =>
    legacyIntegrations.filter((integration) =>
      service.integration.isConnectedByName(integration),
    ),
  )
  const reenable = legacyEnabled.filter((integration) =>
    migratedIntegrations.includes(integration),
  )
  const enabledSet = new Set(legacyEnabled)

  for (const { type, data } of withParagonData) {
    if (data?.enabled) {
      enabledSet.add(type)
    }
  }

  const availableSet = new Set([
    ...allParagonMetadata.map((metadata) => metadata.type),
    ...legacyIntegrations,
  ])

  for (const enabledIntegration of enabledSet) {
    availableSet.delete(enabledIntegration)
  }

  const actualLegacy = legacyIntegrations.filter(
    (integration) => !migratedIntegrations.includes(integration),
  )

  return {
    reenable,
    enabled: Array.from(enabledSet),
    available: Array.from(availableSet),
    legacy: actualLegacy,
    withCustomConfig: integrationsWithCustomConfig,
  }
}
