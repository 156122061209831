import { makeAutoObservable } from 'mobx'
import type Plyr from 'plyr'

import type { DecodableMessageMedia } from '@src/service/model'

type PlyrModule = typeof Plyr

type Control = 'volume' | 'playbackSpeed' | 'settings'

interface AudioPlayerControllerOptions {
  onMenuOpened?: () => void
  onMenuClosed?: () => void
  onPlay?: () => void
  onPause?: () => void
  onDownload?: () => void
}

export type AudioMedia = Pick<DecodableMessageMedia, 'url' | 'type' | 'name'>

export default class AudioPlayerController {
  plyr: Plyr
  media: AudioMedia | null = null
  openedControl: Control | null = null

  constructor(
    Plyr: PlyrModule,
    private audioElement: HTMLAudioElement,
    private options?: AudioPlayerControllerOptions,
  ) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.plyr = new Plyr(audioElement, {
      iconUrl: '/assets/plyr.svg',
      storage: { enabled: true, key: 'opplayer' },
      controls: ['play', 'progress', 'current-time'],
    })

    // Plyr has keyboard shortcuts, among them there is the shortcut `c`
    // that toggles captions, however, we mostly use Plyr for audio which
    // don't have captions and when the key is pressed the shortcut
    // handler throws an error. Therefore, with this we are overriding the
    // shortcut handler to a noop function to supress such error.
    Object.assign(this.plyr, { toggleCaptions: () => void {} })

    this.initializeEventHandlers()
  }

  private handlePlay = () => {
    this.options?.onPlay?.()
  }

  private handlePause = () => {
    this.options?.onPause?.()
  }

  private initializeEventHandlers() {
    this.plyr.on('play', this.handlePlay)
    this.plyr.on('pause', this.handlePause)
  }

  private removeEventHandlers() {
    this.plyr.off('play', this.handlePlay)
    this.plyr.off('pause', this.handlePause)
  }

  setSource(media: AudioMedia) {
    if (!media.url) {
      return
    }

    this.media = media

    this.plyr.source = {
      type: 'audio',
      sources: [{ src: media.url, type: media.type ?? undefined }],
    }
  }

  async download() {
    if (!this.media?.url) {
      return
    }
    const response = await fetch(this.media.url)
    const blob = await response.blob()
    const element = document.createElement('a')
    element.href = URL.createObjectURL(blob)
    element.download = this.media.name ?? 'audio.mp3'
    element.click()
    URL.revokeObjectURL(element.href)
    this.options?.onDownload?.()
  }

  openControl(control: Control) {
    this.openedControl = control
    this.options?.onMenuOpened?.()
  }

  closeControl() {
    this.openedControl = null
    this.options?.onMenuClosed?.()
  }

  tearDown() {
    this.removeEventHandlers()
  }
}
