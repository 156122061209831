import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'

import GlobalErrorView from './GlobalErrorView'

interface GlobalErrorBoundaryProps {
  children: React.ReactNode
}

const GlobalErrorBoundary = ({ children }: GlobalErrorBoundaryProps) => {
  return (
    <SentryErrorBoundary
      fallback={({ error, resetError }) => {
        return <GlobalErrorView error={error} reset={resetError} />
      }}
    >
      {children}
    </SentryErrorBoundary>
  )
}

export default GlobalErrorBoundary
