/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */

import { makeAutoObservable } from 'mobx'

import isNonNull from '@src/lib/isNonNull'
import log, { logError } from '@src/lib/log'
import Collection from '@src/service/collections/Collection'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import type { Invitation } from '@src/service/transport/account'

import type Service from '.'
import aiAgentConstants from './aiAgentConstants'
import type { MemberDirectNumber } from './model'
import { MemberModel, PresenceModel } from './model'
import type { MemberRepository, PresenceRepository } from './worker/repository'

interface UpdateInvite {
  member: MemberModel
  phoneNumberIds?: string[]
  needsNewNumber?: boolean
}

export default class MemberStore {
  collection: PersistedCollection<MemberModel, MemberRepository>
  presence: PersistedCollection<PresenceModel, PresenceRepository>
  orgMember = new Collection<MemberModel>({ bindElements: true })
  private _aiAgentMember: MemberModel

  constructor(private service: Service) {
    this.collection = new PersistedCollection({
      table: service.storage.table('member'),
      classConstructor: () => new MemberModel(service.member),
    })
    this.presence = new PersistedCollection({
      table: service.storage.table('presence'),
      classConstructor: () => new PresenceModel(service.member),
    })

    makeAutoObservable(this, {})
    this.subscribeToWebSocket()

    this._aiAgentMember = new MemberModel(this)
    this._aiAgentMember.deserialize({
      id: aiAgentConstants.id,
      firstName: aiAgentConstants.name,
      pictureUrl: aiAgentConstants.lightAvatar,
      pictureUrlDark: aiAgentConstants.darkAvatar,
    })
  }

  get(id: string) {
    if (id === aiAgentConstants.id) {
      return this._aiAgentMember
    }

    return this.collection.get(id)
  }

  get directNumbers(): MemberDirectNumber[] {
    return this.collection.list.map((member) => member.directNumber).filter(isNonNull)
  }

  get aiAgentMember() {
    return this.get(this._aiAgentMember.id)
  }

  getWorkspaceCreatorId(): string | null {
    if (this.collection.list.length === 0) {
      log.error(
        'No members found in the workspace while trying to determine the workspace creator.',
      )
      return null
    }

    let firstMember = this.collection.list[0]

    for (const member of this.collection.list) {
      if (member.createdAt === null) {
        continue
      }

      if (member.createdAt < (firstMember?.createdAt ?? Infinity)) {
        firstMember = member
      }
    }

    if (!firstMember) {
      log.error(
        'No member found in the workspace while trying to determine the workspace creator.',
      )
      return null
    }

    return firstMember.id
  }

  get isShowOnCallStatusEnabled() {
    return this.service.capabilities.features.showOnCallStatusEnabled
  }

  getSharedPhoneNumbers(memberId: string) {
    return this.service.phoneNumber.collection.list.filter((pn) =>
      pn.isSharedWith(memberId),
    )
  }

  fetch() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.collection.performQuery((repo) => repo.all())
    return this.service.transport.account.members
      .list()
      .then((res) => this.collection.load(res, { deleteOthers: true }))
  }

  setRole(member: MemberModel): Promise<any> {
    this.orgMember.put(member)
    this.collection.put(member)
    if (member.status === 'invited' && this.service.organization.current) {
      return this.service.transport.account.organization
        .for(this.service.organization.current.id)
        .invites.update(member.id, { role: member.role })
    } else {
      return this.service.transport.account.members.put(member.id, { role: member.role })
    }
  }

  fetchAdmin() {
    if (!this.service.organization.current) {
      return this.handleCurrentOrganizationAbscence()
    }

    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .member.fetch()
      .then((res) => {
        const members = res.map((json) =>
          new MemberModel(this.service.member).deserialize(json),
        )
        this.orgMember.putBulk(members)
        return members
      })
  }

  fetchPresence() {
    if (!this.service.organization.current) {
      return this.handleCurrentOrganizationAbscence()
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.presence.performQuery((repo) => repo.all())
    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .presence.list()
      .then((res) => this.presence.load(res, { deleteOthers: true }))
  }

  updatePresence(presence: PresenceModel) {
    this.presence.put(presence)
    return this.service.transport.account.presence.put(presence.serialize())
  }

  setDoNotDisturb(presence: PresenceModel) {
    if (presence.snoozedUntil && presence.snoozedUntil > Date.now()) {
      const duration = (presence.snoozedUntil - Date.now()) / 1000 / 60
      return this.service.transport.account.presence.doNotDisturb(duration)
    } else {
      return this.service.transport.account.presence.clearDoNotDisturb()
    }
  }

  invite = (invite: Invitation) => {
    if (!this.service.organization.current) {
      return this.handleCurrentOrganizationAbscence()
    }

    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .invites.send(invite)
      .then((res) => {
        this.service.analytics.workspace.invited()
        return this.orgMember.put(new MemberModel(this.service.member).deserialize(res))
      })
  }

  reinvite = async (memberId: string) => {
    if (!this.service.organization.current) {
      return this.handleCurrentOrganizationAbscence()
    }

    const invite = await this.service.organization.getInvite(memberId)

    if (!invite) {
      logError(new Error('Failed to reinvite member'))
      throw new Error('Failed to reinvite member')
    }

    await this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .invites.resend(invite.id)

    this.service.analytics.workspace.invited()
  }

  inviteBulk = (invites: Invitation[]) => {
    if (!this.service.organization.current) {
      return this.handleCurrentOrganizationAbscence()
    }

    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .invites.sendBulk(invites)
      .then((res) => {
        this.service.analytics.workspace.invited()
        return res
      })
  }

  // TODO: this will append the phone number to the existing invites but it's not an ideal solution
  // https://linear.app/openphone/issue/GROW-1604/improve-the-update-invite-approach
  updateInvite = async ({ member, phoneNumberIds, needsNewNumber }: UpdateInvite) => {
    if (!this.service.organization.current) {
      return this.handleCurrentOrganizationAbscence()
    }

    const { id, role } = member

    return await this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .invites.update(id, {
        role,
        phoneNumberIds,
        needsNewNumber,
      })
  }

  uninvite = (inviteId: string) => {
    if (!this.service.organization.current) {
      return this.handleCurrentOrganizationAbscence()
    }

    this.orgMember.delete(inviteId)
    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .invites.delete(inviteId)
  }

  delete = (member: MemberModel) => {
    if (!this.service.organization.current) {
      return this.handleCurrentOrganizationAbscence()
    }

    this.collection.delete(member)
    this.orgMember.delete(member)
    return this.service.transport.account.organization
      .for(this.service.organization.current.id)
      .member.delete(member.id)
  }

  private subscribeToWebSocket() {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'user-presence-update':
          return this.presence.load(data.presence)
        case 'member-update': {
          // Check if there's an existing member with 'invited' status that matches the email in the update.
          const invitedMember = this.orgMember.list.find(
            (member) => member.status === 'invited' && member.email === data.member.email,
          )

          // If an invited member exists and the update changes their status to something other than 'invited',
          // we need to remove the invited instance.
          if (invitedMember && data.member.status !== 'invited') {
            this.orgMember.delete(invitedMember.id)
          }

          this.orgMember.put(
            new MemberModel(this.service.member).deserialize(data.member),
          )
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.collection.load(data.member)

          if (data.member.id === this.service.user.current?.id) {
            void this.service.organization.phoneNumber.fetch()
          }
          break
        }
        case 'member-delete':
          this.orgMember.delete(data.memberId)
          return this.collection.delete(data.memberId)
      }
    })
  }

  private handleCurrentOrganizationAbscence() {
    if (this.service.transport.online) {
      logError(new Error('Current organization is null'))
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors -- FIXME: Fix this ESLint violation!
      return Promise.reject(`An error occurred`)
    } else {
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors -- FIXME: Fix this ESLint violation!
      return Promise.reject(`Can't perform this action while offline`)
    }
  }
}
