import { toE164 } from '@src/lib/phone-number'

/**
 * The base class for any object that has a displayable profile
 * i.e. contacts, members, orgs, groups, etc
 */
export interface Identity {
  id: string
  name: string
  shortName: string | null
  initials: string | null
  pictureUrl?: string | null
  pictureUrlDark?: string | null
  pictureSymbol?: string
  phones: IdentityPhone[]
  emailAddresses: string[]
  isAnonymous: boolean
  source?: string
  sourceIconUrl?: string | null
}

export function isIdentity(obj: any): obj is Identity {
  return (
    !!obj &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    typeof obj.id === 'string' &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    typeof obj.name === 'string' &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    typeof obj.shortName === 'string' &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    typeof obj.initials === 'string' &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    Array.isArray(obj.phones) &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    Array.isArray(obj.emailAddresses) &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    typeof obj.isAnonymous === 'boolean'
  )
}

export interface IdentityPhone {
  id: string | null
  name: string | null
  symbol: string | null
  number: string
  isOffHours?: boolean | null
  isShared?: boolean | null
}

/**
 * Model class acting as base for every domain object
 */
export interface Model {
  id: string
  deserialize(json: any): this
  serialize(): any
  tearDown?(): void
}

export function isModel(item: unknown): item is Model {
  return item != null && typeof item === 'object' && 'id' in item
}

export function createAnonymousIdentityFromNumber(number: string): Identity {
  return {
    id: number,
    name: number,
    shortName: null,
    initials: null,
    pictureUrl: null,
    phones: [
      {
        id: null,
        name: null,
        symbol: null,
        number: toE164(number),
        isOffHours: null,
        isShared: null,
      },
    ],
    emailAddresses: [],
    isAnonymous: true,
  }
}
