import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { useAppStore } from '@src/app/context'
import Header from '@src/app/onboarding/Header'
import { useRecaptcha } from '@src/app/recaptcha'
import Button from '@src/component/ButtonV2'
import Tooltip from '@src/component/tooltip'
import FormField from '@ui/FormField/FormField'
import PasswordInputControl from '@ui/PasswordInputControl/PasswordInputControl'
import TextInputControl from '@ui/TextInputControl/TextInputControl'
import Typography from '@ui/Typography'
import { Spacer } from '@ui/layout/Spacer'

import * as styles from './Password.css'

const schema = z.object({
  email: z.string().email('Invalid email format').trim().nonempty('Email is required'),
  password: z.string().nonempty('Password is required'),
})

function Password() {
  const { login: loginStore } = useAppStore()
  const { isReady: isGrecaptchaReady, generateToken } = useRecaptcha()

  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    formState: { isValid, errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: loginStore.email ?? '',
      password: '',
    },
  })

  const emailValue = watch('email')

  const handleForgot = () => {
    loginStore.email = emailValue
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    loginStore.setStep('forgot')
  }

  const handleBack = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    loginStore.setStep('login')
  }

  const onSubmit = handleSubmit(async (data) => {
    let recaptcha_token
    try {
      recaptcha_token = await generateToken(
        loginStore.mode === 'login' ? 'login_code' : 'signup_code',
      )
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      loginStore.loginWithPassword(data.email, data.password, recaptcha_token)
    }
  })

  useEffect(() => {
    setFocus(loginStore?.email ? 'password' : 'email')
  }, [setFocus, loginStore])

  return (
    <div>
      <Header />
      <div className={styles.container}>
        <div className={styles.card}>
          <Typography variant="emoji" className={styles.emoji}>
            👋
          </Typography>
          <Spacer height={8} />
          <Typography variant="largeTitle">Welcome back!</Typography>
          <Spacer height={8} />
          <Typography variant="body" color="textSecondary">
            Enter your email address and password to log back in to your account
          </Typography>

          <form onSubmit={onSubmit} className={styles.form}>
            <Spacer height={40} />
            <FormField label="Email" error={errors.email?.message}>
              <TextInputControl
                type="email"
                size={45}
                autoComplete="email"
                {...register('email')}
              />
            </FormField>
            <Spacer height={16} />
            <FormField label="Password" error={errors.password?.message}>
              <PasswordInputControl
                autoComplete="current-password"
                defaultHidden
                {...register('password')}
              />
            </FormField>
            <Spacer height={16} />
            {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
            <Button
              fullWidth
              height={40}
              variant="contained"
              color="primary"
              disabled={!isValid && isGrecaptchaReady}
              loading={loginStore.loading === 'logging_in'}
              type="submit"
            >
              Continue
            </Button>
            <Spacer height={8} />
            <Tooltip disabled={Boolean(emailValue)} title="Enter an email first">
              <span>
                {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
                <Button
                  fullWidth
                  height={36}
                  variant="text"
                  color="textSecondary"
                  type="button"
                  onClick={handleForgot}
                  disabled={!emailValue}
                >
                  Forgot your password?
                </Button>
              </span>
            </Tooltip>
            <Spacer height={8} />

            {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
            <Button
              fullWidth
              height={36}
              variant="text"
              color="textSecondary"
              type="button"
              onClick={handleBack}
            >
              Go back
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default observer(Password)
