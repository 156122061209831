import Typography from '@material-ui/core/Typography'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import AnimatingLogo from '@src/app/components/AnimatingLogo'
import Button from '@src/component/button'
import { fromQueryString, toQueryString } from '@src/lib/query-string'

import * as styles from './NativeLogin.css'
import { redirectGoogleLogin } from './google-login'

function NativeLogin() {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
  const [params, setParams] = useState<any>(null)

  const [searchParams, setSearchParams] = useState<{
    [key: string]: string
  } | null>(null)

  useEffect(() => {
    // used by universal login
    const searchParams = fromQueryString(location.search)
    if (searchParams.code && searchParams.state) {
      setSearchParams(searchParams)
      redirectToUniversalLogin(searchParams)
      return
    }

    const params = fromQueryString(location.hash)
    if (params?.access_token) {
      setParams(params)
      redirectToApp(params)
    } else {
      redirectGoogleLogin()
    }
  }, [location.hash, location.search])

  const redirectToUniversalLogin = (result: { [key: string]: string }) => {
    window.location.replace(`openphone://auth/authorize${toQueryString(result)}`)
  }

  const redirectToApp = (result: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    window.location.replace(`openphone://login${toQueryString(result)}`)
  }

  return (
    <div className={cx(styles.root, { [styles.redirect]: !params || !searchParams })}>
      {(params || searchParams) && (
        <>
          <AnimatingLogo />
          <Typography style={{ marginTop: 30 }} variant="body2" color="textSecondary">
            Redirecting you to the desktop app...
          </Typography>
          {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
          <Button
            style={{ marginTop: 10 }}
            variant="text"
            color="primary"
            onClick={() =>
              searchParams
                ? redirectToUniversalLogin(searchParams)
                : redirectToApp(params)
            }
          >
            Click here if it's taking too long
          </Button>
        </>
      )}
    </div>
  )
}

export default NativeLogin
