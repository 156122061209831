import { captureException } from '@sentry/react'

import config from '@src/config'
import type { UserModel } from '@src/service/model'

declare let WorkerGlobalScope: any

enum LogLevel {
  trace = 'trace',
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
  fatal = 'fatal',
}

/** Methods to call on console to log each LogLevel. */
const consoleMethods: Record<LogLevel, Console['log']> = {
  [LogLevel.trace]: console.trace,
  [LogLevel.debug]: console.debug,
  [LogLevel.info]: console.info,
  [LogLevel.warn]: console.warn,
  [LogLevel.error]: console.error,
  [LogLevel.fatal]: console.error,
}

/**
 * Ranking of LogLevel keys to determine which logs to print for a given LogLevel.
 */
const logLevelRanks: Record<LogLevel, number> = {
  [LogLevel.trace]: 0,
  [LogLevel.debug]: 1,
  [LogLevel.info]: 2,
  [LogLevel.warn]: 3,
  [LogLevel.error]: 3,
  [LogLevel.fatal]: 3,
}

/**
 * @internalapi
 */
class Log {
  private user: UserModel | null = null

  /**
   * The current LogLevel threshold.
   */
  get logLevel() {
    return this._logLevel
  }

  /**
   * @param _logLevel - The initial LogLevel threshold to display logs for.
   */
  constructor(
    private _logLevel: LogLevel,
    private isDev: boolean,
  ) {}

  /**
   * Log a console.info message if the current LogLevel threshold is 'debug'.
   * @param message - Message to log
   * @param meta - Meta data to send with the log
   */
  debug(message: string, meta?: Record<string, any>): void {
    this.log(LogLevel.debug, message, meta)
  }

  /**
   * Log a console.error message if the current LogLevel threshold is 'error' or lower.
   * @param message - Message to log
   * @param meta - Meta data to send with the log
   */
  error(message: string, meta?: Record<string, any>): void {
    this.log(LogLevel.error, message, meta)
  }

  /**
   * Log a console.error message with the error object turned into a JSON string
   * @param error - an error
   */
  toJsonString(error: any): string {
    function replaceErrors(key, value) {
      if (value instanceof Error) {
        const error = {}

        Object.getOwnPropertyNames(value).forEach(function (key) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          error[key] = value[key]
        })

        return error
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      return value
    }
    return JSON.stringify(error, replaceErrors)
  }

  /**
   * Log a console.info message if the current LogLevel threshold is 'info' or lower.
   * @param message - Message to log
   * @param meta - Meta data to send with the log
   */
  info(message: string, meta?: Record<string, any>): void {
    this.log(LogLevel.info, message, meta)
  }

  /**
   * Log a console message if the current LogLevel threshold is equal to or less than the
   *   LogLevel specified.
   * @param logLevel - The LogLevel to compare to the current LogLevel to determine
   *   whether the log should be printed.
   * @param message - Message to log
   * @param meta - Meta data to send with the log
   */
  log(logLevel: LogLevel, message: string, meta?: Record<string, any>): void {
    if (logLevelRanks[this.logLevel] <= logLevelRanks[logLevel]) {
      if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
        meta = { ...meta, worker: true }
      }
      const userName = this.user?.id ?? 'Anonymous'
      const body = `${userName} [v${config.VERSION}] - ${message}`
      if (logLevel in consoleMethods) {
        consoleMethods[logLevel](body, meta)
      }
    }
  }

  /**
   * Set/update the LogLevel threshold to apply to all future logs.
   * @param logLevel - The new LogLevel to use as a threshold for logs.
   */
  setLogLevel(logLevel: LogLevel): void {
    this._logLevel = logLevel
  }

  /**
   * Log a console.warn message if the current LogLevel threshold is 'warn' or lower.
   * @param message - Message to log
   * @param meta - Meta data to send with the log
   */
  warn(message: string, meta?: Record<string, any>): void {
    this.log(LogLevel.warn, message, meta)
  }
}

const log = config.IS_DEV ? new Log(LogLevel.trace, true) : new Log(LogLevel.info, false)

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- FIXME: Fix this ESLint violation!
const logError = (error: Error | unknown, extra?: Record<string, unknown>): void => {
  if (error instanceof Error) {
    log.error(error.message, { error, extra })
  } else if (typeof error === 'object') {
    log.error('Unknown error', { error, extra })
  } else {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string -- FIXME: Fix this ESLint violation!
    const serializable = new Error(String(error))
    log.error(serializable.message, { error, extra })
  }

  captureException(error, {
    extra,
  })
}

export default log
export { logError }
