import { makeAutoObservable, observable, toJS } from 'mobx'

import { formatDate, parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'
import type ContactStore from '@src/service/contact-store'
import type { Model } from '@src/service/model/base'
import type { ContactModel } from '@src/service/model/contact/ContactModel'

export type ContactItemTypeMap = {
  'phone-number': string
  email: string
  url: string
  string: string
  address: string
  company: string
  role: string
  'created-by': string
  access: string
  'multi-select': string[]
  date: Date
  boolean: boolean
  number: number
}

export type ContactItemType = keyof ContactItemTypeMap

interface CodableContactItem<TValue> {
  id: string
  createdAt: number | null
  updatedAt: number | null
  deletedAt: number | null
  name: string | null
  templateKey: string | null
  type: ContactItemType | null
  value: TValue | null
}

// Specializations for different contact item types
export interface ContactItemPhoneNumber
  extends CodableContactItem<ContactItemTypeMap['phone-number']> {
  type: 'phone-number'
  value: ContactItemTypeMap['phone-number']
}

export interface ContactItemDate extends CodableContactItem<ContactItemTypeMap['date']> {
  type: 'date'
  value: ContactItemTypeMap['date']
}

export interface ContactItemBoolean
  extends CodableContactItem<ContactItemTypeMap['boolean']> {
  type: 'boolean'
  value: ContactItemTypeMap['boolean']
}

export interface ContactItemMultiSelect
  extends CodableContactItem<ContactItemTypeMap['multi-select']> {
  type: 'multi-select'
  value: ContactItemTypeMap['multi-select']
}

export interface ContactItemEmail
  extends CodableContactItem<ContactItemTypeMap['email']> {
  type: 'email'
  value: ContactItemTypeMap['email']
}

export interface ContactItemUrl extends CodableContactItem<ContactItemTypeMap['url']> {
  type: 'url'
  value: ContactItemTypeMap['url']
}

export interface ContactItemString
  extends CodableContactItem<ContactItemTypeMap['string']> {
  type: 'string'
  value: ContactItemTypeMap['string']
}

export interface ContactItemNumber
  extends CodableContactItem<ContactItemTypeMap['number']> {
  type: 'number'
  value: ContactItemTypeMap['number']
}

export interface ContactItemAddress
  extends CodableContactItem<ContactItemTypeMap['address']> {
  type: 'address'
  value: ContactItemTypeMap['address']
}

export interface ContactItemCompany
  extends CodableContactItem<ContactItemTypeMap['company']> {
  type: 'company'
  value: ContactItemTypeMap['company']
}

export interface ContactItemRole extends CodableContactItem<ContactItemTypeMap['role']> {
  type: 'role'
  value: ContactItemTypeMap['role']
}

export interface ContactItemCreatedBy
  extends CodableContactItem<ContactItemTypeMap['created-by']> {
  type: 'created-by'
  value: ContactItemTypeMap['created-by']
}

export interface ContactItemAccess
  extends CodableContactItem<ContactItemTypeMap['access']> {
  type: 'access'
  value: ContactItemTypeMap['access']
}

// Union type for all possible contact item types
export type TypedContactItem =
  | ContactItemPhoneNumber
  | ContactItemDate
  | ContactItemBoolean
  | ContactItemMultiSelect
  | ContactItemEmail
  | ContactItemUrl
  | ContactItemString
  | ContactItemNumber
  | ContactItemAddress
  | ContactItemCompany
  | ContactItemRole
  | ContactItemCreatedBy
  | ContactItemAccess

export interface RawContactItem {
  id: string
  createdAt: number | null
  updatedAt: number | null
  deletedAt: number | null
  isNew?: boolean
  isPrimary?: boolean
  isPublic?: boolean
  label?: string
  name: string | null
  templateKey: string | null
  type: ContactItemType | null
  value: ContactItemTypeMap[ContactItemType] | null
}

export type LocalCodableContactItem = Partial<RawContactItem>

export class ContactItemModel implements Model {
  private raw: RawContactItem

  constructor(
    private store: ContactStore,
    private contact: ContactModel,
    attrs: Partial<RawContactItem> = {},
  ) {
    this.raw = {
      id: objectId(),
      createdAt: Date.now(),
      updatedAt: Date.now(),
      deletedAt: null,
      isNew: false,
      name: null,
      templateKey: null,
      type: null,
      value: null,
    }

    this.deserialize(attrs)

    makeAutoObservable<this, 'raw'>(this, {
      raw: observable.deep,
    })
  }

  get createdAt(): number | null {
    return this.raw.createdAt
  }

  get deletedAt(): number | null {
    return this.raw.deletedAt
  }

  get id(): string {
    return this.raw.id
  }

  get name(): string | null {
    return this.raw.name
  }

  get templateKey(): string | null {
    return this.raw.templateKey
  }

  get type(): ContactItemType | null {
    return this.raw.type
  }

  get updatedAt(): number | null {
    return this.raw.updatedAt
  }

  get value(): ContactItemTypeMap[ContactItemType] | null {
    return this.raw.value
  }

  get isDeleted(): boolean {
    return this.deletedAt !== null
  }

  get isNew(): boolean {
    return this.raw.isNew ?? false
  }

  get template() {
    const templateKey = this.templateKey
    if (!templateKey) {
      return null
    }
    return this.store.template.list.find((t) => t.key === templateKey)
  }

  get isEmpty() {
    if (!this.value) {
      return true
    }
    if (Array.isArray(this.value) && this.value.length === 0) {
      return true
    }
    return false
  }

  async update(attrs: Partial<RawContactItem>): Promise<this> {
    this.deserialize(attrs)
    await this.contact.update()
    return this
  }

  delete() {
    const items = this.contact.items.filter((i) => i !== this)
    this.contact.localUpdate({
      items,
    })
    return this.contact.update()
  }

  serialize(): RawContactItem {
    return {
      id: this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      name: this.name,
      templateKey: this.templateKey,
      type: this.type,
      value: toJS(this.value),
      isNew: this.isNew,
    }
  }

  deserialize({ deletedAt, createdAt, updatedAt, ...json }: LocalCodableContactItem) {
    if (json) {
      Object.assign(this.raw, json)
    }

    if (deletedAt) {
      this.raw.deletedAt = parseDate(deletedAt)
    }

    if (createdAt) {
      this.raw.createdAt = parseDate(createdAt)
    }

    if (updatedAt) {
      this.raw.updatedAt = parseDate(updatedAt)
    }

    return this
  }

  getTypeSafeValue(): string {
    const value = this.value
    if (value === null) {
      return ''
    }

    switch (this.type) {
      case 'phone-number':
      case 'email':
      case 'url':
      case 'string':
      case 'address':
      case 'company':
      case 'role':
      case 'created-by':
      case 'access':
        return value as string
      case 'multi-select':
        return (value as string[]).join(', ')
      case 'date':
        return formatDate(value as Date)
      case 'boolean':
        return (value as boolean).toString()
      case 'number':
        return (value as number).toString()
      default:
        return ''
    }
  }
}
