import { useAppStore } from '@src/app/context'

export default function useSuspendedCurrentUser() {
  const app = useAppStore()
  const user = app.service.user.current
  const member = user?.asMember

  if (!user || !member) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error -- FIXME: Fix this ESLint violation!
    throw app.service.user.currentUserPromise
  }

  return { user, member }
}
