import type {
  ConnectParams,
  InstallIntegrationOptions,
  CompleteInstallOptions,
} from '@useparagon/connect'
import { paragon } from '@useparagon/connect'
import { useCallback } from 'react'

import { useService } from '@src/service/context'

import type { ParagonUserSettings } from './ParagonContext'
import { ParagonContext } from './ParagonContext'
import { useParagonAuthentication } from './useParagonAuthentication'

paragon.configureGlobal({
  host: 'integrations.openphone.com',
})

export default function ParagonProvider({
  children,
  fallback,
  isElectron,
}: {
  children: React.ReactNode
  fallback: React.ReactNode
  isElectron: boolean
}) {
  const service = useService()
  const { user, error, setUserSettings, resync } = useParagonAuthentication(isElectron)

  if (!user && error) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error -- FIXME: Fix this ESLint violation!
    throw error
  }

  const getAllMetadata = useCallback(() => {
    return paragon.getIntegrationMetadata()
  }, [])

  const getMetadata = useCallback((integrationType: string) => {
    try {
      return paragon.getIntegrationMetadata(integrationType)
    } catch {
      return null
    }
  }, [])

  const connect = useCallback((integrationType: string, options: ConnectParams = {}) => {
    paragon.connect(integrationType, options)
  }, [])

  const install = useCallback(
    async (integrationType: string, options: InstallIntegrationOptions = {}) => {
      await paragon.installIntegration(integrationType, options)
    },
    [],
  )

  const migrate = useCallback(
    (integrationType: string) => {
      paragon.connect(integrationType, {
        onInstall: () => {
          void resync(integrationType)
        },
      })
    },
    [resync],
  )

  const migrateInstall = useCallback(
    (integrationType: string, options: InstallIntegrationOptions = {}) => {
      return install(integrationType, {
        ...options,
        onInstall: () => {
          void resync(integrationType)
        },
      })
    },
    [install, resync],
  )

  const completeInstall = useCallback(
    async (integrationType: string, options: CompleteInstallOptions) => {
      if (!options.authorizationCode) {
        throw new Error('authorizationCode is required for completeInstall')
      }
      await paragon.completeInstall(integrationType, {
        ...options,
        showPortalAfterInstall: false,
      })
    },
    [],
  )

  const getUserToken = useCallback(async () => {
    const tokenResponse = await service.transport.integration.paragon.token()
    return tokenResponse.token
  }, [service])

  if (!user) {
    return <>{fallback}</>
  }

  const integrations = user.integrations
  const userSettings = user.meta as ParagonUserSettings

  return (
    <ParagonContext.Provider
      value={{
        integrations,
        getMetadata,
        getAllMetadata,
        getUserToken,
        connect,
        migrate,
        userSettings,
        setUserSettings,
        install,
        migrateInstall,
        completeInstall,
      }}
    >
      {children}
    </ParagonContext.Provider>
  )
}
