import { observer } from 'mobx-react-lite'

import type { FlagKey } from '@src/service/feature-flags'

import useFlag from './useFlag'

interface FlagProps<Key extends FlagKey> {
  flag: Key
  children: React.ReactNode
  fallback?: React.ReactNode
}

function Flag<Key extends FlagKey>({ flag, children, fallback = null }: FlagProps<Key>) {
  const value = useFlag(flag)

  if (!value) {
    return <>{fallback}</>
  }

  // FIXME: https://linear.app/openphone/issue/ENG-4160
  return <>{children}</>
}

export default observer(Flag)
