import type Heap from './Heap'

export default class AiAutomationsAnalyticsStore {
  constructor(private readonly heap: Heap) {}

  /**
   * Tracks when the automations page is navigated.
   */
  automationsPageNavigated(page: 'sona') {
    this.heap.track('automations_page_navigated', { page })
  }

  /**
   * Tracks when the get ai agent button is clicked on the automations page
   * or from the workflow builder
   */
  getAiAgentClicked(page: 'automations' | 'workflow builder') {
    this.heap.track('automations_get_ai_agent_clicked', { page })
  }

  /**
   * Tracks when the confirm and pay for ai agent button is clicked on the automations page
   * or from the workflow builder
   */
  confirmAndPayForAiAgentClicked(page: 'automations' | 'workflow builder') {
    this.heap.track('automations_confirm_and_pay_for_ai_agent_clicked', { page })
  }

  /**
   * Tracks when the ai agent greeting is updated
   */
  aiAgentGreetingUpdated(newGreeting: string) {
    this.heap.track('automations_ai_agent_greeting_updated', { newGreeting })
  }

  /**
   * Tracks when the view_calls button is clicked on the ai agent settings page
   */
  viewCallsWithAiAutomationsOnCallsView(phoneNumber: string) {
    this.heap.track('view_calls_with_ai_automations_calls_view', {
      phoneNumber,
    })
  }

  /**
   * Tracks when the add knowledge button is clicked
   */
  addKnowledgeClicked() {
    this.heap.track('automations_add_knowledge_clicked')
  }

  /**
   * Tracks when a knowledge page is created
   */
  createKnowledgePage({ name, content }: { name: string; content: string }) {
    this.heap.track('automations_knowledge_page_created', {
      pageName: name,
      pageContent: content,
    })
  }

  /**
   * Tracks when a knowledge page is updated
   */
  updateKnowledgePage({
    previousPageName,
    previousPageContent,
    newPageName,
    newPageContent,
  }: {
    previousPageName: string
    previousPageContent: string
    newPageName: string
    newPageContent: string
  }) {
    this.heap.track('automations_knowledge_page_updated', {
      previousPageName,
      previousPageContent,
      newPageName,
      newPageContent,
    })
  }

  /**
   * Tracks when a failed knowledge page is retried
   */
  retryFailedKnowledgePage(pageTitle: string) {
    this.heap.track('automations_knowledge_page_retry_failed_page', { pageTitle })
  }

  /**
   * Tracks when a knowledge page is deleted from the workspace
   */
  deleteKnowledgePageClicked(pageTitle: string) {
    this.heap.track('automations_knowledge_page_deleted', { pageTitle })
  }

  /**
   * Tracks when a knowledge page is removed from the ai agent
   */
  removeKnowledgePageAccess(pageTitle: string) {
    this.heap.track('automations_knowledge_page_access_removed', { pageTitle })
  }

  /**
   * Tracks when the test ai agent button is clicked
   */
  testAiAgentClicked(
    location: 'ai_agent_step' | 'answer_questions_job' | 'take_a_message_job',
  ) {
    this.heap.track('automations_test_ai_agent_clicked', { location })
  }

  /**
   * Tracks when the take a message job is edited
   */
  takeMessageJobEdited(newMessage: string) {
    this.heap.track('automations_take_a_message_edited', { newMessage })
  }
}
