import { useEffect } from 'react'
import { type Path, Navigate, useParams } from 'react-router-dom'

import AnimatingLogo from '@src/app/components/AnimatingLogo'
import { useAppStore } from '@src/app/context'
import Button from '@src/component/button'
import Typography from '@ui/Typography'

import * as styles from './Callback.css'

export type CallbackState =
  | {
      /**
       * If set to true, we'll attempt to redirect to the electron app using a deep link.
       */
      desktop?: boolean
      redirectTo: string
    }
  | string

const Callback = function () {
  const { history } = useAppStore()
  const params = useParams<{ provider: string }>()

  const query = history.query

  if (query.state) {
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    query.state = atob(query.state)
  }

  const { state, ...queryParams } = query
  const parsedState = state ? parseState(state) : null

  const createRedirectTo = (redirectTo: string): Pick<Path, 'pathname' | 'search'> => {
    const redirectUrl = new URL(redirectTo, window.location.origin)

    for (const [queryParamKey, queryParamValue] of Object.entries(queryParams)) {
      if (queryParamValue) {
        redirectUrl.searchParams.set(queryParamKey, queryParamValue)
      }
    }

    if (params.provider) {
      redirectUrl.searchParams.set('provider', params.provider)
    }

    return {
      pathname: redirectUrl.pathname,
      search: redirectUrl.search,
    }
  }

  if (typeof parsedState === 'string' && parsedState.startsWith('/')) {
    const redirectTo = createRedirectTo(parsedState)
    return <Navigate to={redirectTo} />
  }

  if (parsedState && typeof parsedState !== 'string') {
    const redirectTo = createRedirectTo(parsedState.redirectTo)

    if (parsedState.desktop) {
      return <RedirectInElectronApp to={redirectTo} />
    }

    return <Navigate to={redirectTo} />
  }

  const error =
    "We can't quite figure out how you ended up on this screen. If you were adding an integration, try going through the flow again."

  return (
    <div className={styles.root}>
      <div style={{ padding: '4rem 2rem 0' }}>{error}</div>
      {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
      <Button
        style={{ marginTop: 40 }}
        variant="text"
        color="primary"
        onClick={() => history.push('/inbox')}
      >
        Go back to inbox
      </Button>
    </div>
  )
}

export default Callback

function parseState(state: string): CallbackState {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return JSON.parse(state)
  } catch {
    return state
  }
}

function RedirectInElectronApp({ to }: { to: Pick<Path, 'pathname' | 'search'> }) {
  useEffect(() => {
    const pathname = to.pathname.startsWith('/') ? to.pathname.slice(1) : to.pathname

    window.location.href = `openphone://${pathname}${to.search}`
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, [])

  return (
    <div className={styles.root}>
      <AnimatingLogo />
      <Typography style={{ marginTop: 30 }} variant="body" color="textSecondary">
        Opening the desktop app...
      </Typography>
      {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
      <Button
        style={{ marginTop: 10 }}
        variant="text"
        color="primary"
        onClick={() => window.close()}
      >
        Close window
      </Button>
    </div>
  )
}
