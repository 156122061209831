import type React from 'react'
import { useEffect } from 'react'

/**
 * Ideally we would use an AbortController but it's
 * not supported on node until version 15
 */
type Signal = {
  aborted: boolean
}

export default function useAsyncEffect(
  asyncFunction: (aborted: Signal) => Promise<void>,
  dependencies: React.DependencyList,
) {
  useEffect(() => {
    const signal = { aborted: false }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    asyncFunction(signal)

    return () => {
      signal.aborted = true
    }
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, dependencies)
}
