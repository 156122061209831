import { observer } from 'mobx-react-lite'

import type { FeatureTooltipProps } from './FeatureTooltip'
import FeatureTooltip from './FeatureTooltip'
import useFeatureTooltip, { type UseFeatureTooltipParams } from './useFeatureTooltip'

interface ConnectedFeatureTooltipProps
  extends Omit<FeatureTooltipProps, 'isOpen'>,
    UseFeatureTooltipParams {}

function ConnectedFeatureTooltip({
  name,
  isDisabled,
  showForUsersCreatedBefore,
  onClose,
  ...props
}: ConnectedFeatureTooltipProps) {
  const featureTooltipProps = useFeatureTooltip({
    name,
    isDisabled,
    showForUsersCreatedBefore,
    onClose,
  })

  return <FeatureTooltip {...props} {...featureTooltipProps} />
}

export default observer(ConnectedFeatureTooltip)
