import { action, makeAutoObservable, reaction } from 'mobx'

import isNonNull from '@src/lib/isNonNull'
import { logError } from '@src/lib/log'
import type Service from '@src/service'
import type { EncodableSubscription } from '@src/service/model'
import { SubscriptionModel } from '@src/service/model'
import makePersistable from '@src/service/storage/makePersistable'
import type {
  CancelParams,
  Coupon,
  CreditCard,
  StartBusinessPreviewParams,
  UpdateParams,
} from '@src/service/transport/billing'

import AddonStore from './AddonStore/AddonStore'
import InvoicesStore from './InvoicesStore'

export default class BillingStore {
  addon: AddonStore
  invoices: InvoicesStore
  /**
   * Prefer using `Billing.getCurrentSubscription()` instead if you expect the current
   * subscription to be ready.
   */
  subscription: SubscriptionModel | null = null
  coupon: Coupon | null = null
  card: CreditCard | null = null
  showUpgradeBanner = true

  /**
   * - Trigger UI changes when a KYC (Know Your Customer) check fails
   * - The client is notified about the KYC failure via WebSocket subscription update
   * - We can determine a failed attempt by comparing the `identityFailedCount` property
   *   between the new and old subscription objects
   */
  hasKycFailed = false

  constructor(private root: Service) {
    this.addon = new AddonStore(root)
    this.invoices = new InvoicesStore(root)
    this.subscribeToWebSocket()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, 'BillingStore', {
      subscription: root.storage.async((d: EncodableSubscription) =>
        new SubscriptionModel().deserialize(d),
      ),
      coupon: root.storage.async(),
      showUpgradeBanner: root.storage.sync(),
    })

    reaction(
      () => this.subscription?.identityFailedCount,
      (count, prevCount) => {
        if (isNonNull(count) && isNonNull(prevCount) && count > prevCount) {
          this.hasKycFailed = true
        }
      },
    )
  }

  /**
   * Returns the current subscription if it's ready, otherwise throws an error.
   *
   * @returns The current subscription
   * @throws If the current subscription is not ready yet
   */
  getCurrentSubscription() {
    const currentSubscription = this.subscription

    if (!currentSubscription) {
      throw new Error(
        'BillingStore.getCurrentSubscription() called before the current subscription was ready',
      )
    }

    return currentSubscription
  }

  get isSubscriptionReviewNull(): boolean {
    return (
      this.subscription?.reviewStatus === null &&
      this.root.phoneNumber.loaded &&
      this.root.user.phoneNumbers.length > 0
    )
  }

  fetchSubscription() {
    return this.root.transport.billing.subscription().then(
      action((json) => {
        this.subscription ??= new SubscriptionModel()
        this.subscription.deserialize(json)
      }),
    )
  }

  fetchIdentityVerificationSecret() {
    return this.root.transport.billing.identityVerificationSecret()
  }

  fetchCards() {
    return this.root.transport.billing.creditCard().then(
      action((res) => {
        this.card = res
      }),
    )
  }

  update(params: UpdateParams) {
    return this.root.transport.billing.update(params).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  start(params: UpdateParams) {
    return this.root.transport.billing.start(params).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  startFallback(param: UpdateParams) {
    return this.root.transport.billing.startFallback(param).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  getSubscriptionIntent() {
    return this.root.transport.billing.getSubscriptionIntent()
  }

  getPaymentIntent() {
    return this.root.transport.billing.getPaymentIntent()
  }

  convertCredits(amount: number) {
    return this.root.transport.billing.convertCredits(amount).then(
      action((res) => {
        this.upsertSubscription(res.subscription)
      }),
    )
  }

  addCredits(amount: number) {
    return this.root.transport.billing.addCredits(amount).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  autoCharge(amount: number) {
    return this.root.transport.billing.autoCharge(amount).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  reactivate() {
    return this.root.transport.billing.reactivate().then(
      action((res) => {
        // If the response contains a clientSecret, it means the payment needs 3DS authentication
        if ('clientSecret' in res) {
          return res.clientSecret
        }
        this.upsertSubscription(res)
      }),
    )
  }

  endTrial() {
    return this.root.transport.billing.endTrial().then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  cancel(params: CancelParams) {
    return this.root.transport.billing.cancel(params).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  upsertCreditCard(paymentMethodId: string) {
    return this.root.transport.billing.upsertCreditCard(paymentMethodId).then(
      action((res) => {
        this.card = res
      }),
    )
  }

  upsertSubscription(subscription: EncodableSubscription) {
    if (this.subscription) {
      this.subscription.deserialize(subscription)
    } else {
      const newSubscription = new SubscriptionModel()
      newSubscription.deserialize(subscription)
      this.subscription = newSubscription
    }
  }

  fetchCoupon(code: string) {
    return this.root.transport.billing
      .coupon(code)
      .then(action((res) => (this.coupon = res)))
  }

  /**
   * Bypasses the KYC flow
   *
   * @param orgId
   * @returns updated supscription by setting identityVerificationStatus to `failed` & reviewStatus to `needs review`
   */
  declineKyc() {
    return this.root.transport.billing.declineKyc().then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  startBusinessPreview(params: StartBusinessPreviewParams) {
    return this.root.transport.billing.startBusinessPreview(params).then(
      action((res) => {
        this.root.capabilities.load(res.capabilities)
        this.upsertSubscription(res.subscription)
      }),
    )
  }

  private handlePaymentMethodUpdateEvent(card: CreditCard) {
    this.card = card
  }

  private async handleSubscriptionUpdate({
    subscription,
  }: {
    subscription: EncodableSubscription
  }) {
    this.upsertSubscription(subscription)
    await this.invoices.fetchUpcomingInvoice()
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'subscription-update':
          this.handleSubscriptionUpdate(data).catch(logError)
          return
        case 'payment-method-update':
          this.handlePaymentMethodUpdateEvent(data.card)
          return
      }
    })
  }
}
