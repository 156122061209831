import { useDraggable } from '@dnd-kit/core'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useId, useRef } from 'react'

import { useEditor } from '@src/app/contact/ItemEditor'
import Tag from '@src/app/contact/Tag'
import colorForOption from '@src/app/contact/colorForOption'
import { useAppStore } from '@src/app/context'
import { useOpenInboxOrDefault } from '@src/app/inbox/useOpenInbox'
import IconButton from '@src/component/IconButton'
import { formatDate } from '@src/lib'
import useAsyncCopyToClipboard from '@src/lib/hooks/useAsyncCopyToClipboard'
import isNonNull from '@src/lib/isNonNull'
import { logError } from '@src/lib/log'
import { formatted } from '@src/lib/phone-number'
import type { TemplateTagItemOption } from '@src/lib/search'
import { MemberModel } from '@src/service/model'
import type {
  ContactItemModel,
  TypedContactItem,
} from '@src/service/model/contact/ContactItemModel'
import type { ContactModel } from '@src/service/model/contact/ContactModel'
import Checkbox from '@ui/Checkbox'
import {
  CallIcon,
  CopyIcon,
  EnvelopeIcon,
  MessageIcon,
  NewWindowIcon,
} from '@ui/icons/tint/16/general'

import type { ContactItemMethod } from './Item'
import Item from './Item'
import * as styles from './custom-item.css'
import { isSafeContactUrl } from './isSafeContactUrl'
import { isValueChanged } from './isValueChanged'
import { placeholders } from './placeholders'

interface CustomItemProps {
  contact: ContactModel
  contactItem: ContactItemModel
  defaultName: string
  placeholder: string | null
  editingId: string | null
  isReadOnly?: boolean
}

const CustomItem = function ({
  contact,
  contactItem,
  defaultName,
  placeholder,
  editingId,
  isReadOnly = false,
}: CustomItemProps) {
  const id = useId()
  const itemRef = useRef<ContactItemMethod>(null)
  const valueRef = useRef<HTMLDivElement>(null)
  const contactItemRef = useRef<ContactItemModel>(contactItem)
  const [editor] = useEditor()
  const { inboxes, toast, prompt, service } = useAppStore()
  const { active } = useDraggable({ id })
  const templateItem = contactItem.template
  const inbox = useOpenInboxOrDefault()
  const copyToClipboard = useAsyncCopyToClipboard()

  const messagingEnabled =
    service.capabilities.features.messagingEnabled &&
    !(
      service.billing.getCurrentSubscription().frozenState ||
      service.billing.getCurrentSubscription().isReviewRejected
    )
  const callingEnabled =
    service.capabilities.features.callingEnabled &&
    !(
      service.billing.getCurrentSubscription().frozenState ||
      service.billing.getCurrentSubscription().isReviewRejected
    )

  const callingButtonTitle = (() => {
    if (callingEnabled) {
      return 'Call'
    }

    if (service.billing.getCurrentSubscription().frozenState) {
      return 'You cannot make calls while your workspace is frozen'
    }

    if (service.billing.getCurrentSubscription().isReviewRejected) {
      return 'You cannot make calls while your workspace is disabled'
    }

    return 'Your plan currently does not support this feature'
  })()

  const messagingButtonTitle = (() => {
    if (messagingEnabled) {
      return 'Message'
    }

    if (service.billing.getCurrentSubscription().frozenState) {
      return 'You cannot send messages while your workspace is frozen'
    }

    if (service.billing.getCurrentSubscription().isReviewRejected) {
      return 'You cannot send messages while your workspace is disabled'
    }

    return 'Your plan currently does not support this feature'
  })()

  useEffect(() => {
    // There's a bug that can happen if a contact is in the process of saving
    // when the user clicks to edit an item (ex: ENG-5425). When the user
    // clicks to edit, the handleSave function below, which has captured the
    // current value of contactItem, is passed into the contact editor. After
    // the save has finished, the updated contact is received via the websocket
    // and deserialized into the model. Deserialization creates new instances
    // of all of the ContactItem classes, so, the value captured by the
    // handleSave function is stale. When handleSave is called, the stale class
    // is updated and then contact.update() is called, which "sees" no changes
    // and the update is lost.
    contactItemRef.current = contactItem
  }, [contactItem])

  useEffect(() => {
    if (editingId === contactItem.id) {
      if (templateItem && !templateItem.name) {
        itemRef.current?.changeName()
      } else {
        handleEditValue()
      }
    }
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, [])

  const handleSave = (value: unknown) => {
    // must use contactItemRef in here - see comment above
    const newValue = typeof value === 'string' ? value.trim() : value
    if (isValueChanged(contactItemRef.current.value, newValue)) {
      contactItemRef.current
        .update({ value: newValue as TypedContactItem['value'] })
        .catch(toast.show)
    }
  }

  const handleSaveString = (value: string) => {
    handleSave(value)
  }

  const handleSaveDate = (date: Date | null) => {
    if (date) {
      handleSave(date.toISOString())
    } else {
      handleSave(null)
    }
  }

  const handleSaveTag = (value: string[], newOption?: TemplateTagItemOption) => {
    const tagExists = Array.isArray(templateItem?.options)
      ? templateItem?.options.some(
          (option: TemplateTagItemOption) => option.name === newOption?.name,
        )
      : undefined

    if (newOption && !tagExists) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      templateItem?.update({
        options: [
          ...(Array.isArray(templateItem.options)
            ? (templateItem.options as TemplateTagItemOption[])
            : []),
          newOption,
        ],
      })
    }
    // String arrays are safe to pass directly
    handleSave(value)
  }

  const handleEditValue = () => {
    if (contactItem.type === 'phone-number') {
      if (valueRef.current) {
        editor(valueRef, {
          name: 'edit phone number',
          defaultValue: contactItem.getTypeSafeValue(),
          onSave: handleSave,
        })
      }
    } else if (contactItem.type && Object.keys(placeholders).includes(contactItem.type)) {
      if (valueRef.current) {
        editor(valueRef, {
          name: 'edit string',
          defaultValue: contactItem.getTypeSafeValue(),
          placeholder: placeholders[contactItem.type] ?? '',
          onSave: handleSaveString,
        })
      }
    } else if (contactItem.type === 'date') {
      if (valueRef.current) {
        const dateValue = contactItem.value as Date | null
        editor(valueRef, {
          name: 'edit date',
          defaultValue: dateValue,
          clear: Boolean(dateValue),
          onSave: handleSaveDate,
        })
      }
    } else if (contactItem.type === 'boolean') {
      const boolValue = contactItem.getTypeSafeValue()
      handleSave(!boolValue)
    } else if (contactItem.type === 'multi-select') {
      const multiSelectValue = contactItem.getTypeSafeValue()
      const isInvalidType =
        typeof multiSelectValue !== 'string' &&
        !Array.isArray(multiSelectValue) &&
        // nullish value is valid
        contactItem.value != null

      if (isInvalidType) {
        logError(
          new Error(
            `Contact ${contact.id} with user id: ${contact.userId} contains an invalid custom item (${contactItem.id})`,
          ),
        )

        return
      }

      // Safely convert the value to a string array that EditTags expects
      const stringValue = typeof multiSelectValue === 'string' ? multiSelectValue : ''
      const arrayValue = Array.isArray(multiSelectValue)
        ? multiSelectValue.filter((item): item is string => typeof item === 'string')
        : []

      const defaultValue: string[] =
        typeof multiSelectValue === 'string' ? [stringValue] : arrayValue

      if (valueRef.current && templateItem) {
        editor(valueRef, {
          name: 'edit tags',
          defaultValue,
          templateItem,
          onSave: handleSaveTag,
        })
      }
    }
  }

  const handleCall = (event: React.MouseEvent) => {
    if (inboxes.selectedPhoneNumber && contactItem.type === 'phone-number') {
      const isContactAMember = contact instanceof MemberModel

      service.voice
        .startCall(inboxes.selectedPhoneNumber, [
          {
            number: contactItem.getTypeSafeValue(),
            userId: isContactAMember ? contact.id : null,
            type: isContactAMember
              ? 'member'
              : contact.local // in case of a number we create a "fake" local contact
              ? 'number'
              : 'contact',
          },
        ])
        .catch((error) => {
          toast.showError(error)
          logError(error)
        })
    }
    event.stopPropagation()
  }

  const handleMessage = (event: React.MouseEvent) => {
    if (contactItem.type === 'phone-number') {
      inbox?.newConversation(contactItem.getTypeSafeValue())
    }
    event.stopPropagation()
  }

  const handleNameChange = (name: string) => {
    if (contactItem.name != name) {
      contactItem.update({ name, isNew: false }).catch(toast.showError)
    }
    if (templateItem && templateItem.name != name) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      templateItem.update({ name })
    }
  }

  const handleEmail = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (contactItem.type === 'email') {
      window.open(`mailto:${contactItem.getTypeSafeValue()}`)
    }
  }

  const handleUrl = (event: React.MouseEvent) => {
    event.stopPropagation()

    if (contactItem.type === 'url' && !isSafeContactUrl(contactItem.value)) {
      toast.showError(`Cannot open URL with that protocol`)
      return
    }

    if (contactItem.type === 'url') {
      window.open(contactItem.getTypeSafeValue())
    }
  }

  const handleCopy = async (event: React.MouseEvent) => {
    let val: string = contactItem.getTypeSafeValue()
    if (contactItem.type === 'phone-number') {
      val = formatted(val)
    }
    await copyToClipboard({
      copyString: val,
      successMessage: 'Copied to clipboard.',
    })
    event.stopPropagation()
  }

  const handleDelete = () => {
    setTimeout(() => {
      prompt.show({
        title: 'Delete contact property',
        body: 'Deleting this contact property will remove it for your entire workspace and remove it from all contacts. Are you sure you want to continue?',
        actions: [
          {
            title: 'Delete',
            type: 'destructive',
            onClick: action(() => {
              if (templateItem) {
                templateItem.delete().catch(toast.showError)
              }

              contactItem.delete().catch(toast.showError)
            }),
          },
          {
            title: 'Cancel',
            type: 'secondary',
          },
        ],
      })
    })
  }

  const isBlocked =
    contactItem.type === 'phone-number' &&
    !!service.blocklist.byPhoneNumber[contactItem.getTypeSafeValue()]

  const renderActions = () => {
    const actions: React.ReactNode[] = []
    if (contactItem.type === 'email') {
      actions.push(
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="email"
          title="Email"
          size={22}
          icon={<EnvelopeIcon />}
          onClick={handleEmail}
        />,
      )
    } else if (
      contactItem.type === 'url' &&
      isSafeContactUrl(contactItem.getTypeSafeValue())
    ) {
      actions.push(
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="link"
          size={22}
          title="Open in new window"
          icon={<NewWindowIcon />}
          onClick={handleUrl}
        />,
      )
    } else if (contactItem.type === 'phone-number' && !isBlocked) {
      actions.push(
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="call"
          size={22}
          title={callingButtonTitle}
          icon={<CallIcon />}
          onClick={handleCall}
          disabled={!callingEnabled}
        />,
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="message"
          size={22}
          title={messagingButtonTitle}
          icon={<MessageIcon />}
          onClick={handleMessage}
          disabled={!messagingEnabled}
        />,
      )
    }
    if (typeof contactItem.value === 'string') {
      actions.push(
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="copy"
          title="Copy"
          size={22}
          icon={<CopyIcon />}
          onClick={handleCopy}
        />,
      )
    }
    return actions.filter(isNonNull)
  }

  const name = contactItem.template?.name || contactItem.name || defaultName
  const isDraggable =
    contactItem.type !== 'phone-number' && contactItem.type !== 'email' && !isReadOnly

  const renderValue = () => {
    if (contactItem.type === 'phone-number') {
      return formatted((contactItem.value as string) ?? '')
    } else if (contactItem.type && Object.keys(placeholders).includes(contactItem.type)) {
      return contactItem.getTypeSafeValue()
    } else if (contactItem.type === 'date') {
      const dateValue = contactItem.value as Date
      return dateValue ? formatDate(dateValue) : null
    } else if (contactItem.type === 'boolean') {
      const boolValue = contactItem.value as boolean
      return (
        <Checkbox checked={boolValue ?? false} className={styles.checkbox} label={name} />
      )
    } else if (contactItem.type === 'multi-select') {
      const multiSelectValue = contactItem.value as string[]
      if (
        !multiSelectValue ||
        (Array.isArray(multiSelectValue) && multiSelectValue.length === 0) ||
        !templateItem
      ) {
        return null
      }

      return (
        <div className={styles.tags}>
          {Array.isArray(multiSelectValue) ? (
            multiSelectValue.map((item: string) => (
              <Tag key={item} name={item} color={colorForOption(item, templateItem)} />
            ))
          ) : (
            <Tag
              key={multiSelectValue}
              name={multiSelectValue}
              color={colorForOption(multiSelectValue, templateItem)}
            />
          )}
        </div>
      )
    }
    return null
  }

  const itemProps = isReadOnly
    ? ({
        disableName: true,
      } as const)
    : ({
        onNameChange: handleNameChange,
        onValueClick: handleEditValue,
        onDelete: handleDelete,
      } as const)

  return (
    <Item
      ref={itemRef}
      type={contactItem.type}
      name={name}
      draggable={isDraggable}
      isDragging={!!active}
      placeholder={placeholder ?? undefined}
      valueRef={valueRef}
      value={renderValue()}
      actions={renderActions()}
      {...itemProps}
    />
  )
}

export default observer(CustomItem)
