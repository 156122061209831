import type {
  NavigateOptions,
  To,
  Router,
  createBrowserRouter,
  NavigateFunction,
} from 'react-router-dom'

import isSameLocation from './isSameLocation'

type Router = ReturnType<typeof createBrowserRouter>

export type SafeAbsoluteNavigateFunction = NavigateFunction & {
  // Includes front space to prevent it from showing up in autocomplete
  ' __isSafeAbsoluteNavigate': true
}

/**
 * Returns an imperative method for changing the location.
 *
 * This is a wrapper around the `navigate` method from `react-router-dom`.
 *
 * This method will not navigate if the user is already on the page.
 *
 * The trade-off is that this method won't work with relative paths.
 */
export default function createSafeAbsoluteNavigate(
  router: Router,
): SafeAbsoluteNavigateFunction {
  const routerNavigate = router.navigate

  function navigate(delta: number): void
  function navigate(to: To, options?: NavigateOptions): void
  function navigate(to: To | number, options?: NavigateOptions): void {
    if (typeof to === 'number') {
      throw new Error(
        'Relative navigation is not supported, use react-router-dom navigate instead.',
      )
    }

    // Check if the user is trying to navigate to a different state
    // We don't want to navigate if the user is already on the page
    // We need to check state, pathname, and search params
    const currentLocation = router.state.location
    const currentPathname = currentLocation.pathname

    const url = (() => {
      const origin = window.location.origin

      if (typeof to === 'string') {
        return new URL(to, origin)
      }

      const { pathname = currentPathname, search, hash } = to
      const result = new URL(pathname, origin)

      if (search) {
        result.search = search
      }

      if (hash) {
        result.hash = hash
      }

      return result
    })()

    if (isSameLocation({ url, options }, currentLocation)) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    routerNavigate(to, options)
  }

  return markNavigate(navigate)
}

function markNavigate(navigate: NavigateFunction): SafeAbsoluteNavigateFunction {
  navigate[' __isSafeAbsoluteNavigate'] = true
  return navigate as SafeAbsoluteNavigateFunction
}

function isSafeAbsoluteNavigate(
  navigate: NavigateFunction | SafeAbsoluteNavigateFunction,
): navigate is SafeAbsoluteNavigateFunction {
  return navigate[' __isSafeAbsoluteNavigate'] === true
}
