import { makeAutoObservable } from 'mobx'

import { isReferentiallyDeepEqual } from '@src/lib/collections'
import type ContactStore from '@src/service/contact-store'
import type MemberStore from '@src/service/member-store'
import type PhoneNumberStore from '@src/service/phone-number-store'

import type { CodablePhoneNumber, Model, SerializedMember } from '.'
import MemberModel from './MemberModel'
import type { CodableContact } from './contact/ContactModel'
import { ContactModel } from './contact/ContactModel'
import PhoneNumberModel from './phone-number/PhoneNumberModel'

export interface CodableSearchQuery {
  id: string
  in: CodablePhoneNumber[]
  from: (SerializedMember | CodableContact)[]
  term: string
}

export default class SearchQueryModel implements Model {
  private raw: CodableSearchQuery

  constructor(
    private memberStore: MemberStore,
    private phoneNumberStore: PhoneNumberStore,
    private contactStore: ContactStore,
    attrs: CodableSearchQuery,
  ) {
    this.raw = attrs

    makeAutoObservable(this)
  }

  get id(): string {
    return this.raw.id
  }

  get in(): PhoneNumberModel[] {
    return this.raw.in.map(
      (phoneNumber) => new PhoneNumberModel(this.phoneNumberStore, phoneNumber),
    )
  }

  set in(phoneNumbers: PhoneNumberModel[]) {
    this.raw.in = phoneNumbers.map((phoneNumber) => phoneNumber.serialize())
  }

  get from(): (ContactModel | MemberModel)[] {
    return this.raw.from.map((memberOrContact) =>
      memberOrContact.role === 'member'
        ? new MemberModel(this.memberStore).deserialize(memberOrContact)
        : new ContactModel(this.contactStore, memberOrContact),
    )
  }

  set from(membersOrContacts: (ContactModel | MemberModel)[]) {
    this.raw.from = membersOrContacts.map((memberOrContact) =>
      memberOrContact.serialize(),
    )
  }

  get term(): string {
    return this.raw.term
  }

  set term(value: string) {
    this.raw.term = value
  }

  /**
   * A query matches another if it's the same instance of the class or if it
   * searches for the same things
   *
   * @param query - The query instance to compare.
   *
   * @returns `true` if the query search for the same things or it's the same query, otherwise `false`.
   */
  matches(query: SearchQueryModel): boolean {
    return (
      isReferentiallyDeepEqual(query, this) ||
      (query.term === this.term &&
        query.in.every((_, index) => query.in[index]?.id === this.in[index]?.id) &&
        query.from.every((_, index) => query.from[index]?.id === this.from[index]?.id))
    )
  }

  deserialize(json: CodableSearchQuery) {
    this.raw = json

    return this
  }

  serialize(): CodableSearchQuery {
    return this.raw
  }
}
