import { useContext } from 'react'

import { ParagonContext } from './ParagonContext'

export function useParagon() {
  const context = useContext(ParagonContext)

  if (!context) {
    throw new Error('useParagon must be used within a ParagonProvider')
  }

  return context
}
