import fuzzysort from 'fuzzysort'

import type { EntityPhoneNumberModel, PhoneNumberModel } from '@src/service/model'

interface SearchOptions {
  limit?: number
}

export function fuzzySearch<T>(
  collection: readonly T[],
  query: string,
  fields: (keyof T)[],
  options: SearchOptions = {},
): T[] {
  const result = fuzzysort.go(query, collection, {
    limit: 10,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    keys: fields as any,
    threshold: -10000,
    ...options,
  })
  return result.map((r) => r.obj)
}

export async function searchPhoneNumbers<
  T extends EntityPhoneNumberModel | PhoneNumberModel,
>(query: string, phoneNumbers: T[]): Promise<T[]> {
  const pns = phoneNumbers.map((p) => ({
    id: p.id,
    name: p.name,
    number: p.number,
    unames: p.members.map((m) => m.name).join(' '),
  }))
  const result = await fuzzysort.goAsync(query, pns, {
    limit: 10,
    keys: ['name', 'number', 'unames'],
    threshold: -10000,
  })
  return result
    .map((r) => phoneNumbers.find((p) => p.id === r.obj.id))
    .filter((phoneNumber): phoneNumber is T => !!phoneNumber)
}

export interface TemplateTagItemOption {
  name: string
  color: string
}

export async function searchTags(
  query: string,
  options: TemplateTagItemOption[],
): Promise<TemplateTagItemOption[]> {
  if (!query) {
    return options
  }
  const result = await fuzzysort.goAsync(query, options, {
    limit: 10,
    keys: ['name'],
    threshold: -10000,
  })
  return result.map((r) => r.obj)
}
