import type { ReactNode } from 'react'

import PricingController from '@src/app/billing/pricing/PricingController'
import { useAppStore } from '@src/app/context'
import useInstance from '@src/lib/hooks/useInstance'

import { PricingContext } from './PricingContext'

interface PricingProviderProps {
  children: ReactNode
}

export default function PricingProvider({ children }: PricingProviderProps) {
  const app = useAppStore()
  const pricingControllerInstance = useInstance(() => new PricingController(app), [app])
  return (
    <PricingContext.Provider value={pricingControllerInstance}>
      {children}
    </PricingContext.Provider>
  )
}
